import styled from 'styled-components'

export const ModalSection = styled.section`
    display: flex;
    flex-direction: column;

    max-width: 1200px;
    background: white;
    width: 65%;
    height: 85%;
    
`
