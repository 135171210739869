import { SET_SEARCH_TERM, RESET_SEARCH_TERM, GET_SEARCH_TERM, SET_SEARCH_LAB, RESET_SEARCH_LAB, SET_CURRENT_PRODUCT_ID } from '../constants/search'

export const actionSetSearchTerm = (term) => ({
  type: SET_SEARCH_TERM,
  term
})

export const actionResetSearchTerm = () => ({
  type: RESET_SEARCH_TERM
})

export const actionGetSearchTerm = () => ({
  type: GET_SEARCH_TERM
})

export const actionSetSearchLab = ({ id, name }) => ({
  type: SET_SEARCH_LAB,
  id,
  name
})

export const actionResetSearchLab = () => ({
  type: RESET_SEARCH_LAB
})

export const setCurrentProductId = ({ productId }) => ({
  type: SET_CURRENT_PRODUCT_ID,
  productId
})
