/* eslint-disable require-jsdoc */
import React, { useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Layout from '../../components/Layout'
import { v4 as uuidv4 } from 'uuid'

const StackContext = React.createContext()
/**
 * @author ehernandez
 * @param {React.Component} childrens
 * @returns {React.Component}
 */
const StackProvider = () => {
  const [stack, setStack] = useState([])
  const [homeHistory, setHomeHistory] = useState([])
  const [currentHistory, setCurrentHistory] = useState()

  useEffect(() => {
    if (homeHistory.length === 0) navigate({ path: 'home', name: 'Home', fixed: true })//
    const newHistory = [...homeHistory, { path: 'home', name: 'Home', history: true }]
    setCurrentHistory(newHistory.length - 1)
    setHomeHistory(newHistory)
  }, [])
  // console.log({ homeHistory }, { currentHistory })
  /**
   *  @author ehernandez
   * @param {object} item
   */
  const crateTabParams = (item, params = {}) => {
    const newItem = {
      ...item,
      key: uuidv4(),
      id: stack.length + 1,
      params
    }
    return newItem
  }

  /**
   *  @author ehernandez
   * @param {object} item
   * @param {object}  item.path route to open tab
   * @param {object}  item.name name of tab
   * @param {object}  item.multiple if open tab multiple times
   */
  const navigate = (item, params) => {
    const isExist = stack.find(stackItem => stackItem.path === item.path)
    if (isExist && !isExist.multiple) {
      pop(isExist.key)
      return
    }

    if (item.openHome) {
      const existsOnHistory = homeHistory.findIndex(history => history.path === item.path)
      if (existsOnHistory > -1) {
        setCurrentHistory(existsOnHistory)
        navigate(homeHistory[existsOnHistory], params)
        return
      }

      const tabs = stack.filter((tab, index) => {
        if (index === 0) {
          tab.path = item.path
          tab.name = item.name
          tab.params = params
          pop(tab.key)
        }
        return tab
      })

      const newHistory = [...homeHistory.splice(0, currentHistory + 1), { path: item.path, name: item.name, history: true, params: params }]

      setHomeHistory(newHistory)
      setCurrentHistory(newHistory.length - 1)
      setStack(tabs)
      return
    }

    if (item.history) {
      const tabs = stack.filter((tab, index) => {
        if (index === 0) {
          tab.path = item.path
          tab.name = item.name
          pop(tab.key)
        }
        return tab
      })
      setStack(tabs)
      return
    }

    const tabs = stack.filter(tab => {
      tab.active = false
      return tab
    })

    const newTab = crateTabParams(item, params)
    newTab.active = true

    setStack([...tabs, newTab])
  }
  console.log(stack)
  /**
   *  @author ehernandez
   * @param {string} uuid key of tab
   */
  const pop = (uuid) => {
    const tabs = stack.filter(tab => {
      tab.active = false
      if (uuid === tab.key) {
        tab.active = true
      }
      return tab
    })
    setStack(tabs)
  }
  console.log(stack)
  /**
   * remove tab
   * @param {string} uuid key of tab
   *
   */
  const remove = (uuid) => {
    const tabs = stack.filter((tab, index) => {
      if (uuid !== tab.key || index === 0) {
        // console.log(index + 1, tab.id)
        if (tab.id === index + 1) {
          pop(tab.key)
        }
        return tab
      }
      return null
    })
    setStack(tabs)
  }

  /**
   * navigate home stack
   * @author mromero
   * @param {number} direction defines witch direction the navigation on hometab goes
   */
  const browseHome = (direction) => {
    let tabToNavigate = currentHistory + direction
    if (tabToNavigate < 0) tabToNavigate = 0
    if (tabToNavigate > homeHistory.length - 1) tabToNavigate = homeHistory.length - 1
    if (tabToNavigate >= 0) {
      navigate(homeHistory[tabToNavigate])
    }
    setCurrentHistory(tabToNavigate)
  }

  const removeFromHome = () => {
    if (currentHistory > 0) {
      homeHistory.splice(currentHistory, 1)
      navigate(homeHistory[currentHistory - 1])
      setHomeHistory(homeHistory)
      setCurrentHistory(currentHistory - 1)
    }
  }

  return <StackContext.Provider value={{ stack, navigate, pop, remove, browseHome, removeFromHome, currentHistory: currentHistory + 1, totalTabs: homeHistory.length }}>
        <Layout>
            <Outlet />
        </Layout>
    </StackContext.Provider>
}

const useNavigation = () => useContext(StackContext)
export { useNavigation, StackProvider }
