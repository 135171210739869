import React from 'react'
import PropTypes from 'prop-types'
import ProductTag from '../ProductTag'
import Carousell from '../../../../components/Carousell'
import CarousellItem from '../../../../components/Carousell/components/CarousellItem/CarousellItem'

const ProductCover = ({ images }) => {
  console.log(images)
  return (
    <div className="product-cover tw-w-full tw-h-full tw-relative tw-flex tw-justify-center">
      <div className="product-cover__tags tw-absolute tw-top-0 tw-bg-transparent tw-left-0 tw-z-10 tw-flex">
        <div className="tw-mr-2">
          <ProductTag label="nuevo" mode="success" />
        </div>
        <ProductTag label="23" mode="alert" />
      </div>
       <Carousell items={images}>
         {
            images.map((image) => (
              <CarousellItem active={!image.id} key={image.id}>
                <div className="tw-w-full tw-h-full tw-relative tw-flex tw-items-center tw-justify-center">
                <img src={image.src} alt="product" />
                </div>

              </CarousellItem>
            ))
         }
       </Carousell>
    </div>
  )
}

ProductCover.propTypes = {
  images: PropTypes.array
}

export default ProductCover
