import React from 'react'
import FooterLegalAndSocial from '../FooterLegalAndSocial/FooterLegalAndSocial'
import FooterLinksContainer from '../FooterLinksContainer/FooterLinksContainer'
import FooterRatings from '../FooterRatings/FooterRatings'

/**
 * @author mromero
 * @returns {React.Component}
 */
const Footer = () => {
  return (
        <div className='footer tw-bg-white tw-flex tw-flex-col tw-flex-wrap tw-px-0 tw-max-w-container'>
            <div>
                <FooterRatings />
            </div>
            <div>
                <FooterLinksContainer />
            </div>
            <div className='tw-p-4 tw-bg-black tw-bg-opacity-70'>
                <FooterLegalAndSocial />
            </div>
        </div>
  )
}

Footer.propTypes = {

}

export default Footer
