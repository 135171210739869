import React from 'react'
import Icons from '../../Icons'

const iconSize = 26

export const sidebarItems = [
  {
    id: 1,
    name: 'Home',
    path: 'home',
    openHome: true,
    icon: () => <Icons.IcHome size={iconSize} color='white'/>,
    innerLinks: null
  },
  {
    id: 2,
    name: 'Pedidos y encargos',
    icon: () => <Icons.IcStickyNote size={iconSize} color='white'/>,
    innerLinks: [
      {
        id: 1,
        name: 'Pedidos',
        path: 'pedidos'
      },
      {
        id: 2,
        name: 'Encargos',
        path: 'encargos',
        openHome: true
      },
      {
        id: 3,
        name: 'Albaranes',
        path: 'albaranes',
        openHome: true
      },
      {
        id: 4,
        name: 'Devoluciones',
        path: 'devoluciones',
        openHome: true
      }
    ]
  },
  {
    id: 3,
    name: 'Facturas',
    icon: () => <Icons.IcInvoiceVF size={iconSize} color='white'/>,
    innerLinks: [
      {
        id: 1,
        name: 'Facturas actuales',
        path: 'facturasactuales',
        openHome: true
      },
      {
        id: 2,
        name: 'Facturas pasadas',
        path: 'facturaspasadas',
        openHome: true
      }
    ]
  },
  {
    id: 4,
    name: 'Transporte',
    slug: 'transport',
    path: 'order-traking',
    icon: () => <Icons.IcFollowUp size={iconSize} color='white'/>
  },
  {
    id: 5,
    name: 'Alertas',
    path: 'notifications',
    showAlerts: true,
    icon: () => <Icons.IcAlertVF size={iconSize} color='white'/>,
    innerLinks: null
  },
  {
    id: 6,
    name: 'Suggerencias',
    icon: () => <Icons.IcSuggestion size={iconSize} color='white'/>,
    innerLinks: null
  }
]
