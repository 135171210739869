import React from 'react'
import Logo from '../Logo/Logo'
import { HeaderLogoContent } from './styles'
import PropTypes from 'prop-types'
/**
 * @auhtor ehernandez
 * @returns {React.Component}
 */
const Header = ({ position, children }) => {
  return (
    <div className={`header tw-bg-black tw-${position} tw-top-0 tw-left-0 tw-w-full tw-z-10`}>
      <HeaderLogoContent className="header-logo-content tw-w-full tw-flex tw-justify-between tw-items-center tw-px-4">
        <div className="tw-w-36">
          <Logo mode="white" />
        </div>
        <div>
          {children}
        </div>
      </HeaderLogoContent>
    </div>
  )
}

Header.propTypes = {
  position: PropTypes.oneOf(['absolute', 'fixed', 'relative']),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

Header.defaultProps = {
  position: 'absolute'
}

export default Header
