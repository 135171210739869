import React from 'react'
import PropTypes from 'prop-types'
import { Dot } from './styles'

const CarousellDots = ({ target, slideTo, current, index, color }) => {
  console.log(slideTo, 'slideTo')
  return (
    <Dot
    type="button"
    data-bs-target={'#' + target}
    data-bs-slide-to={index}
    className={`carousel-indicators__item ${index === 0 && 'active'} tw-relative`}
    aria-current={index === 0 ? 'true' : 'false'}
>
    <div className={`${current ? `tw-bg-${color}` : 'tw-bg-transparent'} dot-border tw-absolute tw-top-0 tw-left-0 tw-border-${color} tw-border tw-boder-solid tw-h-[8px] tw-w-[8px] tw-rounded-full`} />
</Dot>
  )
}

CarousellDots.propTypes = {
  target: PropTypes.string,
  slideTo: PropTypes.number,
  current: PropTypes.bool,
  color: PropTypes.oneOf(['white', 'primary', 'secondary', 'success', 'alert', 'warning', 'info', 'light', 'dark']),
  index: PropTypes.number
}

export default CarousellDots
