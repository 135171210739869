import React, { useState, useEffect } from 'react'
import Checkbox from '../../../infrastructure/components/Aggrid/infraestructure/frameworkComponents/OrderDetails/Components/CheckBox'
import Paragraphs from '../../Paragraphs'

/**
 *
 * @param {Array} elements array de elementos por los que se puede filtrar
 * @param {String} {title, article} El título del filtro y el artículo, femenino o másculino del mismo
 * @param {Number} maxElementsToShow El máximo número de elementos del filtro a mostrar, a patir del siguiente se muestra el mensaje "Mostrar los X elementos"
 * @returns
 */
const useFilter = (elements, { title, article, maxElementsToShow, filterName, filterQuery }) => {
  const [filterElements, setFilterElements] = useState(elements)
  const [filterTitle] = useState(title)
  const [showAllElements, setShowAllElements] = useState(elements <= 6)
  const [selectedFilterElements, setSelectedFilterElements] = useState([])
  useEffect(() => {
    setFilterElements(elements)
    setSelectedFilterElements([])
    setShowAllElements(elements <= 6)
  }, [])
  const handleElementClick = (index) => {
    if (selectedFilterElements.find(idx => Number(idx) === Number(index))) {
      const newSelectedCategories = selectedFilterElements.filter(idx => idx !== index)
      filterQuery({ filter: filterName, ids: newSelectedCategories })
      setSelectedFilterElements(newSelectedCategories)
      return
    }
    const newSelectedCategories = selectedFilterElements.slice()
    newSelectedCategories.push(index)
    filterQuery({ filter: filterName, ids: newSelectedCategories })
    setSelectedFilterElements(newSelectedCategories)
  }

  const MoreElementsSelector = () => {
    if (filterElements.length <= maxElementsToShow) return <></>
    if (!showAllElements) return <div onClick={ () => setShowAllElements(true)}><Paragraphs className=' tw-text-primary tw-cursor-pointer' uppercase={true} size='xxs' weight='bold'>Ver {article} {filterElements.length} {title}</Paragraphs></div>
    return <div onClick={ () => setShowAllElements(false)}><Paragraphs className=' tw-text-primary tw-cursor-pointer' uppercase={true} size='xxs' weight='bold'>Ocultar {title}</Paragraphs></div>
  }

  // console.log(filterElements)
  const DrawFilterElements = () => {
    return (
      <>
        <div className=' tw-overflow-auto'>
            <Paragraphs uppercase={true} size='xxs' weight='bold' className=' tw-pb-4'>{filterTitle}</Paragraphs>
            { filterElements.length > 0 && filterElements.slice(0, showAllElements || filterElements.length <= maxElementsToShow ? filterElements.length : maxElementsToShow).map((element, index) =>
            <div key={element.id} className='tw-flex tw-gap-2 tw-pb-[13px] tw-cursor-pointer' onClick={() => handleElementClick(element.id)}>
                <div className='tw-self-start'><Checkbox checked={selectedFilterElements.some(idx => idx === element.id)} onChange={() => {}}/></div>
                <Paragraphs className=' tw-self-center'>{element.name}</Paragraphs>
            </div>
            ) }
        </div>
        <MoreElementsSelector/>
        </>
    )
  }

  return {
    DrawFilterElements,
    setFilterElements,
    selectedFilterElements,
    elements
  }
}

export default useFilter
