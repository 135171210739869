import { useState, useEffect } from 'react'
import { useMutation } from '../useMutation/useMutation'
import { useSelector } from 'react-redux'
// import PropTypes from 'prop-types';

/**
 * @author mromero
 * @returns {}
 */
export const useSearch = () => {
  const { searchTerm, selectedLab } = useSelector(state => state.searchReducer)
  const [fetchSearchResult] = useMutation('products/query')
  const [searchResults, setSearchResults] = useState({})
  const [loading, setLoading] = useState(true)
  const [currentQuery, setCurrentQuery] = useState({})
  const [perPage] = useState(5)
  const [currentPage, setCurrentPage] = useState(1)
  const [loadingMoreProducts, setLoadingMoreProducts] = useState(false)
  const [noMoreResults, setNoMoreResults] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (!searchTerm && !selectedLab.id) {
      setSearchResults(null)
      return
    }
    const query = {
      method: 'post',
      data: {
        filter: {
          query: searchTerm || null,
          per_page: perPage
        }
      }
    }
    if (selectedLab?.id) query.data.filter = { ...query.data.filter, manufacturers: [selectedLab.id] }
    setCurrentPage(1)
    callToApi(query, 1)
    setNoMoreResults(false)
  }, [searchTerm, selectedLab])

  const callToApi = async (query, cp = currentPage) => {
    const { data } = await fetchSearchResult({ data: query.data, addToURL: '?per_page=' + perPage + '&page=' + cp })
    console.log({ data })
    setSearchResults({ products: data.data.products, categories: data.data.categories, manufacturers: data.data.manufacturers })
    setLoading(false)
    setCurrentQuery(query)
    setNoMoreResults(false)
    console.log(data.data.products.items[0].id)
  }

  const loadMoreProducts = async () => {
    console.log({ searchResults }) //
    if (currentPage >= searchResults?.products?.pagination?.last_page) {
      setNoMoreResults(true)
      return
    }
    setLoadingMoreProducts(true)
    const { data } = await fetchSearchResult({ data: currentQuery.data, addToURL: '?per_page=' + perPage + '&page=' + parseInt(currentPage + 1).toString() })
    const newSearchResults = { ...searchResults }
    await data?.data?.products?.items.map(item => newSearchResults.products.items.push(item))
    console.log({ newSearchResults })
    setSearchResults(newSearchResults)
    setCurrentPage(currentPage + 1)
    setLoadingMoreProducts(false)
  }

  const filterQuery = async ({ filter, ids }) => {
    setNoMoreResults(false)
    setCurrentPage(1)
    switch (filter) {
      case 'manufacturers': {
        if (ids.length <= 0) {
          delete currentQuery.data.filter.manufacturers
        } else {
          currentQuery.data.filter = { ...currentQuery.data.filter, manufacturers: ids }
        }
        // callToApi(currentQuery)
        const { data } = await fetchSearchResult({ data: currentQuery.data, addToURL: '?per_page=' + perPage })
        console.log(data)
        setSearchResults(
          {
            ...searchResults,
            products: data.data.products,
            categories: data.data.categories,
            // categories: searchResults.categories.length < data.data.categories.length ? data.data.categories : searchResults.categories,
            manufacturers: searchResults.manufacturers.length < data.data.manufacturers.length ? data.data.manufacturers : searchResults.manufacturers
          })
        setCurrentQuery(currentQuery)
        break
      }
      case 'categories': {
        if (ids.length <= 0) {
          delete currentQuery.data.filter.categories
        } else {
          currentQuery.data.filter = { ...currentQuery.data.filter, categories: ids }
        }
        const { data } = await fetchSearchResult({ data: currentQuery.data, addToURL: '?per_page=' + perPage })
        console.log(data)
        setSearchResults(
          {
            ...searchResults,
            products: data.data.products,
            categories: searchResults.categories.length < data.data.categories.length ? data.data.categories : searchResults.categories,
            // manufacturers: searchResults.manufacturers.length < data.data.manufacturers.length ? data.data.manufacturers : searchResults.manufacturers
            manufacturers: data.data.manufacturers
          })
        setCurrentQuery(currentQuery)
        break }
      default:
    }
  }

  // console.log(currentQuery)
  return { searchResults, loading, filterQuery, perPage, currentPage, loadMoreProducts, loadingMoreProducts, noMoreResults }
}
