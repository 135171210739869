import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../../infrastructure/styles/colors'

/**
 *
 * @param {Number} size
 * @param {String} color
 * @returns
 */
const IcLocationVF = ({ size, color }) => (
  <svg id="location_on_black_24dp_1_" data-name="location_on_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 38 38">
    <path id="Trazado_2660" data-name="Trazado 2660" d="M0,0H38V38H0Z" fill="none"/>
    <path id="Trazado_2661" data-name="Trazado 2661" d="M16.083,2A11.075,11.075,0,0,0,5,13.083C5,21.4,16.083,33.667,16.083,33.667S27.167,21.4,27.167,13.083A11.075,11.075,0,0,0,16.083,2ZM8.167,13.083a7.917,7.917,0,0,1,15.833,0c0,4.56-4.56,11.384-7.917,15.643C12.79,24.5,8.167,17.6,8.167,13.083Z" transform="translate(2.917 1.167)" fill={colors[color]}/>
    <circle id="Elipse_457" data-name="Elipse 457" cx="2.958" cy="2.958" r="2.958" transform="translate(16.042 10.292)" fill={colors[color]}/>
  </svg>

)
IcLocationVF.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
}

IcLocationVF.defaultProps = {
  size: 24,
  color: 'primary'
}

export default IcLocationVF
