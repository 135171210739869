import React from 'react'
import PropTypes from 'prop-types'
import { HeaderNav, LayoutContainer } from './styles'
import Header from '../../../components/Header/Header'
import Avatar from '../../../components/Avatar'
import NavTab from '../TabsContainer/NavTab'
import { useNavigation } from '../../context/StackContext/StackContext'
import Sidebar from '../../../components/Sidebar/Sidebar'
import Footer from '../../../components/Footer/Footer'
import { useSelector } from 'react-redux'
import Hero from '../../../components/Hero'
import { useNavigate } from 'react-router-dom'
// import { useUser } from '../../hooks/useUser/useUser'

/**
 * @author ehernandez
 * @param {function} chhildren react componente
 * @returns {React.Component}
 */
const Layout = ({ children }) => {
  const navigation = useNavigate()
  // const { colors } = useSelector(state => state.colorsReducer)
  const { user } = useSelector((state) => state.userReducer)
  // const [openLang, setOpenLang] = useState(false)
  // const { logoutUser } = useUser()
  const { stack, navigate } = useNavigation()
  /**
   * open software on new chrome tab
   *
   *
   * @author   ehernandez
   * @param   {Object} app
   * @param   {Object}  app.url
   * @param   {Object}  app.comprado
   */

  // const openSotware = (app) => {
  //   if (app?.comprada) {
  //     window.open(app.url + '/' + app.token)
  //   }
  // }

  const onClickProfile = () => {
    navigate({
      key: 'Profile',
      path: 'profile',
      title: 'Perfil',
      name: 'Perfil'
    })
  }
  return (
    <LayoutContainer
      isLogged={user?.id}
      className="layout tw-h-screen tw-overflow-auto tw-flex tw-flex-col tw-bg-gray-50 tw-relative"
    >
      {user?.id && (
        <div className="tw-fixed tw-left-0 tw-top-0 tw-h-screen tw-z-50">
          <Sidebar />
        </div>
      )}
      {user?.id ? (
        <HeaderNav className="tw-w-full tw-top-0 tw-left-0">
          <Header position="relative">
            <Avatar onClickProfile={onClickProfile} user={user} />
          </Header>
          <NavTab tabs={stack} />
        </HeaderNav>
      ) : (
        <Hero onCreatePage={() => navigation('/register')} showCreateAccount={true} />
      )}

      <div className="tw-h-full tw-w-full tw-flex tw-flex-col">
        <>{children}</>
        <Footer />
      </div>
    </LayoutContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Layout
