import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'
/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcCheckCircle = ({ color, size }) => {
  return (
    <Icon color={color} size={size} className="ic-clock material-icons ">
    check_circle
    </Icon>
  )
}

IcCheckCircle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
}

IcCheckCircle.defaultProps = {
  color: 'green',
  size: 24
}

export default IcCheckCircle
