import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {number} size size icon
 * @returns {React.Component}
 */
const IcCart = ({ size, color, showPlus }) => {
  return (
    <svg className='iccart' id="ic_menu_vadefarma_cesta" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <path id="Trazado_2295" data-name="Trazado 2295" d="M0,0H24V24H0Z" fill="none"/>
      <path id="Trazado_2662" data-name="Trazado 2662" d="M-19820-23064.621h2.051v3l.121,7.125h14.33l1-7.125" transform="translate(19823.25 23067.5)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Trazado_2663" data-name="Trazado 2663" d="M17,10a2,2,0,1,0-2-2A2,2,0,0,0,17,10Z" transform="translate(-9.234 10.5)" fill="none" stroke={color} strokeLinejoin="round" strokeWidth="2"/>
      <path id="Trazado_2664" data-name="Trazado 2664" d="M17,10a2,2,0,1,0-2-2A2,2,0,0,0,17,10Z" transform="translate(-0.234 10.5)" fill="none" stroke={color} strokeLinejoin="round" strokeWidth="2"/>
      { showPlus &&
        <g id="Grupo_7318" data-name="Grupo 7318" transform="translate(-0.936 2.879)">
        <path id="Trazado_2665" data-name="Trazado 2665" d="M51.936-17.377v4" transform="translate(-37.5 16.998)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        <path id="Trazado_2666" data-name="Trazado 2666" d="M51.936-17.377v4" transform="translate(-0.941 -50.314) rotate(90)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        </g>
      }
    </svg>
  )
}

IcCart.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  showPlus: PropTypes.bool
}

IcCart.defaultProps = {
  size: 24,
  color: '#000',
  showPlus: false
}

export default IcCart
