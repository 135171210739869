import React from 'react'
import { useTranslation } from 'react-i18next'
import AggridProvider from '../../../../components/AggridProvider/AggridProvider'
import Button from '../../../../components/Button/Button'
import DropDown from '../../../../components/DropDown/DropDown'
import HeaderBarPage from '../../../../components/HeaderBarPage'
import Page from '../../../../components/Page'
import TextInput from '../../../../components/TextInput'
import TrakingListing from '../../components/TrakingLinting'
import { aggrid } from './constants'

// import PropTypes from 'prop-types'

const OrderTrakingScreen = () => {
  const { t } = useTranslation()
  return (
    <Page.Container
      name="order-traking-screen"
      className=" tw-bg-white tw-flex tw-flex-col"
    >
      <Page.Header className="header tw-flex tw-flex-row tw-items-center tw-px-4">
        <HeaderBarPage title={t('Seguimiento de pedido')} />
      </Page.Header>

      <Page.Body className="body-order-traking-screen tw-flex tw-flex-col tw-px-4">
        <div className="tw-flex tw-flex-row tw-gap-4">
          <div className="tw-gap-4 tw-flex tw-flex-1">
            <div className="tw-w-6/12">
              <TextInput
                label={t('Busqueda general')}
                placeholder={t('Introduce aquí tu busqueda')}
              />
            </div>
            <div className="tw-w-3/12">
              <DropDown label={t('Mostrar')} />
            </div>
            <div className="tw-w-3/12">
              <DropDown label={t('Estado')} />
            </div>
          </div>
          <div className="tw-w-[400px]" />
        </div>
        <div className="tw-flex tw-gap-4 tw-flex-1 tw-py-4  ">
          <div className="tw-flex-1">
            <AggridProvider data={aggrid} />
          </div>
          <div className="tw-w-[400px] tw-bg-secondary tw-bg-opacity-20 tw-p-8 tw-flex tw-flex-col tw-justify-between">

            <TrakingListing number="000332" active={{ id: 1 }} />
            <div className='tw-flex tw-flex-col tw-gap-3'>
            <div>
              <Button size="full" label={t('Ver detalle pedido')} uppercase />
            </div>
            <div>
              <Button mode='transparent' size="full" label={t('Ver albarán')} uppercase />
            </div>
            </div>
          </div>
        </div>
      </Page.Body>
    </Page.Container>
  )
}

OrderTrakingScreen.propTypes = {}

export default OrderTrakingScreen
