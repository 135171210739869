import styled from 'styled-components'

export const Line = styled.div`
  width: ${(props) => (props.small ? 12 : 19)}px;
  height: 3px;
  border-radius: 20px;
  position: relative;
  margin-left: ${(props) => (props.small ? 3 : 0)}px;
  margin-bottom: 1px;
  &::before {
    content: "";
    width: 2.5px;
    height: 2px;
    background-color: ${props => props.color};
    position: absolute;
    left: 0;
    top: 0;
  }
  &::after {
    content: "";
    width: 40%;
    height: 2px;
    background-color: ${props => props.color};
    position: absolute;
    left: 5px;
    top: 0;
  }
`
