import React from 'react'
import Paragraphs from '../../../../components/Paragraphs'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { listing } from '../contans'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'

const TrakingListing = ({ number, active }) => {
  const { t } = useTranslation()
  return (
    <div className="traking-listing">
      <div className="traking-listing__header">
        <Paragraphs weight="bold" className='tw-mb-4'>{t('Pedido', { number })}</Paragraphs>
        <div>
          {listing.map((item, idx) => {
            const size =
              active?.id === item.id
                ? 'tw-h-[45px] tw-w-[45px]'
                : 'tw-h-[40px] tw-w-[40px]'
            const color =
              active?.id === item.id
                ? 'tw-bg-tertiary'
                : 'tw-bg-primary tw-bg-opacity-50'
            return (
              <div key={item.id}>
                <div className="tw-flex">
                  <div className="tw-mr-3">
                    <div
                      className={`${size} ${size} tw-rounded-full ${color} tw-flex tw-items-center tw-justify-center`}
                    >
                      <img src={item.img} alt='box' />
                    </div>
                    {
                        idx !== listing.length - 1 && (
                            <div className="tw-ml-3 tw-my-3">
                            <div className='tw-flex tw-items-center tw-justify-center tw-h-[15px] tw-w-[20px]'>*</div>
                            <div className='tw-flex tw-items-center tw-justify-center tw-h-[15px] tw-w-[20px]'>*</div>
                            <div className='tw-flex tw-items-center tw-justify-center tw-h-[15px] tw-w-[20px]'>*</div>
                          </div>
                        )
                    }

                  </div>
                  <div>
                    <Paragraphs className="tw-mb-1" weight="bold">
                      {t(item.name)}
                    </Paragraphs>
                    <Paragraphs className=' tw-capitalize'>
                      {format(new Date(item.date), 'EEEE, dd/MM  hh:mm', {
                        locale: es
                      })}
                    </Paragraphs>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

TrakingListing.propTypes = {
  number: PropTypes.string,
  active: PropTypes.object
}
TrakingListing.defaultProps = {
  active: {}
}

export default TrakingListing
