import { ADD_PRODUCT_TO_CART } from '../constants/cart'

const initialState = {
  items: [],
  uuid: null
}

/**
   * @author ehernandez
   * @param {object} state state of the application
   * @param {object} action action to be executed
   * @returns {object}
   */
const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART:
      return {
        ...state,
        ...action.cart
      }

    default:
      return state
  }
}

export default cartReducer
