import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../Paragraphs'
import { useTranslation } from 'react-i18next'
import SelectorAddItems from '../../SelectorAddItems'

const TitleDescription = ({ cn, laboratory, name, size, weight }) => {
  const { t } = useTranslation()
  return (
    <>
      <Paragraphs className="tw-text-primary" size="xs" weight="bold">
        {t('cn:', cn)}
      </Paragraphs>
      <div className="tw-mt-1">
        <Paragraphs size={size} weight={weight}>
          {name}
        </Paragraphs>
        <Paragraphs size="xs" className="tw-text-gray-400">
          {laboratory?.name}
        </Paragraphs>
      </div>
    </>
  )
}
TitleDescription.propTypes = {
  cn: PropTypes.string,
  name: PropTypes.string,
  laboratory: PropTypes.object,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  weight: PropTypes.oneOf(['regular', 'bold'])
}

const SlotPrice = ({ price }) => (
  <Paragraphs size="xs" weight="bold">
    {price} €
  </Paragraphs>
)
SlotPrice.propTypes = {
  price: PropTypes.number
}

const SlotTotal = ({ price, quantity }) => (
  <Paragraphs weight="bold">{(price * quantity)?.toFixed(2)} €</Paragraphs>
)

SlotTotal.propTypes = {
  price: PropTypes.number,
  quantity: PropTypes.number
}

const SlotDelete = ({ onDelete }) => {
  return (

    <span
        onClick={onDelete}
      className="icon-ic_delete tw-text-secondary hover:tw-text-primary"
      style={{ fontSize: 20 }}
    />

  )
}

SlotDelete.propTypes = {
  onDelete: PropTypes.func
}

const SlotBasket = ({ cn, name, onDelete, mode, laboratory, price, quantity, onChageQty }) => {
  if (mode === 'small') {
    return (
      <div className="slot-basket tw-flex">
        <div className="slot-basket__content__image tw-h-[72px] tw-w-[72px]">
          <img src="https://via.placeholder.com/150" alt="product" />
        </div>
        <div className="slot-basket__content__text tw-flex-1">
          <div className="tw-flex">
            <div className="slot-basket__content__text tw-flex-1 tw-px-2">
              <TitleDescription weight='regular' size='xs' name={name} cn={cn} laboratory={laboratory} />
            </div>
            <div className="slot-basket__content__delete tw-w-[30px] tw-text-right">
              <SlotDelete onDelete={onDelete} />
            </div>
          </div>
          <div className="tw-flex tw-justify-between tw-items-center">
            <div className="slot-basket__content__quantity">
              <SelectorAddItems onChange={onChageQty} quantity={quantity} />
            </div>
            <div className="slot-basket__content__total  tw-text-center">
              <SlotTotal price={price} quantity={quantity} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="slot-basket">
      <div className="slot-basket__content tw-flex tw-flex-wrap">
        <div className="slot-basket__content__image tw-h-[72px] tw-w-[72px]">
          <img src="https://via.placeholder.com/150" alt="product" />
        </div>
        <div className="slot-basket__content__text tw-flex-1 tw-px-2">
          <TitleDescription size='sm' weight='bold' cn={cn} laboratory={laboratory} />
        </div>
        <div className="slot-basket__content__price tw-w-[80px] tw-pt-4 tw-text-center">
          <SlotPrice price={price} />
        </div>
        <div className="slot-basket__content__quantity tw-pt-1">
          <SelectorAddItems onChange={onChageQty} quantity={quantity} />
        </div>
        <div className="slot-basket__content__discount tw-w-[80px] tw-pt-4 tw-text-center">
          <Paragraphs>-</Paragraphs>
        </div>
        <div className="slot-basket__content__total tw-w-[80px] tw-pt-4 tw-text-center">
          <SlotTotal price={price} />
        </div>
        <div className="slot-basket__content__delete tw-w-[40px] tw-pt-4 tw-text-right">
          <SlotDelete onDelete={onDelete} />
        </div>
      </div>
    </div>
  )
}

SlotBasket.propTypes = {
  cn: PropTypes.string,
  name: PropTypes.string,
  laboratory: PropTypes.object,
  quantity: PropTypes.number,
  price: PropTypes.number,
  mode: PropTypes.oneOf(['small', 'large']),
  onChageQty: PropTypes.func,
  onDelete: PropTypes.func
}

SlotBasket.defaultProps = {
  mode: 'large'
}

export default SlotBasket
