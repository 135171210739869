import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './infrastructure/localization/i18n'

import 'tw-elements'
import 'tw-elements/dist/css/index.min.css'

import './infrastructure/styles/index.css'

const enterprise = require('@ag-grid-enterprise/core')
const licenseKey = 'CompanyName=Sertec Farma SL,LicensedGroup=Sfera,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-013689,ExpiryDate=19_February_2022_[v2]_MTY0NTIyODgwMDAwMA==025b9e5a65c89c8187117ad3078f1915'
const cleanedLicenseKey =
  licenseKey && licenseKey.replace(/[\u200B-\u200D\uFEFF]/g, '')
enterprise.LicenseManager.setLicenseKey(cleanedLicenseKey)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
