import React from 'react'
import PropTypes from 'prop-types'
import CarousellDots from './components/CarousellDots'
const Carousell = ({ children, id, items, color }) => {
  return (
    <div id={id} className="carousel slide relative" data-bs-ride="carousel">
      {items.length > 0 && (
        <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
          {items.map((i, idx) => {
            return (
              <CarousellDots key={i.id} target={id} index={idx} color={color} />
            )
          })}
        </div>
      )}

      <div className="carousel-inner relative w-full overflow-hidden">
        {children}
      </div>
      {items.length > 0 && (
        <>
          <button
            className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
            type="button"
            data-bs-target={'#' + id}
            data-bs-slide="prev"
          >
            <span
              style={{ fontSize: 30 }}
              className={`icon-ic_arrow_hero inline-block bg-no-repeat tw-text-${color}`}
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
            type="button"
            data-bs-target={'#' + id}
            data-bs-slide="next"
          >
            <span
              style={{ fontSize: 30 }}
              className={`icon-ic_arrow_hero inline-block bg-no-repeat tw-text-${color}`}
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </>
      )}
    </div>
  )
}

Carousell.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  color: PropTypes.oneOf([
    'white',
    'primary',
    'secondary',
    'success',
    'alert',
    'warning',
    'info',
    'light',
    'dark'
  ])
}

Carousell.defaultProps = {
  items: []
}

export default Carousell
