import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import useListenerClose from '../../infrastructure/hooks/useListenerClose'

const ProductPromotionsModal = ({ promotions, className, closeModal }) => {
  const [ref] = useListenerClose(closeModal)
  return (
    <div ref={ref} className={`product-promotions-modal tw-bg-white tw-w-[285px] tw-shadow tw-p-4 tw-gap-3 tw-flex tw-flex-col ${className}`} onClick={closeModal}>
        {
            promotions.map((promotion, index) => <Paragraphs key={index} size='xs' color='black' className={`${promotions.length - 1 > index && ' tw-border-b tw-pb-3'}`} >{promotion}</Paragraphs>)
        }
    </div>
  )
}

ProductPromotionsModal.propTypes = {
  promotions: PropTypes.array,
  className: PropTypes.string,
  closeModal: PropTypes.func
}

export default ProductPromotionsModal
