import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Paragraphs from '../../../../components/Paragraphs'
import TextInput from '../../../../components/TextInput'
import { INPUTS } from '../../constants/inputs'
import { useNavigate } from 'react-router-dom'
import { useLogin } from '../../hooks/useLogin/useLogin'
import LeftRegister from '../../components/LeftRegister'
import BannerPage from '../../components/BannerPage'
import FormLogin from '../../components/FormLogin/FormLogin'
import Checkbox from '../../../../components/CheckBox/Checkbox'
import Hero from '../../../../components/Hero'

const CreateUserScreen = () => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm()
  const password = useRef({})
  password.current = watch('password', '')
  const navigate = useNavigate()
  const { onRegister } = useLogin()

  const onSubmit = async (data) => {
    const { success } = await onRegister(data)
    if (success) {
      navigate('/')
    }
  }

  const navigateTo = () => {
    navigate('/login')
  }
  return (
    <div className="create-user-screen tw-flex tw-flex-col tw-h-screen tw-w-screen">
      <Hero gotToHome={() => navigate('/')}/>
      <div className="tw-flex-1 tw-flex ">
        <LeftRegister
          className="tw-w-6/12 tw-flex tw-justify-between tw-flex-col"
          navigateTo={navigateTo}
        />
        <BannerPage className="tw-w-6/12 tw-flex tw-justify-between tw-flex-col tw-items-center">
          <FormLogin btnLabel={t('Darme de alta')} title={t('Registrate con los datos del farmacéutico/a titular')} handleSubmit={handleSubmit} onSubmit={onSubmit}>
            {INPUTS.register.map((i) => (
              <TextInput
                type={i.type}
                key={i.id}
                id={i.id}
                required
                label={i.label}
                register={register(i.name, {
                  required: {
                    value: true,
                    message: 'Campo requerido'
                  },
                  minLength: {
                    value: i.minLength?.value,
                    message: i?.minLength?.message
                  },
                  validate:
                    i.validate === 'password_confirmation'
                      ? (value) =>
                          value === password.current ||
                          'Las contraseñas no coinciden'
                      : null
                })}
                description={i.description}
                error={errors[i.name]?.message}
              />
            ))}
            <Paragraphs
              className="tw-text-alert tw-py-2"
              weight="bold"
              size="xxs"
            >
              {t('* Todos los campos son obligatorios')}
            </Paragraphs>
            <div className="flex tw-py-2">
                <Checkbox error={errors?.terms?.message} id='terms' register={register('terms', {
                  required: {
                    value: true,
                    message: 'Campo requerido'
                  }
                })}>
                    <Paragraphs size='xs'>{t('texto aceptar condiciones')}</Paragraphs>
                </Checkbox>
            </div>
          </FormLogin>
        </BannerPage>
      </div>
    </div>
  )
}

CreateUserScreen.propTypes = {}

export default CreateUserScreen
