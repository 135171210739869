import React, { useState, useEffect } from 'react'
import Button from '../Button/Button'
import CartButton from '../CartButton/CartButton'
import OrderBasket from '../OrderBasket'
import { useNavigation } from '../../infrastructure/context/StackContext/StackContext'
import { useDispatch, useSelector } from 'react-redux'
import { actionSetSearchTerm, actionSetSearchLab, actionResetSearchLab } from '../../infrastructure/redux/actions/search' // actionResetSearchTerm
// import LaboratoriesSelector from './components/LaboratoriesSelector/LaboratoriesSelector'
import Paragraphs from '../Paragraphs'
import QueryInput from './components/QueryInput/QueryInput'
import useCart from '../../infrastructure/hooks/useCart'
/**
 * @author mromero
 * @param {itemsInCartNum} number of items inside the cart
 * @returns {React.Component}
 */
const TopSearch = () => {
  const { searchTerm } = useSelector(state => state.searchReducer)
  const { selectedLab } = useSelector(state => state.searchReducer)

  const dispatch = useDispatch()
  const [showBasket, setShowBasket] = useState(false)
  const [openLaboratorySelector, setOpenLaboratorySelector] = useState(false)
  const [openQueryInput, setOpenQueryInput] = useState(false)
  const { cart } = useCart()

  const navigation = useNavigation()
  const handleSubmitSearch = (e) => {
    e.preventDefault()
    console.log(e.target[0].value)
    dispatch(actionSetSearchTerm(e.target[0].value))
    setOpenQueryInput(false)
    navigation.navigate({
      id: 2,
      name: 'Búsqueda',
      path: 'search',
      openHome: true
    })
  }

  const handleSubmitPredictive = (predictive) => {
    switch (predictive.type) {
      case 'product':
        dispatch(actionSetSearchTerm(predictive.name))
        setOpenQueryInput(false)
        navigation.navigate({
          id: 2,
          name: 'Búsqueda',
          path: 'search',
          openHome: true
        })
        break
      case 'manufacturer':
        // dispatch(actionResetSearchTerm())
        handleSetSelectedLab({ id: predictive.id, name: predictive.name })
        break

      default: return
    }
    setOpenQueryInput(false)
  }

  const handleSetSelectedLab = ({ id, name }) => {
    dispatch(actionSetSearchLab({ id, name }))
    navigation.navigate({
      id: 2,
      name: 'Búsqueda',
      path: 'search',
      // searchPhrase: textSearch,
      openHome: true
    })
  }
  /**
   * al presionar la tecla ESC limpia el término de la búsqueda
   */
  useEffect(() => {
    const escFunction = (event) => {
      if (event.key === 'Escape') {
        // dispatch(actionResetSearchTerm())
        // dispatch(actionResetSearchLab())
        // setTextSearch('')
        setOpenQueryInput(false)
        setOpenLaboratorySelector(false)
      }
    }
    document.addEventListener('keyup', escFunction)
    return () => document.addEventListener('keyup', escFunction)
  }, [])
  console.log({ searchTerm })
  return (
    <div className='top-search tw-flex tw-flex-row tw-flex-wrap tw-pb-4 tw-relative'>
      <div className='tw-flex tw-flex-wrap tw-border tw-flex-1 tw-px-2 tw-items-center tw-justify-between'>
          <div className='tw-flex-1 tw-flex tw-cursor-pointer' onClick={() => setOpenQueryInput(true)}>
            <span className='icon-ic_common_search tw-text-primary' style={{ fontSize: 30 }}/>
            <Paragraphs className={`tw-self-center tw-pl-2 ${!searchTerm && ' tw-text-gray-300'}`} size='lg'>
              {searchTerm?.length > 0 ? searchTerm : 'Busca entre XXXXX productos de más de XXXXX laboratorios'}
            </Paragraphs>
          </div>
          { openQueryInput &&
            <QueryInput
              closeSelector={setOpenQueryInput}
              submitSearch={handleSubmitSearch}
              searchTerm={searchTerm}
              submitPredictive={handleSubmitPredictive}
              onlyManufacturers={false}
              placeholder='Busca entre XXXXX productos de más de XXXXX laboratorios'
            />
          }
          <div className='tw-flex'>
            {selectedLab.id
              ? <div className=' tw-flex tw-bg-black tw-text-white tw-px-2'>
                  <Paragraphs className=' tw-self-center tw-pr-2' size='xxs' weight='bold'>{selectedLab.name}</Paragraphs>
                  <span className='icon-ic_common_close tw-text-2xl tw-cursor-pointer' onClick={() => {
                    dispatch(actionResetSearchLab())
                    setOpenLaboratorySelector(false)
                  }}/>
                </div>
              : openLaboratorySelector
                ? <QueryInput
                  closeSelector={setOpenLaboratorySelector}
                  submitSearch={handleSubmitSearch}
                  searchTerm={''}
                  submitPredictive={handleSubmitPredictive}
                  onlyManufacturers={true}
                  placeholder='Busque un laboratorio para filtrar los resultados'
                  />
              // <LaboratoriesSelector closeSelector={() => setOpenLaboratorySelector(false)} setSelectedLaboratory={handleSetSelectedLab}/>
                : <div onClick={() => setOpenLaboratorySelector(true)} className=' tw-flex tw-gap-3 tw-cursor-pointer tw-pr-2 hover:tw-text-primary'>
                    <Paragraphs className=' tw-self-center' uppercase={true} weight='bold' size='xs'>todos los laboratorios</Paragraphs>
                    <span className='icon-ic_filtro_laboratorio tw-text-3xl' />
                  </div>
            }
          </div>
      </div>
      <div className='tw-flex tw-flex-row tw-pl-2 tw-gap-2'>
          <div className='tw-flex-1 '>
            <Button
                size='full'
                mode='secondary'
                label="lo más buscado"
                onClick={() => {}}
                textColor="#8F3087"
                uppercase
              />
          </div>
          <div className=' '>
            <CartButton itemsInCartNum={cart.items.length} plus={false} buttonSize={50} onClick={() => setShowBasket(true)}/>
          </div>
      </div>
      {showBasket &&
        <div className=' tw-absolute tw-top-[1px] tw-right-[1px] tw-z-50'>
          <OrderBasket items={cart?.items} bigBasket={false} closeBasket={() => setShowBasket(false)} />
        </div>

      }
    </div>
  )
}

export default TopSearch
