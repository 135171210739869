import React from 'react'
import PropTypes from 'prop-types'
import { TabContainer } from './styles'
import Paragraphs from '../../../../../components/Paragraphs'
import Icons from '../../../../../components/Icons'
import { useNavigation } from '../../../../context/StackContext/StackContext'

/**
 * component tab
 * @author ehernandez
 * @param {boolean} active is tab active
 * @returns {React.Component}
 */
const Tab = ({ active, name, uuid, path, fixed }) => {
  const navigation = useNavigation()

  const onClickTab = () => {
    navigation.pop(uuid)
  }

  const removeTab = () => {
    navigation.remove(uuid)
  }

  const browseBack = () => {
    if (navigation.currentHistory > 1) navigation.browseHome(-1)
  }

  const browseForward = () => {
    if (navigation.currentHistory < navigation.totalTabs) navigation.browseHome(1)
  }

  const background = active ? 'white' : 'primary'
  const color = active ? 'primary' : 'white'
  return (
    <TabContainer data-slug={path} className={`tab tw-bg-${background} tw-h-full tw-cursor-pointer`}>
      <div className='tw-flex tw-h-full tw-w-full tw-justify-between '>
        {
          navigation.currentHistory > 1 && <div onClick= { browseBack }>
          <Icons.IcArrow size={26} color={navigation.currentHistory > 1 ? '#8F3087' : '#D2BEDD'} direction='left'/>
        </div>
        }
        <div className='tw-px-4 tw-h-full tw-w-full tw-flex tw-items-center' onClick={onClickTab}>
          <Paragraphs uppercase size='xs' weight='bold' className={`tw-text-${color}`}>{name}</Paragraphs>
        </div>
        {
          !fixed &&
             <div onClick={removeTab} className={`tw-h-full tw-w-8 tw-items-center tw-justify-center tw-flex tw-cursor-pointer tw-text-${color}`} style={{ fontSize: 25 }}>
                  <span className="icon-ic_common_close"></span>
              </div>

        }
        {
          navigation.currentHistory > 1 && <div onClick={ browseForward }>
          <Icons.IcArrow size={26} color={navigation.currentHistory < navigation.totalTabs ? '#8F3087' : '#D2BEDD'}/>
        </div>

        }
      </div>
    </TabContainer>
  )
}

Tab.propTypes = {
  active: PropTypes.bool,
  name: PropTypes.string,
  uuid: PropTypes.string,
  id: PropTypes.number,
  path: PropTypes.string,
  fixed: PropTypes.bool
}

export default Tab
