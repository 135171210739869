import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../../../components/Paragraphs'

const ProductLabCategories = ({ laboratory, categories }) => {
  return (
    <div className="product-lab-categories tw-flex tw-gap-2">
      {laboratory?.image && (
        <div className="image-laboratory  tw-h-[25px] tw-items-center tw-w-[80px] ">
          <img className='tw-w-full' src={laboratory?.name} />
        </div>
      )}
      {categories?.map((category) => (
        <Paragraphs
          key={category.id}
          size="xs"
          className=" tw-bg-slate-100 hover:tw-bg-slate-500 hover:tw-text-white tw-cursor-pointer tw-px-2 tw-h-[25px] tw-flex tw-items-center tw-justify-center"
          uppercase
        >
          {category?.name}
        </Paragraphs>
      ))}
    </div>
  )
}

ProductLabCategories.propTypes = {
  categories: PropTypes.array,
  laboratory: PropTypes.object
}

export default ProductLabCategories
