import React from 'react'
import BannerPage from '../BannerPage'
import Paragraphs from '../../../../components/Paragraphs'
import { Trans, useTranslation } from 'react-i18next'
import { adventages } from '../../constants/register'
import PropTypes from 'prop-types'
import Button from '../../../../components/Button'

/**
 * @author ehernandez
 * @param navigateTo function to navigate
 * @returns {React.Component}
 */
const LeftRegister = ({ navigateTo, className }) => {
  const { t } = useTranslation()
  return (
    <BannerPage className={`tw-bg-primary left-register ${className}`}>
      <div>
        <Paragraphs size="8xl" weight="bold" className="tw-text-white">
          {t('Crear mi cuenta')}
        </Paragraphs>
        <div className="tw-py-10 tw-text-white">
          <Paragraphs>
            {t(
              'Estás a 3 pasos de acceder al mayor vademécum de medicamentos y parafarmacia para dar respuesta a todos tus pacientes.'
            )}
          </Paragraphs>
          <Paragraphs>
            {t(
              ' Para darte de alta, necesitarás los datos de la farmacia y tu información de contacto como farmacéutico/a titular.'
            )}
          </Paragraphs>
        </div>
        <Paragraphs size="2xl" weight="bold" className="tw-text-white">
          {t('¿Cómo registrarse en vadefarma?')}
        </Paragraphs>
        <div className="tw-flex tw-flex-wrap tw-pb-2 tw-py-8">
          {adventages.map((i) => (
            <div key={i.id} className="tw-text-white tw-w-4/12 tw-p-2">
              <span className="icon-ic_paso_1" style={{ fontSize: 60 }} />
              <div className="tw-flex tw-py-3">
                <Paragraphs
                  size="xs"
                  className="tw-bg-tertiary tw-px-2 tw-py-1"
                  weight="bold"
                >
                  {t('Paso ', { step: i.id })}
                </Paragraphs>
              </div>
              <Trans
                i18nKey="Crea una cuenta con los datos del titular de la farmacia"
                components={{ bold: <Paragraphs weight="bold" /> }}
              />
            </div>
          ))}
        </div>
      </div>
      <Button
        onClick={navigateTo}
        mode="transparent-white-border"
        textColor="white"
        label={t('Ya estoy registrado')}
        uppercase
      />
    </BannerPage>
  )
}

LeftRegister.propTypes = {
  navigateTo: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default LeftRegister
