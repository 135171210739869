import React from 'react'
// import PropTypes from 'prop-types'
import Rating from '../Rating'
import RatingsLogo from '../RatingsLogo'

/**
    * @author   mromero
    * @return {React.Component}
    */
const FooterRatings = () => {
  return (
  // <div className='footer-ratings tw-bg-negroVf tw-flex tw-py-10'>
            <div className='tw-w-full tw-bg-negroVf tw-flex tw-flex-rows tw-flex-wrap tw-gap-y-2 tw-pt-8 tw-pb-8 tw-px-3'>
                <div className='tw-col-span-1 tw-pr-8 tw-pb-5'>
                    <RatingsLogo />
                </div>
                <div className='tw-flex tw-flex-wrap tw-flex-grow tw-gap-5'>
                    <Rating
                        rating={6}
                        ratingAuthor="Farners F."
                        ratingDate='2021-09-11T00:00:00'
                        ratingExcerpt="Prueba de resumen de valoración.... dsklfjsdlk fjkl s"
                        starsNum={3}
                    />
                        <Rating
                            rating={6}
                            ratingAuthor="Jordi G."
                            ratingDate='2021-05-07T00:00:00'
                            ratingExcerpt="Fedefarma lo hizo muy bien"
                            starsNum={4}
                        />

                        <Rating
                            rating={6}
                            ratingAuthor="Ana M."
                            ratingDate='2021-10-24T00:00:00'
                            ratingExcerpt="Lorem ipsum dolor sit amet, consectetur elit, s"
                            starsNum={4}
                        />

                        <Rating
                            rating={6}
                            ratingAuthor="Jesús M."
                            ratingDate='2021-02-28T00:00:00'
                            ratingExcerpt="Lorem ipsum dolor sit amet, consectetur elit, sjsdh sdkfh sdkjfh ksdjhf "
                            starsNum={4}
                        />
                        <Rating
                            rating={6}
                            ratingAuthor="Ana M."
                            ratingDate='2021-08-31T00:00:00'
                            ratingExcerpt="Lorem ipsum dolor sit amet, consectetur elit, s"
                            starsNum={2}
                        />
                </div>
            </div>
  // </div>
  )
}

FooterRatings.propTypes = {

}

export default FooterRatings
