import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {Number} size
 * @param {String} color
 * @param {String} className
 * @param {String} hoverColor
 * @returns
 */

const IcAssistance = ({ size, color, className }) => (
  <svg id="ic_banner_destacado_asistencia" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18">
  <rect id="Rectángulo_3108" data-name="Rectángulo 3108" width="18" height="18" fill="none"/>
  <g id="Grupo_7303" data-name="Grupo 7303" transform="translate(22844 23489)">
    <path id="Sustracción_10" data-name="Sustracción 10" d="M14,14H5.5V12.5h7V7a5.5,5.5,0,1,0-11,0v.115L0,7A7,7,0,0,1,14,7v7Z" transform="translate(-22842 -23487)" fill={color}/>
    <path id="Intersección_1" data-name="Intersección 1" d="M7.168,3.086a4,4,0,0,1-3.163,3.7A4,4,0,0,1,7.168,3.086Z" transform="translate(-22843 -23487)" fill={color}/>
    <path id="Intersección_2" data-name="Intersección 2" d="M3.158,3.088A4.005,4.005,0,0,1,7.883,6.034,4.005,4.005,0,0,1,3.158,3.088Z" transform="translate(-22839 -23487)" fill={color}/>
    <path id="Rectángulo_3104" data-name="Rectángulo 3104" d="M1,0H2.5a0,0,0,0,1,0,0V5a0,0,0,0,1,0,0H1A1,1,0,0,1,0,4V1A1,1,0,0,1,1,0Z" transform="translate(-22843 -23481)" fill={color}/>
    <path id="Rectángulo_3107" data-name="Rectángulo 3107" d="M0,0H1.5a1,1,0,0,1,1,1V4a1,1,0,0,1-1,1H0A0,0,0,0,1,0,5V0A0,0,0,0,1,0,0Z" transform="translate(-22829.5 -23481)" fill={color}/>
    <rect id="Rectángulo_3106" data-name="Rectángulo 3106" width="1.5" height="4" transform="translate(-22829.5 -23480)" fill={color}/>
    <circle id="Elipse_428" data-name="Elipse 428" cx="1" cy="1" r="1" transform="translate(-22838 -23480)" fill={color}/>
    <circle id="Elipse_429" data-name="Elipse 429" cx="1" cy="1" r="1" transform="translate(-22834 -23480)" fill={color}/>
  </g>
</svg>

)
IcAssistance.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  hoverColor: PropTypes.string
}

IcAssistance.defaultProps = {
  size: 30,
  color: '#000',
  className: '',
  hoverColor: '#8F3087'
}
export default IcAssistance
