export const adventages = [
  {
    id: 1,
    description: '<bold>Crea una cuenta</bold> con los datos del titular de la farmacia',
    icon: 'icon-ic_paso_1'
  },
  {
    id: 2,
    icon: 'icon-ic_paso_2',
    description: '<bold>Nos pondremos en contacto</bold> contigo en menos de 24 h.'
  },
  {
    id: 3,
    description: '<bold>Accede a vadefarma</bold> y haz tu primer pedido.',
    icon: 'icon-ic_paso_3'
  }
]

export const infoLogin = [
  {
    id: 1,
    description: 'descripción',
    icon: 'icon-ic_seguimiento_reparto',
    title: 'Titulo'

  },
  {
    id: 2,
    icon: 'icon-ic_ventaja_novedades',
    description: 'descripción',
    title: 'Titulo'

  },
  {
    id: 3,
    description: 'descripción',
    icon: 'icon-ic_ventaja_asistencia',
    title: 'Titulo'

  },
  {
    id: 4,
    description: 'descripción',
    icon: 'icon-ic_ventaja_ofertas',
    title: 'Titulo'

  },
  {
    id: 3,
    description: 'descripción',
    icon: 'icon-ic_ventaja_seguro',
    title: 'Titulo'
  }
]
