import React from 'react'
import InfoBox from '../InfoBox'
import ShippingLocator from '../ShippingLocator/ShippingLocator'
// import PropTypes from 'prop-types'

const InfoBoxesContainer = () => {
  const boxes = [
    {
      id: 1,
      boxType: 'order',
      boxText: 'Pedidos y encargos',
      haveAlerts: false
    },
    {
      id: 2,
      boxType: 'invoice',
      boxText: 'Facturas',
      haveAlerts: false
    },
    {
      id: 3,
      boxType: 'alert',
      boxText: 'Alertas sanitarias',
      haveAlerts: true
    }
  ]

  return (
        <div className='info-boxes-container tw-flex tw-flex-wrap tw-justify-start'>
            {boxes.map(box =>
            <div key={box.id} className=' tw-w-full md:tw-w-6/12 md:tw-p lg:tw-w-2/12 tw-pr-2 tw-pt-2'>
              <InfoBox boxType={box.boxType} boxText={box.boxText} haveAlerts={box.haveAlerts} />
            </div>
            )}
            <div className=' tw-w-full md:tw-w-6/12 lg:tw-w-6/12 tw-pr-2 tw-pt-2 lg:tw-pr-0' ><ShippingLocator /></div>
        </div>
  )
}

InfoBoxesContainer.propTypes = {

}

export default InfoBoxesContainer
