import styled, { keyframes } from 'styled-components'
const fadeIn = keyframes`
  0% {
    right: -300px;
  }
  100% {
    right: 30px;
  }
`
export const FlashContent = styled.div`
    padding: 6px;
    min-width: 300px;
    position:fixed;
    top: ${props => props.items * 60}px;
    z-index: 9999;
    animation: .2s ${fadeIn} ease-out;
    animation-fill-mode: forwards;

`
