import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'
/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcAnotacionesClose = ({ size, color }) => {
  return (
        <Icon
            className="IcFavorite material-icons tw-items-center tw-justify-center"
            size={size}
            color={color}
        >
            <svg id="ic_sidebar_notas" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <rect id="Rectángulo_1289" data-name="Rectángulo 1289" width="24" height="24" fill="none" />
                <g id="Grupo_6907" data-name="Grupo 6907" transform="translate(-1)">
                    <path id="Trazado_2038" data-name="Trazado 2038" d="M-.5,0h17V14H3.5l-4,2Z" transform="translate(5.5 4)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <rect id="Rectángulo_3082" data-name="Rectángulo 3082" width="8" height="1.5" transform="translate(11.362 7.391) rotate(45)" fill="#ffffff" />
                    <rect id="Rectángulo_3083" data-name="Rectángulo 3083" width="8" height="1.5" transform="translate(10.301 13.047) rotate(-45)" fill="#ffffff" />
                </g>
            </svg>
        </Icon>
  )
}

IcAnotacionesClose.defaultProps = {
  size: 24
}

IcAnotacionesClose.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
}

export default IcAnotacionesClose
