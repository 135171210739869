import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'

/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcCheck = ({ size, color, className }) => {
  return (
    <Icon
      className={`IcCheck material-icons ${
        className || 'tw-block tw-items-center tw-justify-center'
      }`}
      size={size}
      color={color}
    >
      check
    </Icon>
  )
}

IcCheck.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default IcCheck
