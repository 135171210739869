import React from 'react'
import Paragraphs from '../Paragraphs'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import IcSearch from '../Icons/IcSearch'
import IcCartDoted from '../Icons/IcCartDoted/IcCartDoted'
import CartButton from '../CartButton'

/**
 * @author mromero
 * @returns {React.Component}
 */
const HeaderBarPage = ({ showSearch, showCart, links, title, showCartButton, onPressLink }) => {
  const { t } = useTranslation()
  return (
    <div className='top-menu-links tw-flex tw-flex-row tw-justify-between'>
      <div className='tw-flex tw-flex-col tw-flex-wrap tw-self-center'>
       {title && <div className='top-menu-title'>
          <Paragraphs weight='bold' size='xl'>{title}</Paragraphs>
        </div>}
        <div className='tw-flex tw-flex-row'>
        {

          links && links.map(link =>
             <span onClick={() => onPressLink(link)} key={link.id} className=''>
                   <Paragraphs size='sm' className='tw-cursor-pointer tw-text-primary tw-pr-5 hover:tw-underline' weight='bold'>{t(`${link.text}`)}</Paragraphs>
             </span>)
         }
        </div>

      </div>
      <div className='tw-flex tw-flex-row tw-items-center'>
        {showSearch && <div><IcSearch color='#000' size={26}/></div>}
        {showCart && <div className=' tw-pl-2'><IcCartDoted color='#000' size={26} haveItems={true}/></div>}
        {showCartButton && <CartButton />}
      </div>
    </div>
  )
}

HeaderBarPage.propTypes = {
  showSearch: PropTypes.bool,
  showCart: PropTypes.bool,
  links: PropTypes.array,
  showCartFilled: PropTypes.bool,
  title: PropTypes.string,
  showCartButton: PropTypes.bool,
  onPressLink: PropTypes.func
}

HeaderBarPage.defaultProps = {
  links: []
}
export default HeaderBarPage
