const columns = [
  {
    id: 1,
    text: '¿Necesitas ayuda?',
    type: 'header',
    items: [
      {
        id: 1,
        type: 'link',
        text: '34937060371',
        link: 'tel:+34633183809'

      },
      {
        id: 2,
        type: 'link',
        text: '34960450103',
        link: 'tel:+34633183809'
      },
      {
        id: 3,
        type: 'link',
        text: 'sas@fedefarma.com',
        link: 'mailto:sas@fedefarma.com'

      }
    ]
  },
  {
    id: 2,
    text: 'Catálogo',
    type: 'header',
    items: [
      {
        id: 1,
        text: 'Productos',
        type: 'link',
        link: '#',
        items: [
          {
            id: 1,
            type: 'link',
            text: 'Natural',
            link: 'https://wwww.google.es'
          },
          {
            id: 2,
            type: 'path',
            text: 'Ortopedia',
            path: '/productos'
          },
          {
            id: 3,
            type: 'link',
            text: 'Veterinaria',
            link: '#'
          },
          {
            id: 4,
            type: 'link',
            text: 'Dermocosmética',
            link: '#'
          }
        ]
      },
      {
        id: 3,
        type: 'link',
        text: 'Promociones',
        path: 'promociones',
        items: []
      },
      {
        id: 4,
        type: 'link',
        text: 'Marcas',
        link: '#',
        items: []
      },
      {
        id: 5,
        type: 'link',
        text: 'Novedades',
        link: '#',
        items: []
      },
      {
        id: 6,
        type: 'link',
        text: 'Outlet',
        link: '#',
        items: []
      }
    ]
  },
  {
    id: 3,
    text: 'Sobre *Vadefarma',
    type: 'header',
    items: [
      {
        id: 1,
        type: 'link',
        text: 'Lorem ipsum dolor',
        link: '#'
      },
      {
        id: 3,
        type: 'link',
        text: 'Consectetur adipiscing elit',
        link: '#'
      },
      {
        id: 4,
        type: 'link',
        text: 'Sed do eiusmod',
        link: '#'
      },
      {
        id: 5,
        type: 'image',
        src: 'https://www.trobadafedefarma.com/wp-content/themes/fede-2/img/logo-fedefarma-white.png',
        alt: 'logo-image'
      },
      {
        id: 6,
        type: 'blank-line'
      }
    ]
  }
]

export default columns
