import React from 'react'
import { Line } from './styles'
import PropTypes from 'prop-types'

/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcFilters = ({ color }) => {
  return (
    <div className="IcFilters">
      <Line className="tw-rounded" color={color} />
      <Line className="tw-rounded" small color={color} />
      <Line className="tw-rounded" small color={color} />
    </div>
  )
}

IcFilters.propTypes = {
  color: PropTypes.string
}

export default IcFilters
