import { useSelector, useDispatch } from 'react-redux'
import { actionAddProductToCart } from '../../redux/actions/cart'
import useFlashMessage from '../useFlashMessage'
import { useMutation } from '../useMutation/useMutation'

export const useCart = () => {
  const { showMessage } = useFlashMessage()
  const [fetchData, { loading }] = useMutation('cart')
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cartReducer)
  const addProductToCart = async (quantity, productId) => {
    if (productId) {
      const values = {
        quantity,
        product_id: productId
      }

      if (cart.id) {
        values.cart_id = cart.id
      }
      const { data, success } = await fetchData({
        method: 'POST',
        data: values
      })
      if (success) {
        showMessage({
          type: 'success',
          message: 'Producto añadido al carrito'
        })
        dispatch(actionAddProductToCart(data?.data?.cart))

        return {
          success: true
        }
      } else {
        return {
          success: false
        }
      }
    }
  }

  return { addProductToCart, loading, cart }
}
