import React from 'react'
import PropTypes from 'prop-types'
import { ContentContainer } from './styles'

/**
 * @author ehernandez
 * @returns {React.Component}
 */
const Container = ({ name, className, children }) => {
  return (
        <ContentContainer className={`page-container ${name} ${className}`}>
            {children}
        </ContentContainer>
  )
}

Container.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Container
