import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../../infrastructure/styles/colors'

/**
 *
 * @param {Number} size
 * @param {Color} color
 * @returns
 */
const IcInvoiceVF = ({ size, color }) => (
  <svg id="ic_menu_vadefarma_facturas" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 38 38">
    <path id="Trazado_2280" data-name="Trazado 2280" d="M0,0H38V38H0Z" fill="none"/>
    <path id="Unión_90" data-name="Unión 90" d="M5.73,19.77H2.055a1.964,1.964,0,1,1,0-3.924H5.042l-.034-.321a11.933,11.933,0,0,1-.039-1.209c0-.5,0-1.012.047-1.481l.071-.917H2.055a1.964,1.964,0,1,1,0-3.924H6.092a12.763,12.763,0,0,1,4.024-5.556A12.016,12.016,0,0,1,17.656,0a11.4,11.4,0,0,1,8.875,3.567l.015.017a2.2,2.2,0,0,1,.629,1.5,2.181,2.181,0,0,1-2.235,2.06,2.08,2.08,0,0,1-1.578-.65,7.512,7.512,0,0,0-5.84-2.68,6.973,6.973,0,0,0-6.489,4.182h7.458a1.964,1.964,0,1,1,0,3.924H9.915l-.072.747c-.047.5-.047,1.041-.047,1.561s0,1.02.047,1.489l.014.131h8.635a1.964,1.964,0,1,1,0,3.924H10.676l.214.485a7.176,7.176,0,0,0,6.9,4.477,7.551,7.551,0,0,0,5.934-2.906l.012-.01a1.955,1.955,0,0,1,1.508-.668,1.981,1.981,0,0,1,2.023,1.931,2.183,2.183,0,0,1-.442,1.237,11.077,11.077,0,0,1-9.035,4.223A11.983,11.983,0,0,1,5.73,19.77Z" transform="translate(5.002 4.929)" fill={colors[color]} stroke="none" strokeWidth="0.4"/>
  </svg>
)
IcInvoiceVF.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
}

IcInvoiceVF.defaultProps = {
  size: '24px',
  color: '#fff'
}

export default IcInvoiceVF
