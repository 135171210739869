import React, { useEffect, useState } from 'react'
import ProductDetail from '../components/ProductDetail/ProductDetail'
import Page from '../../../components/Page'
import ProductListBlock from '../../../components/ProductsListBlock'
import PropTypes from 'prop-types'
import useProduct from '../../../infrastructure/hooks/useProduct'
import Carousell from '../../../components/Carousell'
import TopSearch from '../../../components/TopSearch'
import HeaderBarPage from '../../../components/HeaderBarPage'
import { menuLinks } from '../../Home/constants/constants'
import { useSelector } from 'react-redux'

/**
 * @author mromero
 * @returns {React.Component}
 */
const DetailScreen = ({ route }) => {
  const { getProduct, data, images, extra, getProductList } = useProduct()
  const [list, setList] = useState()
  const { currentProductId } = useSelector(state => state.searchReducer)
  console.log({ currentProductId })
  useEffect(() => {
    // getProduct(route?.params?.id, true, true)
    getProduct(currentProductId, true, true)
    getProductList().then(res => {
      if (res.success) setList(res.data.items)
    })
  }, [])
  console.log(data, '----')
  console.log({ route })
  return (
      <Page.Container name='catalog-screen' className=' tw-bg-white tw-flex tw-flex-col'>
        <Carousell />
        <Page.Header className='header tw-flex tw-flex-col tw-px-4 tw-justify-center'>
          <HeaderBarPage links={menuLinks} />
        </Page.Header>
        <Page.Body className='body tw-flex tw-flex-col tw-pb-8 tw-gap-8 tw-px-4'>
          <TopSearch />
          <div className='tw-h-[500px]'>
            <ProductDetail
            data={data}
            images={images}
            extra={extra}
            />
          </div>
        <div className='tw-flex tw-flex-col tw-gap-4 tw-bg-secondary tw-bg-opacity-10 tw-px-4 tw-py-8'>
            <ProductListBlock products={list} title='Productos relacionados' />
        </div>
        <div className='tw-flex tw-flex-col tw-gap-4 tw-bg-white tw-px-4 tw-py-4'>
            <ProductListBlock products={list} title='Vistos recientemente' />
        </div>
        </Page.Body>
      </Page.Container>

  )
}

DetailScreen.propTypes = {
  route: PropTypes.object
}

export default DetailScreen
