import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../../infrastructure/styles/colors'

/**
 *
 * @param {Number} size
 * @returns
 */
const IcOrderVF = ({ size, color }) => (
  <svg id="ic_menu_vadefarma_pedidos" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 38 38">
    <g id="Grupo_5681" data-name="Grupo 5681" transform="translate(5.001 4.995)">
      <path id="Unión_98" data-name="Unión 98" d="M1.614,28.007A1.585,1.585,0,0,1,0,26.451V1.557A1.585,1.585,0,0,1,1.614,0H27.389A1.584,1.584,0,0,1,29,1.557V20.51a1.528,1.528,0,0,1-.472,1.1l-6.151,5.94a1.638,1.638,0,0,1-1.139.456Zm1.611-3.112h14.5V18.671a1.584,1.584,0,0,1,1.611-1.556h6.443v-14H3.225Zm17.721-.362L25.4,20.228H20.946ZM8.058,15.56a1.557,1.557,0,1,1,0-3.112H14.5a1.557,1.557,0,1,1,0,3.112Zm0-6.224a1.557,1.557,0,1,1,0-3.112H20.946a1.557,1.557,0,1,1,0,3.112Z" transform="translate(0 0.004)" fill={colors[color]}/>
    </g>
    <path id="Trazado_2073" data-name="Trazado 2073" d="M0,0H38V38H0Z" fill="none"/>
  </svg>

)
IcOrderVF.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
}

export default IcOrderVF
