import styled from 'styled-components'

export const SidebarInnerContainer = styled.div`
  width: 56px;
`

export const SidebarBtnMenu = styled.div`
  height: 60px;
`

export const SidebarOutterContainer = styled.div`
  left: 50px;
  & .sidebar-outter-container-content {
    width: 250px;
  }
`

export const SidebarContentHeader = styled.div`
  height: 100px;
`
