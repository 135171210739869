import { useDispatch } from 'react-redux'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'
import { useMutation } from '../../../../infrastructure/hooks/useMutation/useMutation'
import { actionSetTokenSuccess } from '../../../../infrastructure/redux/actions/user'

/**
 * @description Hook to get the login state
 * @author ehernandez
 * @returns {Object}
 */
export const useLogin = () => {
  const [fetchLogin] = useMutation('login')
  const [fetchRegister] = useMutation('sign-up')
  const dispatch = useDispatch()
  const { showMessage } = useFlashMessage()

  /**
   * @author ehernandez
   * @param {Object} user
   * @returns {Object}
   */
  const onLogin = async (user) => {
    const { data, success, error } = await fetchLogin({
      data: user
    })
    if (success) {
      if (data) {
        dispatch(actionSetTokenSuccess(data?.access_token, data.user))
      }
    }
    return {
      success,
      data,
      error
    }
  }

  /**
   * @author ehernandez
   * @param {Object} user
   * @returns {Object}
   */
  const onRegister = async (user) => {
    const { data, success, error } = await fetchRegister({
      data: user
    })

    if (success) {
      return await onLogin(user)
    }
    if (error) {
      if (error.message === 'Validation Error' && error.errors) {
        console.log(error, '.....')
        showMessage({ message: error.errors[Object.keys(error.errors)[0]][0], type: 'alert' })
      }
    }
    return {
      success,
      data,
      error
    }
  }

  return { onLogin, onRegister }
}
