import React, { useState } from 'react'
import Paragraphs from '../Paragraphs'
import UserPopover from '../UserPopover/UserPopover'
import { ActiveNotification, ContainerBallUser } from './styles'
import PropTypes from 'prop-types'

/**
 * @author ehernandez
 * @returns {React.Component}
 */
const Avatar = ({ user, onClickProfile }) => {
  const [showUserPopover, setShowUserPopover] = useState(false)
  return (
    <div className='avatar tw-px-4 tw-cursor-pointer'>
      <div className='tw-flex tw-text-white' onClick={() => setShowUserPopover(!showUserPopover)}>
        <div className='tw-pr-3 tw-text-right' >
          <Paragraphs size='xs' weight='bold'>{user?.name}</Paragraphs>
          <Paragraphs size='xxxs'>{user?.email}</Paragraphs>
        </div>
        <ContainerBallUser className='tw-relative tw-bg-primary'>
          <ActiveNotification className='tw-absolute' />
        </ContainerBallUser>
      </div>
      {showUserPopover && <UserPopover onClickProfile={onClickProfile} closeUserPopover= {() => setShowUserPopover(!showUserPopover)}/>}
    </div>
  )
}

Avatar.propTypes = {
  user: PropTypes.object,
  onClickProfile: PropTypes.func
}

export default Avatar
