import React, { useState, useEffect, useRef } from 'react'
import useOnScreen from '../../infrastructure/hooks/useOnScreen'
import PropTypes, { bool } from 'prop-types'
import Paragraphs from '../Paragraphs'
import Icons from '../Icons'
import CartButton from '../CartButton/CartButton'
import { useNavigation } from '../../infrastructure/context/StackContext/StackContext'
import colors from '../../infrastructure/styles/colors'
import useProduct from '../../infrastructure/hooks/useProduct'
import { setCurrentProductId } from '../../infrastructure/redux/actions/search' // actionResetSearchTerm
import { useDispatch } from 'react-redux'

/**
 *  @author mromero
 * @param {name} the name of the product
 * @param {isNew} if a product is new
 * @param {discount} % of discount
 * @param {cn} Cn number
 * @param {lab} Laboratori name
 * @param {price}
 * @param {isFavorite}
 * @param {imgSrc}
 * @returns {React.Component}
 */
const ProductCard = ({
  name,
  isNew,
  discount,
  cn,
  lab,
  price,
  isFavorite,
  manufacturer,
  id_product: idProduct,
  id
}) => {
  const [favorite, setFavorite] = useState(isFavorite)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const navigation = useNavigation()
  const { extra, getProductFede, images } = useProduct()
  const ref = useRef()
  const isVisible = useOnScreen(ref)
  const dispatch = useDispatch()

  useEffect(() => {
    if (idProduct && isVisible && !isDataLoaded) {
      getProductFede(idProduct, true, cn)
      setIsDataLoaded(true)
    }
  }, [isVisible])

  const onClickProduct = () => {
    dispatch(setCurrentProductId({ productId: id }))
    navigation.navigate({
      name: 'Catálogo',
      path: 'product-detail',
      openHome: true
    }, { id })
  }

  // console.log({ images })

  return (
    <div ref={ref} className="product-card tw-relative  hover:tw-shadow-lg tw-p-4 tw-bg-white  hover:tw-border-none tw-flex-col tw-w-full tw-h-[400px] tw-justify-between tw-border tw-border-slate-200 hover:tw--translate-y-1 tw-duration-200">
      <div className="attributtes tw-absolute tw-left-2 tw-top-2">
        {isNew && (
          <Paragraphs
            size="xxs"
            className=" tw-bg-green tw-py-1 tw-text-white tw-px-3"
          >
            NUEVO
          </Paragraphs>
        )}
      </div>
      <div className="btn-favorite tw-absolute tw-right-2 tw-top-2">
        <div
          className=" tw-flex tw-py-1 tw-cursor-pointer tw-z-10"
          onClick={() => setFavorite(!favorite)}
        >
          <Icons.IcFavoriteSVG
            size={18}
            color={colors.alert}
            borderColor={colors.alert}
            selected={favorite}
          />
        </div>
      </div>
      <div onClick={onClickProduct} className='tw-flex tw-flex-col tw-h-full tw-cursor-pointer' >
      <div className="tw-flex-1 tw-flex tw-items-center tw-justify-center tw-overflow-hidden">
        {
          images && images.length > 0 && (<img src={images[0].src}/>)
        }

      </div>
      <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-h-full">
        <div className="">
          <div className="tw-pt-2">
            <Paragraphs className="tw-text-primary" size="xxs" weight="bold">
              CN: {cn}
            </Paragraphs>
          </div>
          <div className="tw-pt-2 tw-overflow-hidden tw-h-[50px]">
            <Paragraphs size="sm" weight="bold">
              {name}
            </Paragraphs>
          </div>
          <div className="tw-pt-2">
            <Paragraphs
              size="xxs"
              color="#00000080"
              className=" tw-cursor-pointer hover:tw-underline"
            >
              {manufacturer?.name}
            </Paragraphs>
          </div>
        </div>

        <div className="tw-flex tw-flex-col tw-h-1/2 tw-justify-end">
          <Paragraphs size="sm" className='tw-text-alert'>
            Quedan pocas unidades
          </Paragraphs>
          <Paragraphs size="xxs">Multizona (Terrassa)</Paragraphs>
        </div>
        <div className="tw-flex tw-justify-between tw-items-end">
          <div className="tw-flex tw-flex-row tw-gap-1 tw-items-baseline">
            <Paragraphs
              className=" tw-align-text-bottom tw-text-tertiary"
              size="lg"
              weight="bold"
            >
              {extra?.price ? extra.price : '-'}
              {/* {discount
                ? (price - (discount * price) / 100).toFixed(2)
                : price.toFixed(2)} */}
            </Paragraphs>
            <Paragraphs className="tw-text-tertiary" size="xxs" weight="bold">
              €
            </Paragraphs>
            {discount && (
              <Paragraphs
                className=" tw-line-through tw-text-tertiary"
                size="xxs"
                weight="bold"
              >
                {price} €
              </Paragraphs>
            )}
          </div>
          <CartButton onClick={() => console.log(`add ${cn} to cart`)} />
        </div>
        </div>
      </div>
      {/* <div className="tw-flex tw-flex-col tw-h-full tw-justify-items-end tw-px-1 tw-pt-1">
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-flex tw-flex-row tw-h-fit tw-text-center tw-z-20 tw-gap-1">
            {isNew && (
              <Paragraphs
                color="white"
                size="xxs"
                className=" tw-bg-green tw-p-1"
              >
                NUEVO
              </Paragraphs>
            )}
            {discount && (
              <Paragraphs
                color="white"
                size="xxs"
                className=" tw-bg-red-500 tw-p-1"
              >
                {discount} %
              </Paragraphs>
            )}
          </div>
          <div
            className=" tw-flex tw-py-1 tw-cursor-pointer"
            onClick={() => setFavorite(!favorite)}
          >
            <Icons.IcFavoriteSVG
              size={18}
              color={colors.alert}
              borderColor={colors.alert}
              selected={favorite}
            />
          </div>
        </div>

        <div
          className="tw-flex tw-h-3/6 tw-justify-center"
          onClick={() =>
            navigation.navigate({
              name: 'Catálogo',
              path: 'catalog',
              openHome: true,
            })
          }
        >
          <img className=" tw-object-contain" src={imgSrc} />
        </div>
        <div
          className="tw-flex tw-flex-col tw-h-3/6 tw-justify-between"
          onClick={() =>
            navigation.navigate({
              name: 'Catálogo',
              path: 'product-detail',
              openHome: true,
            })
          }
        >
          <div className="tw-flex tw-flex-col  tw-h-1/2 tw-justify-between">
            <div className="tw-flex tw-h-1/6">
              <Paragraphs color="#8F3087" size="xxs" weight="bold">
                CN: {cn}
              </Paragraphs>
            </div>
            <div className="tw-flex tw-h-4/6">
              <Paragraphs size="xs" weight="bold" className="tw-pt-2 ">
                {name}
              </Paragraphs>
            </div>
            <div className="tw-flex tw-h-1/6">
              <Paragraphs
                size="xxs"
                color="#00000080"
                className=" tw-cursor-pointer hover:tw-underline"
              >
                {lab}
              </Paragraphs>
            </div>
          </div>
          <div className=" tw-flex tw-flex-col tw-h-1/2 tw-justify-between">
            <div className="tw-flex tw-flex-col tw-h-1/2 tw-justify-end">
              <Paragraphs color="red" size="xxxs">
                Quedan pocas unidades
              </Paragraphs>
              <Paragraphs size="xxxs">Multizona (Terrassa)</Paragraphs>
            </div>
            <div className="tw-flex tw-flex-col tw-h-1/2 tw-justify-end"></div>
          </div>
        </div>
      </div>
      <div className='tw-flex tw-justify-between tw-items-end'>
        <div className="tw-flex tw-flex-row tw-gap-1 tw-items-baseline">
          <Paragraphs
            className=" tw-align-text-bottom"
            color="#C8B800"
            size="lg"
            weight="bold"
          >
            {discount
              ? (price - (discount * price) / 100).toFixed(2)
              : price.toFixed(2)}
          </Paragraphs>
          <Paragraphs color="#C8B800" size="xxs" weight="bold">
            €
          </Paragraphs>
          {discount && (
            <Paragraphs
              className=" tw-line-through"
              color="#C8B800"
              size="xxs"
              weight="bold"
            >
              {price} €
            </Paragraphs>
          )}
        </div>
        <CartButton onClick={() => console.log(`add ${cn} to cart`)} />
      </div> */}
    </div>
  )
}

ProductCard.propTypes = {
  id_product: PropTypes.number,
  name: PropTypes.string,
  isFavorite: bool,
  isNew: PropTypes.bool,
  discount: PropTypes.number,
  cn: PropTypes.string,
  lab: PropTypes.string,
  price: PropTypes.number,
  manufacturer: PropTypes.string,
  id: PropTypes.number
}

export default ProductCard
