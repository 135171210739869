import React from 'react'
import Paragraphs from '../Paragraphs'
// import youtubeSVG from './assets/Youtube.svg'
// import instagramSVG from './assets/Instagram.svg'
import Icons from '../Icons'
import colors from '../../infrastructure/styles/colors'

// import { LinkContainer } from './styles'
// import { useNavigation } from '../../infrastructure/context/StackContext/StackContext'
// import PropTypes from 'prop-types'
/**
 * @author mromero
 * @returns {React.Component}
 */
const FooterLegalAndSocial = () => {
  // const navigation = useNavigation()
  const legalSocialItems = [
    {
      id: 1,
      text: 'Contacto',
      path: '/contacto'
    },
    {
      id: 2,
      text: 'Política de privacidad',
      path: '/politica-de-privacidad'
    },
    {
      id: 3,
      text: 'Política de Cookies',
      path: '/politica-de-cookies'
    },
    {
      id: 4,
      text: 'Aviso legal',
      path: '/aviso-legal'
    },
    {
      id: 5,
      text: 'Copyright',
      path: '/copyright'
    }
  ]
  /**
    *
    *
    * @author   mromero
    * @param   {Object} item
    */
  // const onClik = (item) => {
  //   return;
  //   if (item.path) {
  //     navigation.navigate({ paths: item.path, id: 999 })
  //   } else {
  //     window.open(item.link, '_self')
  //   // }
  // }

  return (
        <div className='footer-legal-and-social tw-bg-black tw-flex tw-justify-between tw-flex-row tw-flex-wrap tw-gap-4 tw-px-3'>
            <div className='tw-flex tw-flex-wrap tw-gap-6 tw-items-center'>
                {legalSocialItems.map(item => (
                    <div key={item.id} className='tw-py-3 tw-cursor-pointer tw-border-b-2 tw-border-transparent hover:tw-border-lilaVF'>
                     <Paragraphs key={item.id} className='tw-text-white' size="xs" weight="bold">{item.text}</Paragraphs>
                    </div>
                ))}
                <div>
                  <Paragraphs size='xs' className='tw-text-white/60' >© Vadefarma {new Date().getFullYear()}</Paragraphs>
                </div>
            </div>
            <div className='tw-flex tw-flex-row tw-content-center tw-gap-x-4 tw-pr-2'>
              <div className='tw-cursor-pointer tw-self-center'>
               <Icons.IcYoutube size={28} color='#fff' bgColor='#fff' bgOpacity={0.2} colorOver={colors.primary} bgColorOver='#fff' bgOpacityOver={1} />
              </div>
              <div className='tw-cursor-pointer tw-self-center'>
               <Icons.IcFacebook size={28} color='#fff' bgColor='#fff' bgOpacity={0.2} colorOver={colors.primary} bgColorOver='#fff' bgOpacityOver={1} />
              </div>
              <div className='tw-cursor-pointer tw-self-center'>
               <Icons.IcInstagram size={28} color='#fff' bgColor='#fff' bgOpacity={0.2} colorOver={colors.primary} bgColorOver='#fff' bgOpacityOver={1} />
              </div>

            </div>
        </div>
  )
}

FooterLegalAndSocial.propTypes = {

}

export default FooterLegalAndSocial
