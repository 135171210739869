import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {number} size size icon
 * @returns {React.Component}
 */
const IcCartDoted = ({ size, color, haveItems }) => {
  return (
    <svg id="ic_menu_vadefarma_cesta" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <path id="Trazado_2295" data-name="Trazado 2295" d="M0,0H24V24H0Z" fill="none"/>
      <path id="Trazado_2671" data-name="Trazado 2671" d="M-19820-23064.621h2.051v3l.121,7.125h14.33" transform="translate(19823.25 23067.5)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Trazado_2672" data-name="Trazado 2672" d="M17,10a2,2,0,1,0-2-2A2,2,0,0,0,17,10Z" transform="translate(-9.234 10.5)" fill="none" stroke={color} strokeLinejoin="round" strokeWidth="2"/>
      <path id="Trazado_2673" data-name="Trazado 2673" d="M17,10a2,2,0,1,0-2-2A2,2,0,0,0,17,10Z" transform="translate(-0.234 10.5)" fill="none" stroke={color} strokeLinejoin="round" strokeWidth="2"/>
      <path id="Trazado_2674" data-name="Trazado 2674" d="M5.766,5.277h9.127" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <g id="Elipse_459" data-name="Elipse 459" transform="translate(16)" fill={haveItems ? '#3dbf42' : '#e5e5e5'} stroke="#fff" strokeWidth="2">
        <circle cx="4" cy="4" r="4" stroke="none"/>
        <circle cx="4" cy="4" r="3" fill="none"/>
      </g>
    </svg>
  )
}

IcCartDoted.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  haveItems: PropTypes.bool
}

IcCartDoted.defaultProps = {
  size: 24,
  color: '#0000000',
  haveItems: true
}

export default IcCartDoted
