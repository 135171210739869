import React, { useState } from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {Number} size
 * @param {String} color
 * @param {String} bgColor
 * @returns
 */

const IcFacebook = ({ size, color, bgColor, bgOpacity, colorOver, bgColorOver, bgOpacityOver }) => {
  const [mouseOver, setMouseOver] = useState(false)
  return (
    <svg className='ic-facebook' xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 26 26" onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}>
      <circle id="Elipse_529" data-name="Elipse 529" cx="13" cy="13" r="13" fill={mouseOver ? bgColorOver : bgColor} opacity={mouseOver ? bgOpacityOver : bgOpacity}/>
      <path id="Facebook-Logo.wine" d="M4.667,14H1.891V7.1H0V4.852H1.891v-1.6C1.891,1.359,2.727,0,5.5,0,6.081,0,7,.118,7,.118V2.2H6.035c-.984,0-1.368.3-1.368,1.123V4.852h2.3L6.759,7.1H4.667Z" transform="translate(9 6)" fill={mouseOver ? colorOver : color}/>
    </svg>
  )
}
IcFacebook.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  bgOpacity: PropTypes.number,
  colorOver: PropTypes.string,
  bgColorOver: PropTypes.string,
  bgOpacityOver: PropTypes.number
}

IcFacebook.defaultProps = {
  size: 30,
  color: '#000',
  bgColor: '#000',
  bgOpacity: 0.2,
  colorOver: '#000',
  bgColorOver: '#000',
  bgOpacityOver: 0.2
}
export default IcFacebook
