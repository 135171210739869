import React from 'react'
import PropTypes from 'prop-types'

const CarousellItem = ({ children, active }) => {
  return (
    <div className={`carousel-item ${active && 'active'} tw-relative tw-float-left tw-w-full`}>
    {children}
  </div>
  )
}

CarousellItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool
}

export default CarousellItem
