import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'
/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcEdit = ({ size }) => {
  return (
    <Icon
      className="IcEdit material-icons tw-flex tw-items-center tw-justify-center tw-text-primary"
      size={size}
    >
      edit
    </Icon>
  )
}

IcEdit.propTypes = {
  size: PropTypes.number.isRequired
}

export default IcEdit
