import React from 'react'
import IcLocationVF from '../../../../components/Icons/IcLocationVF'
import IcTruckVF from '../../../../components/Icons/IcTruckVF/IcTruckVF'
import Paragraphs from '../../../../components/Paragraphs'
// import PropTypes from 'prop-types'
/**
 * @returns {React.Component}
 */
const ShippingLocator = () => {
  console.log('ShippingLocator')
  return (
    <div className='info-box tw-flex tw-tw-flex-row tw-w-full tw-h-[84px] tw-bg-[#FBFAFC] tw-transition-colors tw-delay-150 tw-border-b-4 tw-border-transparent tw-cursor-pointer hover:tw-border-tertiary tw-items-center tw-px-4 tw-gap-4'>
        <IcLocationVF size={38} color='tertiary' />
        <Paragraphs weight='bold' size='base'>Localizador de pedidos</Paragraphs>
        <div className='tw-grow tw-flex-col'>
            <div className='tw-flex tw-flex-row tw-gap-2'>
            <IcTruckVF size={19} color='red'/>
            <Paragraphs color='red' size='xs' weight='bold'>En reparto</Paragraphs>
            </div>

            <div className='tw-flex tw-flex-row'>
                <Paragraphs size='xs'>Nº. Pedido: </Paragraphs>
                <Paragraphs size='xs' weight='bold'>2020-0000028</Paragraphs>
            </div>
        </div>
    </div>
  )
}

ShippingLocator.propTypes = {

}

export default ShippingLocator
