import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import useOnScreen from '../../infrastructure/hooks/useOnScreen'
import useProduct from '../../infrastructure/hooks/useProduct'
import colors from '../../infrastructure/styles/colors'
import Icons from '../Icons'
import Paragraphs from '../Paragraphs'
import CartButton from '../CartButton/CartButton'
import ProductPromotionsModal from '../ProductPromotionsModal/ProductPromotionsModal'
import { setCurrentProductId } from '../../infrastructure/redux/actions/search' // actionResetSearchTerm
import { useDispatch } from 'react-redux'
import { useNavigation } from '../../infrastructure/context/StackContext/StackContext'

/**
 * @author mromero
 * @param {*} props
 * @returns {React.Component}
 */
const ProductItemList = ({
  name,
  isNew,
  discount,
  cn,
  lab,
  price,
  isFavorite,
  manufacturer_id: manufacturerId,
  id_product: idProduct,
  id
}) => {
  const [favorite, setFavorite] = useState(isFavorite)
  const [showPromotions, setShowPromotions] = useState(false)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const cutPromotions = (promotions) => {
    if (promotions.length > 4) return promotions.slice(0, 3)
    return promotions
  }
  const { extra, getProductFede, images } = useProduct()
  const ref = useRef()
  const isVisible = useOnScreen(ref)
  const dispatch = useDispatch()
  useEffect(() => {
    if (idProduct && isVisible && !isDataLoaded) {
      console.log({ idProduct })
      getProductFede(idProduct, true, cn)
      setIsDataLoaded(true)
    }
  }, [isVisible])
  const navigation = useNavigation()
  const onClickProduct = () => {
    dispatch(setCurrentProductId({ productId: id }))
    navigation.navigate({
      name: 'Catálogo',
      path: 'product-detail',
      openHome: true
    }, { id })
  }

  return (
    <div
      ref={ref}
      className='product-item-list tw-bg-white tw-flex tw-flex-wrap tw-flex-row tw-h-36 tw-w-full tw-relative tw-border tw-border-slate-100 tw-p-[18px] hover:tw-shadow hover:tw--translate-y-1 tw-duration-200 tw-cursor-pointer'
      onClick={onClickProduct}
    >
        <div className=' tw-absolute tw-top-[18px] tw-left-[18px] tw-z-10'>
            <Icons.IcFavoriteSVG color={colors.alert} borderColor={colors.alert} size={18} selected={favorite} onClick={() => setFavorite(!favorite)}/>
        </div>
        <div className=' tw-cursor-pointer' onClick={onClickProduct}>

        </div>
        <div className="tw-flex-1 tw-flex tw-items-center tw-justify-center tw-overflow-hidden">
        {
          images && images.length > 0 && (<img className=' tw-object-scale-down tw-max-h-28' src={images[0].src}/>)
        }
        </div>

        <div className=' tw-flex tw-flex-col tw-w-4/6  tw-h-full tw-justify-between'>
            <div className=' tw-flex tw-flex-col'>
                <Paragraphs size='xxs' weight='bold' className=' tw-text-primary'>CN: {cn}</Paragraphs>
                <Paragraphs size='sm' weight='bold'>{name}</Paragraphs>
                <Paragraphs size='xs'>{manufacturerId?.name}</Paragraphs>
            </div>

            <ul className=' tw-flex tw-flex-col tw-flex-wrap tw-list-inside tw-list-disc tw-text-primary tw-max-h-16'>
                {extra?.promotions && cutPromotions(extra?.promotions).map(promotion =>
                    <li key={promotion}><Paragraphs className=' tw-inline-block tw-text-black' size='xs'>{promotion}</Paragraphs></li>
                )}
                {extra?.promotions?.length > 4 &&
                    <li className='tw-list-none' onClick={() => setShowPromotions(!showPromotions)}>
                        <Paragraphs size='xs' weight='bold' uppercase className=' tw-cursor-pointer tw-inline-block'>ver todas las promociones</Paragraphs>
                        {showPromotions && <ProductPromotionsModal promotions={extra?.promotions} closeModal={() => setShowPromotions(false)} className=' tw-absolute tw-top-3'/>}
                    </li>
                }

            </ul>
        </div>

        <div className=' tw-flex tw-flex-col tw-w-1/6  tw-h-full tw-justify-between'>
            <div className=' tw-flex tw-flex-col tw-justify-end'>
                <Paragraphs size='xs' weight='bold' color={extra?.has_stock ? colors.green : colors.alert} className={` tw-self-end ${extra?.has_stock ? ' tw-text-success' : ' tw-text-red-600'}`}>
                    {extra && (extra.has_stock ? 'Disponible' : 'No diponible')}
                 </Paragraphs>
                { extra?.nomalm && <Paragraphs size='xs' className=' tw-self-end'>{extra?.nomalm}</Paragraphs>}
            </div>
            <div className=' tw-flex tw-row tw-justify-end tw-gap-x-4'>
                <Paragraphs className=' tw-self-end' size='xl' weight='bold' color={colors.tertiary}>{extra?.price ? extra.price : '-'} <Paragraphs size='xs' className=' tw-inline-block' weight='bold'>€</Paragraphs></Paragraphs>
                <CartButton onClick={() => console.log('added to cart')}/>
            </div>
        </div>
    </div>
  )
}

ProductItemList.propTypes = {
  id_product: PropTypes.number,
  name: PropTypes.string,
  isFavorite: PropTypes.bool,
  isNew: PropTypes.bool,
  discount: PropTypes.number,
  cn: PropTypes.string,
  lab: PropTypes.string,
  price: PropTypes.number,
  manufacturer_id: PropTypes.object,
  id: PropTypes.number
}

export default ProductItemList

ProductItemList.propTypes = {
  product: PropTypes.object
}

ProductItemList.defaultProps = {
  product: {
    productName: 'Mascarilla FFP2 Kadi con Certificado CE Negra',
    isFavorite: true,
    available: true,
    whareHouse: 'Almacén Terrassa',
    isNew: true,
    discount: 50,
    promotions: [
      {
        id: 1,
        text: 'A partir de 10 uds - 10 % dt. s/PVFF • A partir de 20 uds - 15 % dt. s/PVFF'
      },
      {
        id: 2,
        text: 'A partir de 10 uds - 10 % dt. s/PVFF • A partir de 20 uds - 15 % dt. s/PVFF'
      }
    //   {
    //     id: 3,
    //     text: 'A partir de 10 uds - 10 % dt. s/PVFF • A partir de 20 uds - 15 % dt. s/PVFF'
    //   },
    //   {
    //     id: 4,
    //     text: 'A partir de 10 uds - 10 % dt. s/PVFF • A partir de 20 uds - 15 % dt. s/PVFF'
    //   }, {
    //     id: 5,
    //     text: 'A partir de 10 uds - 10 % dt. s/PVFF • A partir de 20 uds - 15 % dt. s/PVFF'
    //   }
    ],
    cn: '036260',
    lab: 'Kadi S.L.',
    price: 34.03,
    imgSrc: 'https://www.pharmashop.es/13999-big_default/priorin-triplo-3-x-60-capsulas-25-descuento.jpg'
  }
}
