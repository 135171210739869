import {
  SET_SEARCH_TERM,
  RESET_SEARCH_TERM,
  SET_SEARCH_LAB,
  RESET_SEARCH_LAB,
  SET_CURRENT_PRODUCT_ID
} from '../constants/search'

const laboratories =
  [
    {
      id: 1,
      name: 'Actafarma'
    },
    {
      id: 2,
      name: 'Adamed'
    },
    {
      id: 3,
      name: 'Alfasigma España'
    },

    {
      id: 4,
      name: 'Actafarma'
    },
    {
      id: 5,
      name: 'Adamed'
    },
    {
      id: 6,
      name: 'Alfasigma España'
    },

    {
      id: 7,
      name: 'Actafarma'
    },
    {
      id: 8,
      name: 'Adamed'
    },
    {
      id: 9,
      name: 'Alfasigma España'
    }
  ]

const initialState = {
  searchTerm: null,
  selectedLab: { id: null, name: null },
  laboratories: laboratories,
  currentProductId: null
}

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.term
      }

    case RESET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: null
      }

    case SET_SEARCH_LAB:
      console.log({ action })
      return {
        ...state,
        selectedLab: { id: action.id, name: action.name }
      }

    case RESET_SEARCH_LAB:
      return {
        ...state,
        selectedLab: initialState.selectedLab
      }

    case SET_CURRENT_PRODUCT_ID: {
      return {
        ...state,
        currentProductId: action.productId
      }
    }
    default:
      return state
  }
}

export default searchReducer
