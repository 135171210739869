import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {Number} size
 * @param {String} color
 * @param {String} className
 * @param {String} hoverColor
 * @returns
 */

const IcFollowUp = ({ size, color, className }) => (
  <svg id="ic_menu_vadefarma_seguimiento" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
  <path id="Trazado_2286" data-name="Trazado 2286" d="M0,0H24V24H0Z" fill="none"/>
  <g id="Grupo_6310" data-name="Grupo 6310" transform="translate(-256.625 -498.266)">
    <path id="Trazado_2287" data-name="Trazado 2287" d="M17,10a2,2,0,1,0-2-2A2,2,0,0,0,17,10Z" transform="translate(247.5 507.363)" fill="none" stroke={color} strokeLinejoin="round" strokeWidth="2"/>
    <path id="Trazado_2288" data-name="Trazado 2288" d="M17,10a2,2,0,1,0-2-2A2,2,0,0,0,17,10Z" transform="translate(255.5 507.363)" fill="none" stroke={color} strokeLinejoin="round" strokeWidth="2"/>
    <path id="Trazado_2289" data-name="Trazado 2289" d="M-19901.875-23061.234h10.887v4.883h4.881l1.75,1.75v5.148h-2.928" transform="translate(20162 23565)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <path id="Trazado_2290" data-name="Trazado 2290" d="M-19892-23061.234h4.291l1.49,5.037" transform="translate(20162 23565)" fill="none" stroke={color} strokeLinejoin="round" strokeWidth="2"/>
    <path id="Trazado_2291" data-name="Trazado 2291" d="M-19903.016-23058.3h3.043" transform="translate(20163.141 23565.451)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <path id="Trazado_2292" data-name="Trazado 2292" d="M-19903.016-23058.3h4.375" transform="translate(20163.141 23568.748)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <path id="Trazado_2293" data-name="Trazado 2293" d="M-19892-23049.453h-3.248" transform="translate(20162 23565)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <path id="Trazado_2294" data-name="Trazado 2294" d="M-19892.873-23049.453h-2.375" transform="translate(20155.373 23565)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  </g>
</svg>

)
IcFollowUp.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  hoverColor: PropTypes.string
}

IcFollowUp.defaultProps = {
  size: 30,
  color: '#000',
  className: '',
  hoverColor: '#8F3087'
}
export default IcFollowUp
