import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import colors from '../../infrastructure/styles/colors'
import Icons from '../Icons'
import { format, isToday } from 'date-fns'

/**
 * @author mromero
 * @param {Object} notification
 * @returns {React.Component}
 */
const Notification = ({ notification, index, changeNotification, deleteNotification }) => {
  const [showBody, setShowBody] = useState(false)
  //   const [notification, setNotification] = useState(noti)
  const [showActions, setShowActions] = useState(false)
  /**
   * abre y cierra el cuerpo de la notificació
   */
  const handleOpenNotification = () => {
    setShowBody(!showBody)
    if (notification.isNew) setTimeout(() => changeNotification(notification.id), 500)
  }

  /**
   * Cierra el menú de opciones si se pierde el foco del ratón
   * @param {ref} ref
   */
  function useOutsideAlerter (ref) {
    useEffect(() => {
      function handleClickOutside (event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowActions(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
        <div className={`notification tw-flex tw-flex-col tw-w-full tw-p-[18px] ${index % 2 !== 0 ? ' tw-bg-secondary-20' : ' tw-bg-white'}`}>
            <div className='tw-flex tw-flex-row tw-h-8' >
                <div className=' tw-w-1/12 tw-cursor-pointer tw-select-none tw-self-center' onClick={handleOpenNotification}><Icons.IcArrowExpand color direction={{}} expand={ showBody ? 'less' : 'more'} size={20} /></div>
                <div className=' tw-flex tw-flex-col tw-w-9/12 tw-self-center tw-cursor-pointer' onClick={handleOpenNotification}>
                    <Paragraphs color={colors.primary} weight='bold' size='xxs'>
                        { isToday(notification.date)
                          ? format(notification.date, '\'Hoy a las \'HH:mm')
                          : format(notification.date, 'dd/MM/yyyy - HH:mm')
                        }
                    </Paragraphs>
                    <div >
                        <Paragraphs className=' tw-truncate' size='sm' weight={notification.isNew ? 'bold' : 'regular'}>{notification.title}</Paragraphs>
                    </div>
                </div>
                <div className={'tw-w-1/12 tw-self-center tw-text-center tw-py-1'}>
                    {notification.isNew && <Paragraphs className={`tw-w-16 tw-py-1 ${notification.isNew && ' tw-bg-success'}`} uppercase size='xxs' weight='bold' color='white'>no leída</Paragraphs>}
                </div>
                <div className='tw-flex tw-cursor-pointer tw-select-none tw-w-1/12 tw-self-center tw-justify-end tw-relative' onClick={() => setShowActions(!showActions)}>
                    <Icons.IcMoreVert color={colors.primary} size={20}/>
                    {showActions &&
                        <div ref={wrapperRef} className=' tw-absolute tw-right-0 tw--top-[10px] tw-flex tw-flex-col tw-w-40 tw-shadow-xl tw-bg-white tw-z-10'>
                            <div onClick={() => {
                              setShowActions(false)
                              setShowBody(false)
                              changeNotification(notification.id)
                            }}>
                                <Paragraphs className='tw-px-5 tw-py-5 tw-border-b hover:tw-underline' size='xs' >
                                    {notification.isNew ? 'Marcar como leído' : 'Marcar como no leído'}
                                </Paragraphs>
                            </div>
                            <div onClick={() => deleteNotification(notification.id)}>
                                <Paragraphs className='tw-px-5 tw-py-5 hover:tw-underline' size='xs'>Eliminar</Paragraphs>
                            </div>
                        </div>
                    }
                </div>

            </div>
            {showBody &&
            <div className=' tw-flex tw-flex-row tw-pt-5 '>
                <div className=' tw-w-1/12' />
                <div className=' tw-w-9/12'> <Paragraphs>{notification.body}</Paragraphs> </div>
                <div className=' tw-w-2/12' />
            </div>
            }

        </div>
  )
}

Notification.propTypes = {
  notification: PropTypes.object,
  index: PropTypes.number,
  changeNotification: PropTypes.func,
  deleteNotification: PropTypes.func
}

Notification.defaultProps = {
  notification: {
    id: 1,
    isNew: true,
    type: 'comercial',
    date: new Date('Mon Jan 31 2022 11:50:05 GMT+0100 (Central European Standard Time)'),
    title: '',
    body: ''
  },
  index: 0
}

export default Notification
