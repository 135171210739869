import React from 'react'
import TopSearch from '../../../components/TopSearch'
import Page from '../../../components/Page'
import HeaderBarPage from '../../../components/HeaderBarPage'
import ShowSearchResults from '../components/ShowSearchResults'
import { menuLinks } from '../../Home/constants/constants'

const SearchPage = () => {
  return (
  <Page.Container name='home-screen' className=' tw-bg-white tw-flex tw-flex-col'>
    <Page.Header className='header tw-flex tw-flex-col tw-px-4 tw-justify-center'>
      <HeaderBarPage links={menuLinks} />
    </Page.Header>
    <Page.Body className='header tw-flex tw-flex-col tw-px-4'>
      <TopSearch />
      <ShowSearchResults />
    </Page.Body>
  </Page.Container>)
}

export default SearchPage
