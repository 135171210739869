import React from 'react'
import PropTypes from 'prop-types'
import { useNavigation } from '../../context/StackContext/StackContext'
import HomeScreen from '../../../modules/Home/page/HomeScreen/HomeScreen'
import PedidosScreen from '../../../modules/Orders/pages/PedidosScreen'
import EncargosScreen from '../../../modules/Encargos/pages/EncargosScreen'
import BasketScreen from '../../../modules/Basket/pages/BasketScreen'
import OrderTrakingScreen from '../../../modules/Orders/pages/OrderTrakingScreen'
import DetailScreen from '../../../modules/Products/pages/DetailScreen'
import SearchPage from '../../../modules/Search/page/SearchPage'
import NotificationsScreen from '../../../modules/Notifications/pages/NotificationsScreen'
import ProfileScreen from '../../../modules/Profile/pages/ProfileScreen'

/**
 * Componente for render the layout and tabs
 * @author  ehernandez
 * @returns {React.Component}
 */
const StackNavigator = () => {
  const { stack } = useNavigation()

  /**
   * Render components for tabs
   * @author ehernandez
   * @param {page} page page to render
   * @returns {React.Component}
   */
  const getPageComponent = (page) => {
    switch (page.path) {
      case 'home':
        return <HomeScreen route={page} />

      case 'pedidos':
        return <PedidosScreen key={page.key} route={page} />

      case 'encargos':
        return <EncargosScreen key={page.key} route={page} />

      case 'product-detail':
        return <DetailScreen key={page.key} route={page} />

      case 'basket':
        return <BasketScreen key={page.key} route={page} />

      case 'order-traking':
        return <OrderTrakingScreen key={page.key} />

      case 'profile':
        return <ProfileScreen key={page.key} />

      case 'search':
        return <SearchPage key={page.key} route={page}/>

      case 'notifications':
        return <NotificationsScreen key={page.key} route={page} />

      default:
        return null
    }
  }

  return <div className='stack-navigator'>
    {
      stack?.map(page => {
        return <div key={page.key} className={`${page.active ? 'tw-block' : 'tw-hidden'}`}>{getPageComponent(page)}</div>
      })
    }
  </div>
}

StackNavigator.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default StackNavigator
