import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../../infrastructure/styles/colors'

/**
 *
 * @param {Author} mromero
 * @param {Number} size
 * @param {String} color
 * @param {String} borderColor
 * @param {Function} onClick
 * @param {Boolean} selected
 * @returns
 */

const IcFavoriteSVG = ({ size, color, borderColor, onClick, selected }) => {
  const handleClick = () => {
    onClick()
  }
  return (
  <svg className={`ic-favorite-svg tw-cursor-pointer tw-select-none ${!selected && ' tw-opacity-20 hover:tw-opacity-100'} `} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18" onClick={handleClick}>
    <g id="ic-favorite-svg" >
      <rect id="Rectángulo_1289" data-name="Rectángulo 1289" width="18" height="18" fill="none"/>
      <path id="Trazado_1242" data-name="Trazado 1242" d="M9,15.444l-1.015-.9C4.38,11.382,2,9.293,2,6.73A3.755,3.755,0,0,1,5.85,3,4.248,4.248,0,0,1,9,4.417,4.248,4.248,0,0,1,12.15,3,3.755,3.755,0,0,1,16,6.73c0,2.563-2.38,4.652-5.985,7.826Z" transform="translate(0.2 -0.356)" fill={selected ? color : 'none'} stroke={borderColor} strokeLinecap="square" strokeLinejoin="round" strokeWidth="2"/>
    </g>
  </svg>
  )
}

IcFavoriteSVG.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool
}

IcFavoriteSVG.defaultProps = {
  size: 30,
  color: colors.alert,
  borderColor: colors.alert,
  onClick: () => { console.log('Test') },
  selected: false
}
export default IcFavoriteSVG
