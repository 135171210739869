import React from 'react'
import Paragraphs from '../../../../components/Paragraphs'

const FeatureBar = () => {
  return (
    <div className=" feature-bar  tw-bg-negroVf tw-flex tw-flex-wrap tw-place-content-between tw-text-white">
      <div className=" tw-flex tw-flex-row tw-items-center tw-px-4 tw-py-2">
        <span style={{ fontSize: 20 }} className="icon-ic_ventaja_transporte" />
        <Paragraphs color="white" weight="bold" size="xs" className="tw-pl-2">
          Lorem ipsum dolor sit amet
        </Paragraphs>
      </div>
      <div className=" tw-flex tw-flex-row tw-items-center tw-px-4 tw-py-2">
        <span style={{ fontSize: 20 }} className="icon-ic_ventaja_novedades" />
        <Paragraphs color="white" weight="bold" size="xs" className="tw-pl-2">
          Consectetur adispiscing elit
        </Paragraphs>
      </div>
      <div className=" tw-flex tw-flex-row tw-items-center tw-px-4 tw-py-2">
        <span style={{ fontSize: 20 }}className="icon-ic_ventaja_asistencia" />
        <Paragraphs color="white" weight="bold" size="xs" className="tw-pl-2">
          Sed do eiusod tempor incididunt
        </Paragraphs>
      </div>
      <div className=" tw-flex tw-flex-row tw-items-center tw-px-4 tw-py-2">
        <span style={{ fontSize: 20 }} className="icon-ic_ventaja_ofertas" />
        <Paragraphs color="white" weight="bold" size="xs" className="tw-pl-2">
          Ut labore et dolore magna atiqua
        </Paragraphs>
      </div>
      <div className=" tw-flex tw-flex-row tw-items-center tw-px-4 tw-py-2">
        <span style={{ fontSize: 20 }} className="icon-ic_ventaja_seguro" />
        <Paragraphs color="white" weight="bold" size="xs" className="tw-pl-2">
          Quis nostrud exercitation
        </Paragraphs>
      </div>

    </div>
  )
}

FeatureBar.propTypes = {}

export default FeatureBar
