import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import ProductCard from '../ProductCard'

const ProductsListBlock = ({ title, products }) => {
  console.log(products)
  return (
    <div className="tw-flex tw-flex-col">
      <Paragraphs size="xl" weight="bold" className='tw-py-2'>
        {title}
      </Paragraphs>
      <div className="tw-flex tw-flex-wrap">
        {products?.map(
          (product) =>

              <div
                key={product.cn}
                className="tw-w-full md:tw-w-4/12 lg:tw-w-2/12  tw-pr-2 tw-py-1"
              >
                <ProductCard
                  key={product.id_product}
                 {...product}
                />
              </div>

        )}
      </div>
    </div>
  )
}

ProductsListBlock.propTypes = {
  title: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object)
}

ProductsListBlock.defaultProps = {
  products: []
}

export default ProductsListBlock
