import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {Number} size
 * @param {String} color
 * @param {String} className
 * @param {String} hoverColor
 * @returns
 */

const IcSale = ({ size, color, className }) => (
  <svg id="ic_banner_destacado_ofertas" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18">
  <rect id="Rectángulo_3109" data-name="Rectángulo 3109" width={size} height={size} fill="none"/>
  <g id="Grupo_7304" data-name="Grupo 7304" transform="translate(22879 23505)">
    <g id="Trazado_2648" data-name="Trazado 2648" transform="translate(-22879.5 -23505.5)" fill="none">
      <path d="M16.557,9.185l-6.75-6.75A1.491,1.491,0,0,0,8.75,2H3.5A1.5,1.5,0,0,0,2,3.5V8.75a1.5,1.5,0,0,0,.442,1.065l6.75,6.75A1.491,1.491,0,0,0,10.25,17a1.466,1.466,0,0,0,1.057-.443l5.25-5.25A1.466,1.466,0,0,0,17,10.25,1.515,1.515,0,0,0,16.557,9.185Z" stroke="none"/>
      <path d="M 8.751172065734863 3.499992370605469 C 8.750918388366699 3.500021934509277 8.750533103942871 3.500020027160645 8.75 3.5 L 3.502574920654297 3.5 C 3.501347541809082 3.500858306884766 3.500171661376953 3.50161075592041 3.5 3.500617027282715 L 3.49882984161377 8.74921989440918 C 3.49884033203125 8.74923038482666 3.499939918518066 8.751119613647461 3.50316047668457 8.754340171813965 L 10.24625015258789 15.49742984771729 L 10.24683952331543 15.49683952331543 L 15.49731159210205 10.24636840820312 C 15.49712944030762 10.24606895446777 15.49696826934814 10.24582672119141 15.49683952331543 10.24565982818604 L 8.751172065734863 3.499992370605469 M 3.5 2 L 8.75 2 C 9.162500381469727 2 9.537500381469727 2.165000915527344 9.807499885559082 2.434999465942383 L 16.55749893188477 9.185000419616699 C 16.82749938964844 9.454999923706055 17 9.837499618530273 17 10.25 C 17 10.66250038146973 16.83499908447266 11.03750038146973 16.55749893188477 11.30749988555908 L 11.30749988555908 16.55749893188477 C 11.03750038146973 16.83499908447266 10.66250038146973 17 10.25 17 C 9.837499618530273 17 9.462499618530273 16.83499908447266 9.192500114440918 16.56500053405762 L 2.442499160766602 9.814999580383301 C 2.165000915527344 9.537500381469727 2 9.162500381469727 2 8.75 L 2 3.5 C 2 2.674999237060547 2.674999237060547 2 3.5 2 Z" stroke="none" fill={color}/>
    </g>
    <circle id="Elipse_430" data-name="Elipse 430" cx="1" cy="1" r="1" transform="translate(-22874.5 -23500.5)" fill={color}/>
  </g>
</svg>

)
IcSale.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  hoverColor: PropTypes.string
}

IcSale.defaultProps = {
  size: 30,
  color: '#000',
  className: '',
  hoverColor: '#8F3087'
}
export default IcSale
