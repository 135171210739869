const URL_BASE = 'https://areaprivada.fedefarma.com'

export const useAggridProvider = () => {
  /**
   * @author aesteban
   * @param {int} type Tipo de columna de bd
   * @returns {string}
   */
  const _getAgColumnType = (type, links) => {
    let typeColumn = ''
    /**
       * -99 BLOB
       * 1 CHARACTER
       * 2 NUMERIC
       * 3 DECIMAL
       * 4 INTEGER
       * 5 SMALLINT
       * 7 REAL
       * 8 DOUBLE PRECISION
       * 12 VARCHAR
       * 91 DATE
       * 92 TIME
       * 93 TIMESTAMP
       */
    // 3,4,12,91,93
    switch (type) {
      case 1:
      case 12:
        typeColumn = ''
        break
      case 2:
      case 3:
      case 4:
      case 5:
      case 7:
      case 8:
        typeColumn = 'numberColumn'
        break
      case 91:
      case 92:
      case 93:
        typeColumn = 'dateColumn'
        break
      default:
        typeColumn = ''
        break
    }

    if (links) {
    //   typeColumn = typeColumn + '_linkColumn'
    }
    return typeColumn
  }

  /**
   * @author aesteban
   * @returns {Object}
   */
  const _getCols = (data) => {
    const cols = []

    data?.metadata?.forEach(col => {
      const colStyle = col.columnStyle
      cols.push({
        field: col.columnName,
        colId: col.columnName,
        headerName: col.columnLabel,
        suppressMenu: true,
        hide: col.isHidden,
        colIndex: col.columnIndex,
        editable: col.isEditable,
        cellStyle: params => _getStyle(params.rowIndex, colStyle),
        cellRendererParams: {
          links: col.columnStyle.links,
          URL_BASE
        },
        type: _getAgColumnType(col.columnType, col?.columnStyle?.links),
        sortable: true,
        resizable: true,
        cellClass: col.columnStyle.links ? ['tw-text-blue-600', 'tw-underline', 'tw-cursor-pointer'] : null

      })
    })
    return cols
  }

  const _formatGridCols = (data, columns) => {
    const rows = []

    // Recorremos los pedidos que hay que pintar en la tabla
    data.rowset.forEach(row => {
      // Objeto con los datos de cada pedido
      const rowEl = {}

      // Recorremos la estructura de metadatos. Ahí sabremos en qué posición del array de datos está cada elemento.
      columns.forEach(col => {
        rowEl[col.field] = _setFormattedValue(data, row[(col.colIndex - 1)], col.field)
      })

      rows.push(rowEl)
    })

    return rows
  }

  /**
   * @author aesteban
   * @param {int} rowIndex Índice de la file
   * @param {Object} colStyle Objeto con la definición de los estiles
   * @returns
   */
  const _getStyle = (rowIndex, colStyle) => {
    const colors = colStyle.colors
    const colorDef = colors != null ? colors[rowIndex] : null
    const colOptions = colStyle.outputOptions

    return {
      textAlign: colOptions != null ? colOptions.align : null,
      icons: colOptions != null ? colOptions.icons : null,
      color: colorDef != null ? colorDef[0] : null,
      backgroundColor: colorDef != null ? colorDef[1] : null
    }
  }

  /**
   * @author aesteban
   * @param {Object} rawValue Valor que llega de bd
   * @param {string} colName Nombre de la columna a la que pertenece el valor
   * @returns {string}
   */
  const _setFormattedValue = (data, rawValue, colName) => {
    const colMeta = data.metadata.filter(row => row.columnName === colName)[0]
    const colStyle = colMeta.columnStyle
    const colOptions = colStyle.outputOptions

    // si es de tipo fecha, devolvemos directamente el valor de la fecha en formato 'local'
    if (colMeta.columnType === 91) {
      return new Date(rawValue).toLocaleDateString()
    }

    // Evaluamos las opciones de visualización
    if (colOptions != null) {
      const displayValues = colOptions.renderType ? colOptions.renderType.data : null

      if (displayValues != null) {
        const value = displayValues.filter(colValue => colValue.key === rawValue)[0]

        return value != null ? value.value : rawValue
      }
    }

    return rawValue
  }

  return {
    _getCols,
    _formatGridCols,
    _getStyle
  }
}
