import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonScreen from '../../../infrastructure/components/CommonScreen'
/**
 * @athor ehernandez
 * @returns {React.Component}
 */

const EncargosScreen = () => {
  const { t } = useTranslation()

  return (
        <CommonScreen
            name='encargos-screen'
            title={t('Tus Encargos')}
            path='/encargos'
            detailCellRenderer='detailOrderDetails'
            showDownload={true}
            paginationPageSize={5}
            customPagination={true}
        />
  )
}

export default EncargosScreen
