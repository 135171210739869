import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'
/**
 * @param {*} param0
 * @returns {React.Component}
 */
const IcMoreVert = ({ color, size }) => {
  return (
    <Icon color={color} size={size} className="ic-more_vert material-icons">
    more_vert
    </Icon>
  )
}

IcMoreVert.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
}

export default IcMoreVert
