import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../components/Paragraphs'
import colors from '../../infrastructure/styles/colors'
// import { useTranslation } from 'react-i18next'
import Icons from '../../components/Icons'

const CustomPagination = ({ pageSize, totalRows, goToFirstPage, goToLastPage, goToNextPage, goToPreviousPage }) => {
  const [numPages, setNumPages] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  // const { t } = useTranslation()

  const changeToFirstPage = () => {
    setCurrentPage(1)
    goToFirstPage()
  }

  const changeToPreviousPage = () => {
    if (currentPage > 1) {
      const current = currentPage - 1
      setCurrentPage(current)
      goToPreviousPage()
    }
  }

  const changeToNextPage = () => {
    if (currentPage < numPages) {
      const current = currentPage + 1
      setCurrentPage(current)
      goToNextPage()
    }
  }

  const changeToLastPage = () => {
    setCurrentPage(numPages)
    goToLastPage()
  }

  useEffect(() => {
    const nPages = Math.ceil(totalRows / pageSize)
    setNumPages(nPages)
  }, [])

  return (
    <div className=' tw-mt-4 tw-justify-end'>
      <div className=' tw-flex tw-mt-4'>
        <div onClick={() => changeToFirstPage() }><Icons.IcArrow size={22} color={colors.primary} direction='left' /></div>
        <div onClick={() => changeToPreviousPage() }><Icons.IcArrow size={22} color={colors.primary} direction='left' /></div>
        <Paragraphs size='xs' className='tw-mt-1 tw-mx-3' weight='bold'>Página {currentPage} de {numPages} </Paragraphs>
        <div onClick={() => changeToNextPage() }><Icons.IcArrow size={22} color={colors.primary} /></div>
        <div onClick={() => changeToLastPage() }><Icons.IcArrow size={22} color={colors.primary} /></div>
      </div>
    </div>
  )
}

CustomPagination.propTypes = {
  pageSize: PropTypes.number,
  totalRows: PropTypes.number,
  goToFirstPage: PropTypes.func,
  goToLastPage: PropTypes.func,
  goToNextPage: PropTypes.func,
  goToPreviousPage: PropTypes.func
}

export default CustomPagination
