import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Paragraphs from '../../../../components/Paragraphs'
import PropTypes from 'prop-types'
import ProductPromotionsModal from '../../../../components/ProductPromotionsModal/ProductPromotionsModal'

const ProductPromotions = ({
  nomalm,
  promotions,
  available
}) => {
  const { t } = useTranslation()
  const [showPromotions, setShowPromotions] = useState(false)
  const title = available ? t('Disponible') : t('No disponible')
  return (
    <div className="product-promotions tw-flex">
      <div className="tw-flex tw-flex-col tw-self-start tw-w-1/6">
        <Paragraphs className={`${available ? 'tw-text-success' : 'tw-text-alert'}`} size="xs" weight="bold">
          {title}
        </Paragraphs>
        <Paragraphs size="xs">{t('Almacén', { warehouse: nomalm })}</Paragraphs>
      </div>
      <div className="tw-flex tw-flex-col">
            {promotions?.length > 0
              ? (
              <ul className="tw-list-inside tw-list-disc tw-text-primary">
                {promotions.map((promotion, idx) => idx < 2 && (
                  <li key={idx}>
                    <Paragraphs
                      className=" tw-inline-block tw-text-black"
                      size="xs"
                    >
                      {promotion}
                    </Paragraphs>
                  </li>
                ))}
                {promotions.length > 2 && (
                  <li
                    className="tw-list-none"
                    onClick={() => setShowPromotions(!showPromotions)}
                  >
                    <Paragraphs
                      size="xs"
                      uppercase
                      className=" tw-cursor-pointer tw-inline-block"
                    >
                      ver todas las promociones
                    </Paragraphs>
                    {showPromotions && (
                      <ProductPromotionsModal
                        promotions={promotions}
                        closeModal={() => setShowPromotions(false)}
                        className=" tw-absolute tw-top-2"
                      />
                    )}
                  </li>
                )}
              </ul>
                )
              : (
              <>
                {/* <Paragraphs size="xs" weight="bold">
                 {t('¡No hay promociones disponibles en este momento!')}
                </Paragraphs>
                <Paragraphs
                  className=" tw-text-primary"
                  weight="bold"
                  size="xxs"
                  uppercase
                >
                  {t('Enviar alerta al añadir nuevas')}
                </Paragraphs> */}
              </>
                )}
          </div>
    </div>
  )
}

ProductPromotions.propTypes = {
  nomalm: PropTypes.string,
  available: PropTypes.bool,
  promotions: PropTypes.array
}

export default ProductPromotions
