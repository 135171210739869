module.exports = {
  alert: '#D8004D',
  primary: '#8F3087',
  primaryhover: '#7B2673',
  secondary: '#D2BEDD',
  'secondary-20': '#D2BEDD1A',
  tertiary: '#C8B800',
  success: '#71D875',
  warning: '#FFB500',
  negroVf: '#222221',
  lilaVF: '#8f3087',
  white: '#FFFFFF',
  green: '#3DBF42',
  info: '#00B0FF'
}
