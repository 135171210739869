import React from 'react'
import Page from '../../../components/Page'
import NotificacionsContainer from '../../../components/NotificationsContainer'
// import PropTypes from 'prop-types';

const NotificationsScreen = (props) => {
  return (
      <Page.Container>
          <Page.Body className='header tw-flex tw-flex-col tw-px-4 tw-pt-4'>
              <NotificacionsContainer />
          </Page.Body>
      </Page.Container>
  )
}

NotificationsScreen.propTypes = {}

export default NotificationsScreen
