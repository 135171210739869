import React, { useState } from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {Number} size
 * @param {String} color
 * @returns
 */

const IcYoutube = ({ size, color, bgColor, bgOpacity, colorOver, bgColorOver, bgOpacityOver }) => {
  const [mouseOver, setMouseOver] = useState(false)
  return (
    <svg id="Componente_143_1" data-name="Componente 143 – 1" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 26 26" onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}>
      <circle id="Elipse_530" data-name="Elipse 530" cx="13" cy="13" r="13" fill={mouseOver ? bgColorOver : bgColor} opacity={mouseOver ? bgOpacityOver : bgOpacity}/>

      <g id="Grupo_7381" data-name="Grupo 7381" transform="translate(-1140 -2458.115)">

      <path id="path7429" d="M15.67,1.878A2.081,2.081,0,0,0,14.26.367,43.741,43.741,0,0,0,8,0,45.471,45.471,0,0,0,1.74.353,2.125,2.125,0,0,0,.329,1.878,24.2,24.2,0,0,0,0,6a24.112,24.112,0,0,0,.329,4.122,2.081,2.081,0,0,0,1.41,1.511A43.835,43.835,0,0,0,8,12a45.471,45.471,0,0,0,6.26-.353,2.081,2.081,0,0,0,1.41-1.511A24.2,24.2,0,0,0,16,6.014a22.98,22.98,0,0,0-.329-4.136Z" transform="translate(1145 2465.115)" fill={mouseOver ? colorOver : color}/>
      <path id="polygon7431" d="M48.6,24.3v4.846l4.207-2.423Z" transform="translate(1102.781 2444.392)" fill={mouseOver ? bgColorOver : '#000'} opacity={mouseOver ? 1 : 0.6}/>
      </g>
    </svg>
  )
}
IcYoutube.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  bgOpacity: PropTypes.number,
  colorOver: PropTypes.string,
  bgColorOver: PropTypes.string,
  bgOpacityOver: PropTypes.number
}

IcYoutube.defaultProps = {
  size: 30,
  color: '#000',
  bgColor: '#000',
  bgOpacity: 0.2,
  colorOver: '#333333',
  bgColorOver: '#000',
  bgOpacityOver: 0.2
}
export default IcYoutube
