import React from 'react'
import FooterLinks from '../FooterLinks/FooterLinks'
// import NewsLetterForm from '../NewsLetterForm'
import columns from './constants/columns'

/**
    * @author   mromero
    * @return {React.Component}
    */
function FooterLinksContainer () {
  return (
      <div className='footer-links-container tw-bg-black tw-opacity-70  tw-pt-10 tw-pl-4'>
           <div className='tw-w-full tw-flex tw-flex-wrap tw-col-span-2'>
            {columns.map((column, i) =>
                <div className='tw-pb-8 tw-grow' key={i}>
                    <FooterLinks column={column} />
                </div>
            )}
            {/* <div className='tw-grow'>
                <NewsLetterForm/>
            </div> */}
        </div>
      </div>
  )
}

export default FooterLinksContainer
