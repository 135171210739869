import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {Number} size
 * @param {String} color
 * @param {String} className
 * @param {String} hoverColor
 * @returns
 */

const IcSafePayments = ({ size, color, className }) => (
  <svg id="ic_banner_destacado_pago_seguro" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18">
  <rect id="Rectángulo_3110" data-name="Rectángulo 3110" width={size} height={size} fill="none"/>
  <g id="gpp_good_black_24dp">
    <g id="Grupo_7306" data-name="Grupo 7306" transform="translate(3 1.5)">
      <path id="Trazado_2650" data-name="Trazado 2650" d="M10,2,4,4.25V8.818A8.458,8.458,0,0,0,10,17a8.458,8.458,0,0,0,6-8.182V4.25Zm4.5,6.818A7,7,0,0,1,10,15.44,7,7,0,0,1,5.5,8.818V5.233L10,3.642l4.5,1.59ZM7.615,8.443,6.55,9.5,9.2,12.155,13.45,7.91,12.392,6.852l-3.18,3.18Z" transform="translate(-4 -2)" fill={color}/>
    </g>
  </g>
</svg>
)
IcSafePayments.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  hoverColor: PropTypes.string
}

IcSafePayments.defaultProps = {
  size: 30,
  color: '#000',
  className: '',
  hoverColor: '#8F3087'
}
export default IcSafePayments
