import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcEmpty = ({ size, color }) => {
  return (
    <svg id="ic_empty_image" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 100 100">
        <rect id="Rectángulo_2231" data-name="Rectángulo 2231" width={size} height={size} fill="none" />
        <g id="Grupo_7018" data-name="Grupo 7018" transform="translate(-489.999 -343.984)">
            <path id="Unión_95" data-name="Unión 95" d="M15326,17065v-8h16v-16h8v20a4,4,0,0,1-4,4Zm-54,0a4,4,0,0,1-4-4v-20h8v16h16v8Zm13-16.018a1,1,0,0,1-1-1v-38a1,1,0,0,1,1-1h48a1,1,0,0,1,1,1v38a1,1,0,0,1-1,1Zm7-8h34v-24h-34Zm21-14.5a3.5,3.5,0,1,1,3.5,3.5A3.5,3.5,0,0,1,15313,17026.484Zm29-9.5v-16h-16v-8h20a4,4,0,0,1,4,4v20Zm-74,0v-20a4,4,0,0,1,4-4h20v8h-16v16Z" transform="translate(-14768.999 -16634.998)" fill="#d8e6e7" />
        </g>
    </svg>
  )
}

IcEmpty.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
}
IcEmpty.defaultProps = {
  color: '#fff'
}

export default IcEmpty
