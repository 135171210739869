import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'
/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcArrowExpand = ({ size, expand, color }) => {
  return (
    <Icon
      className={`IcFavorite material-icons tw-items-center tw-justify-center ${color ? 'tw-text-primary' : 'tw-text-gray-400'}`}
      size={size}
    >
      {`expand_${expand}`}
    </Icon>
  )
}

IcArrowExpand.propTypes = {
  size: PropTypes.number.isRequired,
  expand: PropTypes.oneOf(['more', 'less']),
  color: PropTypes.bool
}

IcArrowExpand.defaultProps = {
  direction: 'more'
}

export default IcArrowExpand
