import React from 'react'
import PropTypes from 'prop-types'
/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcAddImage = ({ size }) => (
  <svg className="tw-fill-current tw-text-primary" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20.1 20">
    <g id="ic_cabecera_añadir_imagen" transform="translate(0.1)">
      <path id="Trazado_2633" data-name="Trazado 2633" d="M0,0H20V20H0Z" fill="none" />
      <path id="Trazado_2634" data-name="Trazado 2634" d="M17.348,5.048H14.729L13.217,3.429H9.261V5.048h3.23L14,6.667h3.346v9.714H4.13V10.1H2.478v6.286A1.641,1.641,0,0,0,4.13,18H17.348A1.641,1.641,0,0,0,19,16.381V6.667A1.641,1.641,0,0,0,17.348,5.048ZM6.609,11.524a4.131,4.131,0,1,0,4.13-4.048A4.091,4.091,0,0,0,6.609,11.524ZM10.739,9.1a2.429,2.429,0,1,1-2.478,2.429A2.461,2.461,0,0,1,10.739,9.1ZM4.13,5.048H6.609V3.429H4.13V1H2.478V3.429H0V5.048H2.478V7.476H4.13Z" strokeWidth="0.2" />
    </g>
  </svg>
)

IcAddImage.propTypes = {
  size: PropTypes.number.isRequired
}
export default IcAddImage
