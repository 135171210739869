import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'

/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcMinimize = ({ minimized, size, color }) => {
  return (
    <Icon
      color={color}
      size={size}
      className="IcMinimize material-icons tw-block tw-flex tw-items-center tw-justify-center"
    >
      {minimized ? 'close_fullscreen' : 'fullscreen'}
    </Icon>
  )
}

IcMinimize.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  minimized: PropTypes.bool
}
IcMinimize.defaultProps = {
  minimized: false
}

export default IcMinimize
