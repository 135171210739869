import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../../../components/Paragraphs'

const ProductTag = ({ mode, label }) => {
  return <div className={`product-tag tw-bg-${mode} tw-px-4 tw-py-1`}>
      <Paragraphs size='xxs' uppercase className='tw-text-white'>{label}</Paragraphs>
  </div>
}

ProductTag.propTypes = {
  mode: PropTypes.oneOf(['success', 'alert', 'warning']),
  label: PropTypes.string
}

export default ProductTag
