import React, { useState } from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {Number} size
 * @param {String} color
 * @returns
 */

const IcInstagram = ({ size, color, bgColor, bgOpacity, colorOver, bgColorOver, bgOpacityOver }) => {
  const [mouseOver, setMouseOver] = useState(false)
  return (
    <svg id="Componente_141_1" data-name="Componente 141 – 1" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 26 26" onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}>
      <circle id="Elipse_528" data-name="Elipse 528" cx="13" cy="13" r="13" fill={mouseOver ? bgColorOver : bgColor} opacity={mouseOver ? bgOpacityOver : bgOpacity}/>
      <g id="Instagram-Glyph-Black-Logo.wine" transform="translate(5 5)">
        <path id="Trazado_2724" data-name="Trazado 2724" d="M8.015,0c-2.177,0-2.45.009-3.3.048A5.883,5.883,0,0,0,2.765.421a3.929,3.929,0,0,0-1.42.924,3.928,3.928,0,0,0-.924,1.42A5.883,5.883,0,0,0,.048,4.71C.009,5.565,0,5.838,0,8.015s.009,2.45.048,3.3a5.883,5.883,0,0,0,.373,1.945,3.929,3.929,0,0,0,.924,1.42,3.929,3.929,0,0,0,1.42.924,5.884,5.884,0,0,0,1.945.373c.855.039,1.128.048,3.3.048s2.45-.009,3.3-.048a5.883,5.883,0,0,0,1.945-.373,4.1,4.1,0,0,0,2.344-2.344,5.883,5.883,0,0,0,.373-1.945c.039-.855.048-1.128.048-3.3s-.009-2.45-.048-3.3a5.883,5.883,0,0,0-.373-1.945,3.929,3.929,0,0,0-.924-1.42,3.929,3.929,0,0,0-1.42-.924A5.883,5.883,0,0,0,11.319.048C10.464.009,10.191,0,8.015,0Zm0,1.444c2.14,0,2.393.008,3.239.047a4.434,4.434,0,0,1,1.488.276,2.483,2.483,0,0,1,.922.6,2.483,2.483,0,0,1,.6.922,4.434,4.434,0,0,1,.276,1.488c.039.845.047,1.1.047,3.239s-.008,2.393-.047,3.239a4.434,4.434,0,0,1-.276,1.488,2.654,2.654,0,0,1-1.521,1.521,4.434,4.434,0,0,1-1.488.276c-.845.039-1.1.047-3.239.047s-2.394-.008-3.239-.047a4.433,4.433,0,0,1-1.488-.276,2.483,2.483,0,0,1-.922-.6,2.483,2.483,0,0,1-.6-.922,4.435,4.435,0,0,1-.276-1.488c-.039-.845-.047-1.1-.047-3.239s.008-2.393.047-3.239a4.434,4.434,0,0,1,.276-1.488,2.483,2.483,0,0,1,.6-.922,2.483,2.483,0,0,1,.922-.6,4.434,4.434,0,0,1,1.488-.276c.845-.039,1.1-.047,3.239-.047" fill={mouseOver ? colorOver : color}/>
        <path id="Trazado_2725" data-name="Trazado 2725" d="M167.524,124.2a2.672,2.672,0,1,1,2.672-2.672A2.672,2.672,0,0,1,167.524,124.2Zm0-6.787a4.116,4.116,0,1,0,4.116,4.116,4.116,4.116,0,0,0-4.116-4.116m5.24-.163a.962.962,0,1,1-.962-.962.962.962,0,0,1,.962.962" transform="translate(-159.509 -113.51)" fill={mouseOver ? colorOver : color}/>
      </g>
    </svg>
  )
}

IcInstagram.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  bgOpacity: PropTypes.number,
  colorOver: PropTypes.string,
  bgColorOver: PropTypes.string,
  bgOpacityOver: PropTypes.number
}

IcInstagram.defaultProps = {
  size: 30,
  color: '#000',
  bgColor: '#000',
  bgOpacity: 0.2,
  colorOver: '#000',
  bgColorOver: '#000',
  bgOpacityOver: 0.2
}
export default IcInstagram
