import React from 'react'
import Button from '../../../../components/Button/Button'
import Paragraphs from '../../../../components/Paragraphs'
import PropTypes from 'prop-types'
/**
 * @author ehernandez
 * @returns {FormLogin}
 */
const FormLogin = ({ handleSubmit, children, onSubmit, title, btnLabel }) => {
  return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="tw-bg-white tw-max-w-[350px] tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-between"
      >
        <div className="tw-w-full">
          <Paragraphs weight="bold" size="xl" className="tw-mb-4">
            {title}
          </Paragraphs>
          <div className='tw-w-full'>
            {children}
          </div>
        </div>
        <Button label={btnLabel}uppercase type="submit" />
      </form>
  )
}

FormLogin.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired
}

export default FormLogin
