export const aggrid = {
  rowset: [
    [
      '2021-12-22',
      '',
      0,
      null,
      1,
      57300357,
      'PVV21-014900',
      'S',
      null,
      '01',
      'N',
      'N',
      'S',
      '',
      '',
      'gvenalbh_print_manual_fede',
      'PVV21-014900'
    ]
  ],
  metadata: [
    {
      columnName: 'fecha',
      columnType: 91,
      columnIndex: 1,
      precision: 10,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.sql.Date',
      columnLabel: 'Data',
      columnTypeName: 'date',
      columnDisplaySize: 10,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: {
          pattern: 'dd-MM-yyyy',
          zone: null,
          locale: 'ca-ES'
        },
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'pdf_link',
      columnType: 12,
      columnIndex: 2,
      precision: 3,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'PDF',
      columnTypeName: 'varchar',
      columnDisplaySize: 3,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'type',
      columnType: 4,
      columnIndex: 3,
      precision: 10,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.Integer',
      columnLabel: 'type',
      columnTypeName: 'int',
      columnDisplaySize: 10,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: true,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: true,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: {
          pattern: '#,##0.###',
          zone: null,
          locale: 'ca-ES'
        },
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'fechorped',
      columnType: 93,
      columnIndex: 4,
      precision: 25,
      scale: 5,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.sql.Timestamp',
      columnLabel: 'Data i Hora Comanda',
      columnTypeName: 'datetime year to fraction(5)',
      columnDisplaySize: 25,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: {
          pattern: 'dd-MM-yyyy HH:mm:ss',
          zone: null,
          locale: 'ca-ES'
        },
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'linreg',
      columnType: 3,
      columnIndex: 5,
      precision: 15,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.math.BigDecimal',
      columnLabel: 'Línies',
      columnTypeName: 'decimal',
      columnDisplaySize: 15,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: true,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: {
          pattern: '#,##0.###',
          zone: null,
          locale: 'ca-ES'
        },
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'cabid',
      columnType: 4,
      columnIndex: 6,
      precision: 10,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.Integer',
      columnLabel: 'Comanda',
      columnTypeName: 'serial',
      columnDisplaySize: 10,
      isAutoIncrement: true,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 0,
      isHidden: true,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: {
          pattern: '#,##0.###',
          zone: null,
          locale: 'ca-ES'
        },
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'docped',
      columnType: 1,
      columnIndex: 7,
      precision: 16,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Comanda',
      columnTypeName: 'char',
      columnDisplaySize: 16,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: {
          0: {
            href: "/service/rest/fedefarm/iofnet/v1/api/get_obj_pdf/gvenpedh_print?obj_cond=gvenpedh.docser%3D'PVV21-014900'",
            button: false,
            mode: 'MODAL'
          }
        },
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'estcab',
      columnType: 1,
      columnIndex: 8,
      precision: 1,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Estat',
      columnTypeName: 'char',
      columnDisplaySize: 1,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: {
          renderType: {
            data: [
              {
                key: 'E',
                value: 'Bloquejat',
                icon: null
              },
              {
                key: 'V',
                value: 'Validat',
                icon: null
              },
              {
                key: 'X',
                value: 'Extret',
                icon: null
              },
              {
                key: 'P',
                value: 'Parcials',
                icon: null
              },
              {
                key: 'S',
                value: 'Servit',
                icon: null
              },
              {
                key: 'H',
                value: 'Històric',
                icon: null
              }
            ],
            multiple: false,
            stepper: false,
            widget: 'MAP_SELECT'
          },
          prefix: null,
          suffix: null,
          nullValue: null,
          tooltip: null,
          note: 0,
          align: 'AUTO',
          joins: null,
          grid: null,
          trend: null,
          icons: null
        },
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'nomerr',
      columnType: 12,
      columnIndex: 9,
      precision: 40,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Descripció',
      columnTypeName: 'varchar',
      columnDisplaySize: 40,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: true,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'refter',
      columnType: 12,
      columnIndex: 10,
      precision: 255,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Referència',
      columnTypeName: 'varchar',
      columnDisplaySize: 255,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'multizona',
      columnType: 1,
      columnIndex: 11,
      precision: 1,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Multimagatzem',
      columnTypeName: 'char',
      columnDisplaySize: 1,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: {
          renderType: {
            trueValue: 'S',
            falseValue: 'N',
            widget: 'BOOLEAN_CHECKBOX'
          },
          prefix: null,
          suffix: null,
          nullValue: null,
          tooltip: null,
          note: 0,
          align: 'AUTO',
          joins: null,
          grid: null,
          trend: null,
          icons: null
        },
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'encargo',
      columnType: 1,
      columnIndex: 12,
      precision: 1,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Enc.',
      columnTypeName: 'char',
      columnDisplaySize: 1,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: {
          renderType: {
            trueValue: 'S',
            falseValue: 'N',
            widget: 'BOOLEAN_CHECKBOX'
          },
          prefix: null,
          suffix: null,
          nullValue: null,
          tooltip: null,
          note: 0,
          align: 'AUTO',
          joins: null,
          grid: null,
          trend: null,
          icons: null
        },
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'estado',
      columnType: 1,
      columnIndex: 13,
      precision: 1,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Estat',
      columnTypeName: 'char',
      columnDisplaySize: 1,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: true,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: {
          renderType: {
            data: [
              {
                key: 'B',
                value: 'Esborrany',
                icon: null
              },
              {
                key: 'P',
                value: "Pendent d'aprovació",
                icon: null
              },
              {
                key: 'A',
                value: 'Aprovada per facturar',
                icon: null
              },
              {
                key: 'F',
                value: 'Facturada',
                icon: null
              }
            ],
            multiple: false,
            stepper: false,
            widget: 'MAP_SELECT'
          },
          prefix: null,
          suffix: null,
          nullValue: null,
          tooltip: null,
          note: 0,
          align: 'AUTO',
          joins: null,
          grid: null,
          trend: null,
          icons: null
        },
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'albaran',
      columnType: 12,
      columnIndex: 14,
      precision: 2,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Albarà',
      columnTypeName: 'varchar',
      columnDisplaySize: 2,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'zero',
      columnType: 12,
      columnIndex: 15,
      precision: 2,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Comanda zero',
      columnTypeName: 'varchar',
      columnDisplaySize: 2,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'print_obj',
      columnType: 1,
      columnIndex: 16,
      precision: 29,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'print_obj',
      columnTypeName: 'char',
      columnDisplaySize: 29,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: true,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'docpdf',
      columnType: 1,
      columnIndex: 17,
      precision: 16,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'docpdf',
      columnTypeName: 'char',
      columnDisplaySize: 16,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: true,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    }
  ],
  total: 1,
  hasMore: false,
  title: null,
  attributes: null,
  constraints: null,
  messages: [],
  type: 'jdbc'
}
