import React, { useState } from 'react'
import useUser from '../../infrastructure/hooks/useUser'
import Logo from '../Logo/Logo'
import Paragraphs from '../Paragraphs'
import { sidebarItems } from './constans'
import { useNavigation } from '../../infrastructure/context/StackContext/StackContext'

import {
  SidebarBtnMenu,
  SidebarContentHeader,
  SidebarInnerContainer,
  SidebarOutterContainer
} from './styles'

/**
 * sidebar for layout component
 * @author ehernandez
 * @returns {React.Component}
 */
const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [outerContainerContent, setOuterContainerContent] = useState({})
  const [currentItem, setCurrentItem] = useState(-1)
  const { user } = useUser()
  const navigation = useNavigation()

  const closeSidebar = () => {
    setCurrentItem(-1)
    setIsOpen(false)
  }

  const handleIconClick = (item) => {
    if (item.path) {
      closeSidebar()
      navigation.navigate(item)
      return
    }

    if (!item.innerLinks) {
      closeSidebar()
      return
    }

    if (currentItem === item.id) {
      setCurrentItem(-1)
      setIsOpen(false)
    } else {
      setCurrentItem(item.id)
      setOuterContainerContent({ name: item.name, innerLinks: item.innerLinks })
      setIsOpen(true)
    }
  }

  return (
    <div id="sidebar">
      <div className="sidebar-content tw-fixed tw-h-screen tw-flex">
        <SidebarInnerContainer className="sidebar-inner tw-bg-primary tw-h-full">
          <div style={{ height: 100 }}>
            {isOpen
              ? (
              <div
                onClick={closeSidebar}
                style={{ height: 50 }}
                className="tw-flex tw-items-center tw-cursor-pointer tw-justify-center tw-text-white"
              >
                <span
                  className="material-icons-outlined"
                  style={{ fontSize: 15 }}
                >
                  arrow_back_ios
                </span>
              </div>
                )
              : (
              <div
                onClick={closeSidebar}
                style={{ height: 50 }}
                className="tw-flex tw-items-center tw-cursor-pointer tw-justify-center tw-text-white"
              >
                <img
                  src={require('./assets/ic_menu_aplicaciones.svg').default}
                />
              </div>
                )}
          </div>
          <div className="sidebar-menu tw-flex tw-flex-col">
            {sidebarItems.map((item) => (
              <SidebarBtnMenu
                key={item.id}
                data-path={item.path}
                data-slug={item.slug}
                onClick={() => handleIconClick(item)}
                className={
                  'sidebar-menu-inner tw-cursor-pointer ' +
                  (currentItem === item.id
                    ? 'tw-bg-primaryhover'
                    : 'tw-bg-primary hover:tw-bg-primaryhover') +
                  ' tw-h-full tw-flex tw-flex-col tw-justify-center tw-text-white tw-items-center'
                }
              >
                {item.icon()}

                {item.showAlerts && (
                  <div className=" tw-h-1 tw-w-1 tw-bg-green-300 tw-rounded tw-mt-1" />
                )}
              </SidebarBtnMenu>
            ))}
          </div>
        </SidebarInnerContainer>
      </div>
      {isOpen && (
        <SidebarOutterContainer className="tw-fixed tw-top-0 tw-h-full tw-z-50">
          <div
            onClick={closeSidebar}
            className="tw-bg-black tw-bg-opacity-50 tw-w-screen tw-h-screen tw-absolute"
          />
          <div className="sidebar-outter-container-content tw-fixed tw-h-screen tw-flex tw-bg-secondary tw-flex-col">
            <SidebarContentHeader className="tw-bg-white tw-w-full tw-flex tw-flex-col tw-justify-center tw-px-4 tw-gap-2">
              <div className="tw-w-36">
                <Logo />
              </div>
              <div>
                <Paragraphs size='xs' weight="bold">
                  {user?.name}Farmacia garcia
                </Paragraphs>
                <Paragraphs size='xs' className="tw-text-gray-400">
                  {user?.name}Alejandro garcia
                </Paragraphs>
              </div>
            </SidebarContentHeader>
            <div className="sidebar-outter-container-content-inner tw-w-full tw-flex tw-flex-col tw-justify-center tw-p-4">
              <Paragraphs weight="bold" className="tw-text-primary" size="sm">
                {outerContainerContent.name}
              </Paragraphs>
            </div>
            <div className="sidebar-items-list">
              {outerContainerContent.innerLinks.map(
                (i) =>
                  !i.admin && (
                    <div
                      key={i.id}
                      data-path={i.path}
                      className="tw-py-4 tw-px-4 hover:tw-underline tw-cursor-pointer"
                      onClick={() => {
                        closeSidebar()
                        navigation.navigate(i)
                      }}
                    >
                      <Paragraphs
                        className="tw-text-black"
                        size="sm"
                        weight="bold"
                      >
                        {i.name}
                      </Paragraphs>
                    </div>
                  )
              )}
            </div>
          </div>
        </SidebarOutterContainer>
      )}
    </div>
  )
}

export default Sidebar
