import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'
/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcArrow = ({ size, direction, color }) => {
  return (
    <Icon
      className="IcFavorite material-icons tw-items-center tw-justify-center"
      size={size}
      color={color}
    >
      {`chevron_${direction}`}
    </Icon>
  )
}

IcArrow.propTypes = {
  size: PropTypes.number.isRequired,
  direction: PropTypes.oneOf(['right', 'left']),
  color: PropTypes.string
}

IcArrow.defaultProps = {
  direction: 'right'
}

export default IcArrow
