import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Paragraphs from '../Paragraphs'
import { FlashContent } from './styles'

const FlashMessage = () => {
  const { t } = useTranslation()
  const [event, setEvent] = useState(null)
  const [messages, setMessages] = useState([])
  const ref = useRef(null)
  useEffect(() => {
    ref.current.addEventListener('flashmessage', function (e) {
      setEvent(e.detail)
    }, false)
  }, [])

  useEffect(() => {
    if (event) { setMessages([...messages, event]) }
  }, [event])

  useEffect(() => {
    if (messages.length > 0) {
      setTimeout(() => {
        const res = messages.filter((i, index) => index !== 0 && index)
        setMessages(res)
      }, 5000)
    }
  }, [messages])
  console.log(messages)
  return <div ref={ref} id='flash-message'>
    <div className='tw-bg-alert tw-bg-success' />
    {
        messages?.length > 0 && messages.map((message, index) => <FlashContent items={index} key={index} className={`flash-message-content tw-bg-${message.type} tw-text-white tw-mt-3`}>
            <Paragraphs size='xxs'>{t(message.type)}</Paragraphs>
            <Paragraphs>{message.message}</Paragraphs>
        </FlashContent>
        )}

</div>
}

export default FlashMessage
