import React, { useState, useRef, useEffect } from 'react'
import SearchFilters from '../../../../components/SearchFilters/SearchFilters'
import Paragraphs from '../../../../components/Paragraphs'
import ProductCard from '../../../../components/ProductCard'
import ProductItemList from '../../../../components/ProductItemList/ProductItemList'
import { useSelector } from 'react-redux'
import { useSearch } from '../../../../infrastructure/hooks/useSearch/useSearch'
import { useNavigation } from '../../../../infrastructure/context/StackContext/StackContext'
import loadingImg from '../../../../infrastructure/assets/images/loading.gif'
// import { ContainerScroll } from '../../../../components/DropDown/styles'//

const ShowSearchResults = () => {
  const { searchTerm, selectedLab } = useSelector(state => state.searchReducer)
  const { searchResults, loading, filterQuery, loadMoreProducts, loadingMoreProducts, noMoreResults } = useSearch()
  const navigation = useNavigation()
  if (!searchTerm && !selectedLab.id) {
    navigation.navigate({
      id: 2,
      name: 'Home',
      path: 'home',
      openHome: true
    })
  }
  const [grid, setGrid] = useState(false)
  // const listInnerRef = useRef()
  const listBottomLimit = useRef()

  const [isBottomVisible, setIsBottomVisible] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIsBottomVisible(entry.isIntersecting)
  )

  useEffect(() => {
    observer.observe(listBottomLimit.current)
    return () => { observer.disconnect() }
  }, [listBottomLimit.current])

  useEffect(() => {
    // console.log({ isBottomVisible, listBottomLimit })
    if (isBottomVisible && !loadingMoreProducts) loadMoreProducts()
  }, [isBottomVisible])

  if (loading) return <div ref={listBottomLimit} className=' tw-p-2'><img src={loadingImg} height={'30'} width={'30'}/></div>
  if (searchResults?.products?.pagination?.total_items === 0 || !searchResults?.products?.items) {
    return <div ref={listBottomLimit} className=' tw-flex tw-flex-col tw-p-2 tw-gap-2'>
  <Paragraphs size='lg' weight={'bold'}>{`Sin resultados para "${searchTerm}"`}</Paragraphs>
  <Paragraphs size='base'>Revisa la ortografía o utiliza términos más generales</Paragraphs>
</div>
  }
  // console.log(searchResults)
  console.log({ noMoreResults })
  return (
      <div className='show-search-results tw-flex'>
        <SearchFilters categories={searchResults?.categories} manufacturers={searchResults?.manufacturers} filterQuery={filterQuery}/>
        <div className='tw-flex tw-flex-col tw-w-full'>
          <div className=' tw-flex tw-justify-between tw-pb-4'>
            <Paragraphs weight='bold' size='xl'>
              {`1-${searchResults?.products?.items?.length} de ${searchResults?.products?.pagination?.total_items} resultados`}
            </Paragraphs>
            <div className=' tw-flex tw-items-center'>
              <Paragraphs size='xs' weight='bold'>Ordenar por</Paragraphs>
              <span className={`icon-ic_vista_grid tw-cursor-pointer ${grid ? ' tw-text-black' : ' tw-text-secondary'} `} style={{ fontSize: 20 }} onClick={() => setGrid(true)}/>
              <span className={`icon-ic_vista_lista tw-cursor-pointer ${grid ? ' tw-text-secondary' : ' tw-text-black'} `} style={{ fontSize: 20 }} onClick={() => setGrid(false)}/>
            </div>
          </div>
          <div className={`tw-flex ${grid ? ' tw-flex-row tw-flex-wrap ' : ' tw-flex-col '} tw-justify-start tw-gap-4 tw-max-h-fit tw-overflow-auto`}>
            { searchResults?.products?.items?.map(product =>
              <div key={product.id} className={grid ? ' tw-w-1/6 ' : ' tw-w-full'}>
                {
                  grid
                    ? <ProductCard {...product}/>
                    : <ProductItemList {...product}/>
                }

              </div>)}
              {loadingMoreProducts && <img className='tw-bottom-0 tw-p-4 tw-self-center' src={loadingImg} height={'100'} width={'100'}/>}
              <div ref={listBottomLimit} className='bottom-limit'/>
              {noMoreResults &&
                <div className=' tw-border-t tw-py-4'><Paragraphs weight='bold' size='base'>No hay más resultados</Paragraphs></div>
              }
          </div>
        </div>
      </div>
  )
}

export default ShowSearchResults
