import styled from 'styled-components'
import colors from '../../../../../../../styles/colors'

export const CheckboxLabel = styled.label`
    font-family: arial;
    display: block;
    position: relative;
    padding-left: 20px;
    // padding-top: 1px;
    cursor: pointer;
    & input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    & .control_indicator {
        position: absolute;
        top: 0px;
        left: 0;
        height: 14px;
        width: 14px;
        background: #FFF;
        border: 2px solid ${colors.primary};
        border-radius: 0px;
    }
    &:hover input ~ .control_indicator,
    & input:focus ~ .control_indicator {
    background: #FFF;
    }

& input:checked ~ .control_indicator {
    background: ${colors.primary};
}
&:hover input:not([disabled]):checked ~ .control_indicator,
& input:checked:focus ~ .control_indicator {
    background: ${colors.primary};
}
& input:disabled ~ .control_indicator {
    background: #FFFFFF;
    opacity: 0.6;
    pointer-events: none;
}
& .control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
& input:checked ~ .control_indicator:after {
    display: block;
}
&.control-checkbox .control_indicator:after {
    left: 3px;
    top: ${({ globalCheck }) => globalCheck ? '1px' : '0px'};
    width: 2px;
    height: 6px;
    border: solid #ffffff;
    border-width: ${({ globalCheck }) => globalCheck ? ' 0 2px 0px 0' : '0 2px 2px 0'};
    transform: ${({ globalCheck }) => globalCheck ? ' rotate(90deg)' : 'rotate(45deg)'};;
}
&.control-checkbox input:disabled ~ .control_indicator:after {
    border: #FFFFFF;
}
&.control-checkbox .control_indicator::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.5rem;
    height: 4.5rem;
    margin-left: -1.3rem;
    margin-top: -1.3rem;
    background: #2aa1c0;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
}

`
