import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'
/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcAspectRatio = ({ size, color }) => {
  return (
    <Icon
      className="IcAspectRatio material-icons tw-items-center tw-justify-center"
      size={size}
      color={color}
    >
      aspect_ratio
    </Icon>
  )
}

IcAspectRatio.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
}

export default IcAspectRatio
