import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import useFilter from './hooks/useFilter'

const SearchFilters = ({ categories, manufacturers, filterQuery }) => {
  const Categories = useFilter(categories, { title: 'categorías', article: 'las', maxElementsToShow: 5, filterName: 'categories', filterQuery })
  const Manufacturers = useFilter(manufacturers, { title: 'laboratorios', article: 'los', maxElementsToShow: 4, filterName: 'manufacturers', filterQuery })

  useEffect(() => {
    Categories.setFilterElements(categories)
  }, [categories])

  useEffect(() => {
    Manufacturers.setFilterElements(manufacturers)
  }, [manufacturers])

  return (
    <div className='search-filters tw-flex tw-flex-col tw-w-[213px]'>
        <div>
            {categories && <Categories.DrawFilterElements />}
        </div>
        <div className=' tw-pt-6'>
            {manufacturers && <Manufacturers.DrawFilterElements />}
        </div>
    </div>
  )
}

SearchFilters.propTypes = {
  categories: PropTypes.array,
  manufacturers: PropTypes.array,
  filterQuery: PropTypes.func
}

export default SearchFilters
