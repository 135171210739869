import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonScreen from '../../../../infrastructure/components/CommonScreen'

/**
 * @athor ehernandez
 * @returns {React.Component}
 */

const PedidosScreen = () => {
  const { t } = useTranslation()

  return (
    <CommonScreen
      name='pedidos-screen'
      title={t('Tus Pedidos')}
      path='/comandes'
      detailCellRenderer='detailOrderDetails'
      showDownload={true}
      paginationPageSize={5}
      customPagination={true}
    />
  )
}

export default PedidosScreen
