import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'

/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcExpand = ({ size, expand }) => {
  return (
    <Icon
      className="IcExpand material-icons tw-items-center tw-justify-center tw-text-gray-700"
      size={size}
    >
      {`expand_${expand}`}
    </Icon>
  )
}

IcExpand.propTypes = {
  size: PropTypes.number.isRequired,
  expand: PropTypes.oneOf(['more', 'less'])
}

IcExpand.defaultProps = {
  expand: 'more'
}

export default IcExpand
