import React from 'react'
import Tab from './components/Tab'
import { NavTabContainer } from './styles'
import PropTypes from 'prop-types'

/**
 * @author ehernandez
 * @param {array} tabs array of tabs
 * @returns {React.Component}
 */
const NavTab = ({ tabs }) => {
  return (
        <NavTabContainer className='tabs-contianer tw-bg-black tw-flex tw-flex-row'>
          {
            tabs.map(tab => <Tab key={tab.key} uuid={tab.key} {...tab} />)
          }
        </NavTabContainer>
  )
}

NavTab.propTypes = {
  tabs: PropTypes.array
}

NavTab.defaultProps = {
  tabs: []
}

export default NavTab
