import IcAdd from './IcAdd'
import IcMinimize from './IcMinimize'
import IcSearch from './IcSearch'
import IcFilters from './IcFilters'
import IcCached from './IcCached'
import IcDelete from './IcDelete'
import IcEdit from './IcEdit'
import IcFavorite from './IcFavorite'
import IcAspectRatio from './IcAspectRatio'
import IcArrowExpand from './IcArrowExpand'
import IcArrow from './IcArrow'
import IcClose from './IcClose'
import IcDragHandle from './IcDragHandle'
import IcSensorWindow from './IcSensorWindow'
import IcViewColumn from './IcViewColumn'
import IcRotateRight from './IcRotateRight'
import IcRotateLeft from './IcRotateLeft'
import IcMenuDots from './IcMenuDots'
import IcEye from './IcEye'
import IcPdf from './IcPdf'
import IcTableIcon from './IcTableIcon'
import IcCloseBold from './IcCloseBold'
import IcSave from './IcSave'
import IcLogout from './IcLogout'
import IcBin from './IcBin'
import IcBell from './IcBell'
import IcTableFilter from './IcTableFilter'
import IcLocation from './IcLocation'
import IcCheckCircle from './IcCheckCircle/IcCheckCircle'
import IcCancel from './IcCancel/IcCancel'
import IcCheck from './IcCheck'
import IcExpand from './IcExpand/IcExpand'
import IcRemoveCircle from './IcRemoveCircle'
import IcEur from './IcEur'
import IcSettings from './IcSettings'
import IcEmpty from './IcEmpty'
import IcAnotaciones from './IcAnotaciones'
import IcAnotacionesClose from './IcAnotacionesClose/IcAnotacionesClose'
import IcAddImage from './IcAddImage/IcAddImage'
import IcAlertVF from './IcAlertVF'
import IcInvoiceVF from './IcInvoiceVF'
import IcTransport from './IcTransport'
import IcSafePayments from './IcSafePayments'
import IcSale from './IcSale'
import IcAssistance from './IcAssistance'
import IcNovelties from './IcNovelties'
import IcOrderVF from './IcOrderVF'
import IcHome from './IcHome'
import IcFollowUp from './IcFollowUp'
import IcSuggestion from './IcSuggestion'
import IcStickyNote from './IcStickyNote'
import IcDownload from './IcDownload'
import IcPlus from './IcPlus'
import IcRemove from './IcRemove'
import IcFavoriteSVG from './IcFavoriteSVG'
import IcModalFullscreen from './IcModalFullscreen'
import IcFacebook from './IcFacebook'
import IcYoutube from './IcYoutube'
import IcInstagram from './IcInstagram'
import IcDeleteOutline from './IcDeleteOutline'
import IcCart from './IcCart'
import IcMoreVert from './icMoreVert'

const Icons = {
  IcAdd,
  IcEdit,
  IcMinimize,
  IcSearch,
  IcFilters,
  IcCached,
  IcDelete,
  IcFavorite,
  IcClose,
  IcAspectRatio,
  IcDragHandle,
  IcViewColumn,
  IcSensorWindow,
  IcRotateRight,
  IcRotateLeft,
  IcMenuDots,
  IcArrowExpand,
  IcPdf,
  IcEye,
  IcTableIcon,
  IcCloseBold,
  IcSave,
  IcLogout,
  IcBin,
  IcTableFilter,
  IcBell,
  IcLocation,
  IcArrow,
  IcCheckCircle,
  IcCancel,
  IcCheck,
  IcExpand,
  IcRemoveCircle,
  IcEur,
  IcSettings,
  IcEmpty,
  IcAnotaciones,
  IcAnotacionesClose,
  IcAddImage,
  IcAlertVF,
  IcTransport,
  IcSafePayments,
  IcSale,
  IcAssistance,
  IcNovelties,
  IcInvoiceVF,
  IcOrderVF,
  IcHome,
  IcFollowUp,
  IcSuggestion,
  IcStickyNote,
  IcDownload,
  IcPlus,
  IcRemove,
  IcFavoriteSVG,
  IcModalFullscreen,
  IcFacebook,
  IcYoutube,
  IcInstagram,
  IcDeleteOutline,
  IcCart,
  IcMoreVert
}

export default Icons
