import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
/**
 * @author   ehernandez
 * @return {React.Component}
 */
const Checkbox = ({ register, error, id, name, order, children }) => {
  return (
    <div>
      <div className={'form-check tw-flex tw-items-start'}>
        <input
          className="form-check-input appearance-none tw-min-h-[15px] tw-min-w-[15px] border tw-border-secondary tw-border-2 bg-white checked:tw-bg-primary checked:tw-border-primary focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
          type="checkbox"
          {...register}
          id={id}
          name={name}
        />
        <label className="form-check-label tw-px-2" htmlFor={ id} style={{ order: order === 'reverse' && -1 }}>
          {children}
        </label>
      </div>
      <Paragraphs className='tw-text-alert' size='xxs'>{error}</Paragraphs>
    </div>
    // <div className='check-box tw-bg-black tw-text-white'>
    //   <CheckboxLabel className="control control-checkbox">
    //     <Paragraphs className='tw-text-white tw-pl-1 tw-break-word tw-text-opacity-80'>
    //       {checkboxText}
    //     </Paragraphs>
    //     {error && <Paragraphs size='xxs' className='tw-text-alert'>{error}</Paragraphs>}
    //     <input type="checkbox" {...register} />
    //     <div className="control_indicator"></div>
    //   </CheckboxLabel>
    // </div>
  )
}

Checkbox.propTypes = {
  register: PropTypes.object,
  error: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  order: PropTypes.oneOf('normal', 'reverse'),
  children: PropTypes.oneOfType([PropTypes.node])

}

Checkbox.defaultProps = {
  register: {},
  order: 'normal'
}
export default Checkbox
