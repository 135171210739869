import React from 'react'

const IcSearchOff = () =>
<svg id="ic_empty_results" xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68">
  <g id="Grupo_3723" data-name="Grupo 3723">
    <rect id="Rectángulo_2231" data-name="Rectángulo 2231" width="68" height="68" fill="none"/>
  </g>
  <g id="Grupo_3724" data-name="Grupo 3724" transform="translate(14.28 14.28)">
    <g id="Grupo_2271" data-name="Grupo 2271" transform="translate(0 0)">
      <path id="Sustracción_12" data-name="Sustracción 12" d="M17.567,35.134a17.524,17.524,0,0,1-5.059-.742l4.7-4.7c.117,0,.237.005.356.005A12.141,12.141,0,0,0,29.694,17.567c0-.118,0-.238-.005-.356l4.7-4.7A17.572,17.572,0,0,1,17.567,35.134ZM7.354,31.852h0a17.685,17.685,0,0,1-3.881-3.811l3.905-3.905a12.28,12.28,0,0,0,3.9,3.8L7.354,31.852ZM.85,22.969h0A17.572,17.572,0,0,1,22.97.85L18.354,5.465c-.259-.017-.524-.025-.787-.025A12.141,12.141,0,0,0,5.44,17.567c0,.261.009.526.025.787L.85,22.969ZM27.931,11.275h0a12.282,12.282,0,0,0-3.795-3.9l3.905-3.905a17.685,17.685,0,0,1,3.812,3.882l-3.921,3.921Z" fill="#d8e6e7"/>
      <rect id="Rectángulo_2811" data-name="Rectángulo 2811" width="5.44" height="43.52" rx="1" transform="translate(31.267 0.246) rotate(45)" fill="#d8e6e7"/>
      <rect id="Rectángulo_2909" data-name="Rectángulo 2909" width="5.44" height="15.64" rx="1" transform="translate(40.554 36.459) rotate(135)" fill="#d8e6e7"/>
    </g>
  </g>
</svg>

export default IcSearchOff
