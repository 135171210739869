import React, { useEffect, useState } from 'react'
import Page from '../../../components/Page'
import HeaderBarPage from '../../../components/HeaderBarPage'
import AggridProvider from '../../../components/AggridProvider/AggridProvider'
import GridFilterContainer from '../../../components/AggridProvider/GridFilterContainer'
import { comandas } from '../../../modules/Orders/constants/mocks/comandas'
import { comandasOpt } from '../../../modules/Orders/constants/mocks/options/comandasOpt'
import { envios } from '../../../modules/Encargos/constants/mocks/envios'
import { enviosOpt } from '../../../modules/Encargos/constants/mocks/options/enviosOpt'

import PropTypes from 'prop-types'

const CommonScreen = ({ name, path, title, detailCellRenderer, showDownload, paginationPageSize, customPagination }) => {
  const [, setGridApi] = useState(null)
  const [filters, setFilters] = useState(null)
  const [tableData, setTableData] = useState(null)

  const onChangeFilter = (filterValues, fn) => {
    fn()
    doRquest(filterValues)
  }

  const doRquest = (filterValues) => {
    console.log('Do request! ', path, filterValues)
  }

  useEffect(() => {
    switch (path) {
      case '/comandes':
        setFilters(comandasOpt)
        setTableData(comandas)
        break
      case '/encargos':
        setFilters(enviosOpt)
        setTableData(envios)
        break
    }
  }, [])

  /**
   * Cuando tengamos la información sobre los filtros,
   * hacemos una primera llamada a la base de datos siempre
   * y cuando estén todos los campos obligatorios con valor
   * por defecto
   */
  useEffect(() => {
    if (filters !== null) {
      const initValues = {}

      if (filters.POST != null) {
        console.log('Hay accion de insert')
      }

      if (filters.PUT != null) {
        console.log('Hay accion de update')
      }

      if (filters.DELETE != null) {
        console.log('Hay accion de delete')
      }
      let makeRequest = true
      filters?.GET.parameters.forEach(element => {
        const defaultValue = element.columnAttributes?.default
        initValues[element.columnName] = defaultValue

        if (element.isNullable === 0 && defaultValue === null) {
          makeRequest = false
        }
      })

      if (makeRequest) {
        doRquest(initValues)
      }
    }
  }, [filters])

  if (!filters || !tableData) {
    return null
  }

  return (
    <Page.Container
      name={name}
      className=" tw-bg-white tw-flex tw-flex-col"
    >
      <Page.Header className="header tw-flex tw-flex-col tw-p-4">
        <HeaderBarPage showCartButton title={title} />
      </Page.Header>
      <Page.Body className="header tw-flex tw-flex-col tw-px-4 tw-gap-8">
        <GridFilterContainer items={filters} onChangeFilter={onChangeFilter} />
        <AggridProvider
          data={tableData}
          onSetGridApi={(params) => setGridApi(params.api)}
          detailCellRenderer={detailCellRenderer}
          showDownload={showDownload}
          paginationPageSize={paginationPageSize}
          customPagination={customPagination}
        />
      </Page.Body>
    </Page.Container>
  )
}

CommonScreen.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.string,
  detailCellRenderer: PropTypes.string,
  showDownload: PropTypes.bool,
  paginationPageSize: PropTypes.number,
  customPagination: PropTypes.bool
}

export default CommonScreen
