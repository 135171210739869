import React from 'react'
import { useTranslation } from 'react-i18next'
import Logo from '../Logo/Logo'
import Paragraphs from '../Paragraphs'
import PropTypes from 'prop-types'

const Hero = ({ onCreatePage, gotToHome }) => {
  const { t } = useTranslation()
  return (
<>

  <div className="tw-h-14 tw-w-full hero tw-min-h-[56px] tw-bg-black tw-flex tw-justify-between tw-items-center tw-px-4">
    <Logo mode="white" />
    {
      gotToHome && <div onClick={gotToHome} className=" tw-text-white tw-flex tw-items-center">
      <Paragraphs size='xs' uppercase weight='bold'>
        {t('Volver al inicio')}
      </Paragraphs>
      <span className='icon-ic_common_close tw-text-white tw-text-4xl' />
    </div>
    }

  </div>
  {
    onCreatePage && <div onClick={onCreatePage} className='tw-bg-primary tw-px-4 tw-flex tw-items-center tw-justify-between'>
      <Paragraphs weight='bold' size='xs' className="tw-text-white">{t('Crea la cuenta de tu farmacia y encuentra el vademecum más amplio de medicamentos y parafarmacia.')}</Paragraphs>
      <div className='tw-flex tw-items-center'>
        <Paragraphs weight='bold' size='xs' uppercase className='tw-text-white'>{t('Crea tu cuenta')} </Paragraphs>
         <span className='icon-ic_common_close tw-text-white tw-text-4xl tw-ml-4' />
      </div>
    </div>
  }
  </>
  )
}

Hero.propTypes = {
  onCreatePage: PropTypes.func,
  gotToHome: PropTypes.func
}

export default Hero
