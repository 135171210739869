import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {String} placeholder
 * @returns {React.Component}
 */
const TextInputSearch = ({ placeholder, value, onChange, onBlur }) => {
  return (
    <div className='text-input-search  tw-w-full tw-pl-3'>
        <input
            type='text'
            value={value}
            onChange={onChange}
            className='tw-outline-none tw-w-full tw-text-lg'
            placeholder={placeholder}
            autoFocus
            onBlur={onBlur}
        />
    </div>
  )
}

TextInputSearch.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}

export default TextInputSearch
