export const envios = {
  rowset: [
    [
      272,
      '2020-10-27',
      'EN20-0000098',
      null,
      '2020-12-18',
      null,
      'B74492-51',
      'RESIDENCIA EL LLUC',
      'CAJ',
      1,
      0,
      'E',
      '',
      'PDF'
    ],
    [
      270,
      '2020-10-23',
      'EN20-0000096',
      null,
      '2020-12-18',
      null,
      'B64329-53',
      'RESIDENCIA TRES PINS',
      'CAJ',
      1,
      0,
      'E',
      '',
      'PDF'
    ]
  ],
  metadata: [
    {
      columnName: 'bulcid',
      columnType: 4,
      columnIndex: 1,
      precision: 10,
      scale: 0,
      tableName: 'ff_bultociegoh',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.Integer',
      columnLabel: 'Identificador',
      columnTypeName: 'serial',
      columnDisplaySize: 10,
      isAutoIncrement: true,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 0,
      isHidden: true,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: {
          pattern: '#,##0.###',
          zone: null,
          locale: 'ca-ES'
        },
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'fecsol',
      columnType: 91,
      columnIndex: 2,
      precision: 10,
      scale: 0,
      tableName: 'ff_bultociegoh',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.sql.Date',
      columnLabel: 'Data Sol·licitud',
      columnTypeName: 'date',
      columnDisplaySize: 10,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: {
          pattern: 'dd-MM-yyyy',
          zone: null,
          locale: 'ca-ES'
        },
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'docser',
      columnType: 12,
      columnIndex: 3,
      precision: 16,
      scale: 0,
      tableName: 'ff_bultociegoh',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Document',
      columnTypeName: 'varchar',
      columnDisplaySize: 16,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'docref',
      columnType: 12,
      columnIndex: 4,
      precision: 50,
      scale: 0,
      tableName: 'ff_bultociegoh',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Referència',
      columnTypeName: 'varchar',
      columnDisplaySize: 50,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'fecrec',
      columnType: 91,
      columnIndex: 5,
      precision: 10,
      scale: 0,
      tableName: 'ff_bultociegoh',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.sql.Date',
      columnLabel: 'Data recepció',
      columnTypeName: 'date',
      columnDisplaySize: 10,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: {
          pattern: 'dd-MM-yyyy',
          zone: null,
          locale: 'ca-ES'
        },
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'franja',
      columnType: 1,
      columnIndex: 6,
      precision: 6,
      scale: 0,
      tableName: 'ff_bultociegoh',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Franja',
      columnTypeName: 'char',
      columnDisplaySize: 6,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'terdes',
      columnType: 12,
      columnIndex: 7,
      precision: 16,
      scale: 0,
      tableName: 'ff_bultociegoh',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Tercer Destí',
      columnTypeName: 'varchar',
      columnDisplaySize: 16,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 0,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'nombre',
      columnType: -15,
      columnIndex: 8,
      precision: 60,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Nom',
      columnTypeName: 'nchar',
      columnDisplaySize: 60,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'tipbul',
      columnType: 12,
      columnIndex: 9,
      precision: 15,
      scale: 0,
      tableName: 'ff_bultociegoh',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Tipus Embalum',
      columnTypeName: 'varchar',
      columnDisplaySize: 15,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 0,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: {
          renderType: {
            data: [
              {
                key: 'CAJ',
                value: 'Caixa',
                icon: null
              },
              {
                key: 'SOB',
                value: 'Sobre',
                icon: null
              }
            ],
            multiple: false,
            stepper: false,
            widget: 'MAP_SELECT'
          },
          prefix: null,
          suffix: null,
          nullValue: null,
          tooltip: null,
          note: 0,
          align: 'AUTO',
          joins: null,
          grid: null,
          trend: null,
          icons: null
        },
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'numbul',
      columnType: 5,
      columnIndex: 10,
      precision: 5,
      scale: 0,
      tableName: 'ff_bultociegoh',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.Short',
      columnLabel: 'Nº Embalums',
      columnTypeName: 'smallint',
      columnDisplaySize: 5,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: true,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 0,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: {
          pattern: '#,##0.###',
          zone: null,
          locale: 'ca-ES'
        },
        trend: null,
        inputOptions: null,
        outputOptions: {
          renderType: {
            data: [
              {
                key: '1',
                value: '1',
                icon: null
              },
              {
                key: '2',
                value: '2',
                icon: null
              },
              {
                key: '3',
                value: '3',
                icon: null
              },
              {
                key: '4',
                value: '4',
                icon: null
              },
              {
                key: '5',
                value: '5',
                icon: null
              },
              {
                key: '6',
                value: '6',
                icon: null
              },
              {
                key: '7',
                value: '7',
                icon: null
              },
              {
                key: '8',
                value: '8',
                icon: null
              },
              {
                key: '9',
                value: '9',
                icon: null
              },
              {
                key: '10',
                value: '10',
                icon: null
              }
            ],
            multiple: false,
            stepper: false,
            widget: 'MAP_SELECT'
          },
          prefix: null,
          suffix: null,
          nullValue: null,
          tooltip: null,
          note: 0,
          align: 'AUTO',
          joins: null,
          grid: null,
          trend: null,
          icons: null
        },
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'nevera',
      columnType: 5,
      columnIndex: 11,
      precision: 5,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.Short',
      columnLabel: 'Nevera',
      columnTypeName: 'smallint',
      columnDisplaySize: 5,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: true,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 0,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: {
          pattern: '#,##0.###',
          zone: null,
          locale: 'ca-ES'
        },
        trend: null,
        inputOptions: null,
        outputOptions: {
          renderType: {
            data: [
              {
                key: '0',
                value: 'No',
                icon: null
              },
              {
                key: '1',
                value: 'Sí',
                icon: null
              }
            ],
            multiple: false,
            stepper: false,
            widget: 'MAP_SELECT'
          },
          prefix: null,
          suffix: null,
          nullValue: null,
          tooltip: null,
          note: 0,
          align: 'AUTO',
          joins: null,
          grid: null,
          trend: null,
          icons: null
        },
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'estado',
      columnType: 1,
      columnIndex: 12,
      precision: 1,
      scale: 0,
      tableName: 'ff_bultociegoh',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Estat',
      columnTypeName: 'char',
      columnDisplaySize: 1,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 0,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: {
          renderType: {
            data: [
              {
                key: 'P',
                value: 'Pendent',
                icon: null
              },
              {
                key: 'C',
                value: 'Confirmat',
                icon: null
              },
              {
                key: 'U',
                value: 'En curs',
                icon: null
              },
              {
                key: 'E',
                value: 'Entregat',
                icon: null
              }
            ],
            multiple: false,
            stepper: false,
            widget: 'MAP_SELECT'
          },
          prefix: null,
          suffix: null,
          nullValue: null,
          tooltip: null,
          note: 0,
          align: 'AUTO',
          joins: null,
          grid: null,
          trend: null,
          icons: null
        },
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'action',
      columnType: 12,
      columnIndex: 13,
      precision: 9,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'Acció',
      columnTypeName: 'varchar',
      columnDisplaySize: 9,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: null,
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    },
    {
      columnName: 'link_pdf',
      columnType: 12,
      columnIndex: 14,
      precision: 3,
      scale: 0,
      tableName: 'virtportal',
      schemaName: '',
      catalogName: '',
      columnClassName: 'java.lang.String',
      columnLabel: 'PDF',
      columnTypeName: 'varchar',
      columnDisplaySize: 3,
      isAutoIncrement: false,
      isCaseSensitive: false,
      isCurrency: false,
      isReadOnly: false,
      isSearchable: true,
      isSigned: false,
      isWritable: true,
      isDefinitelyWritable: true,
      isNullable: 1,
      isHidden: false,
      isGeometric: false,
      isEditable: false,
      constraints: null,
      columnActions: null,
      columnStyle: {
        header: null,
        align: 'AUTO',
        grid: null,
        joins: null,
        tooltip: null,
        note: 0,
        prefix: null,
        suffix: null,
        format: null,
        trend: null,
        inputOptions: null,
        outputOptions: null,
        links: {
          0: {
            href: "/service/rest/fedefarm/iofnet/v1/api/get_obj_pdf/ff_bultociegoh_pdf?obj_cond=ff_bultociegoh.bulcid%3D'272'",
            button: false,
            mode: 'MODAL'
          },
          1: {
            href: "/service/rest/fedefarm/iofnet/v1/api/get_obj_pdf/ff_bultociegoh_pdf?obj_cond=ff_bultociegoh.bulcid%3D'270'",
            button: false,
            mode: 'MODAL'
          }
        },
        fonts: null,
        colors: null,
        colspans: null,
        icons: null
      },
      columnAttributes: null
    }
  ],
  total: 2,
  hasMore: false,
  title: null,
  attributes: null,
  constraints: null,
  messages: [],
  type: 'jdbc'
}
