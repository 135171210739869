import React from 'react'
import PropTypes from 'prop-types'

/**
 * @author ehernandez
 * @return {React.Component}
/**
 * @auhtor ehernandez
 * @returns {React.Component}
 */
const Header = ({ className, children }) => {
  return (
        <div className={`${className} tw-h-[60px]`}>
            {children}
        </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Header
