import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import {
  ContainerItems,
  ContainerScroll,
  DropdownItem,
  InputContent
} from './styles'
import TextInput from '../TextInput/TextInput'
import IcArrowDown from '../Icons/IcArrowDown'
import useListenerClose from '../../infrastructure/hooks/useListenerClose'
import IcSearchOff from '../Icons/IcSearchOff'
import Label from '../Label'

const DropDown = ({
  data,
  addNew,
  onSearchInput,
  loadMore,
  textInput,
  label,
  onSelectItem,
  disabled,
  noBackGround
}) => {
  const [showHideItems, setShowHideItems] = useState(false)
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)

  const onChange = (e) => {
    onSearchInput(e.target.value)
    setText(e.target.value)
  }

  const onShowHideItems = () => setShowHideItems(!showHideItems)

  const [ref] = useListenerClose(() => setShowHideItems(false), true)

  /**
   * Custom scroll infinite
   * @author   ehernandez
   * @param    {Object} target
   * @param       {Object} target.offsetHeight
   * @param       {Object} target.scrollTop
   */
  const onScrollList = async ({ target }) => {
    const { offsetHeight, scrollTop } = target

    if (
      scrollTop + offsetHeight > target.children[0].offsetHeight - 30 &&
      !loading
    ) {
      setLoading(true)
      await loadMore(data?.paginatorInfo?.currentPage)
      setLoading(false)
    }
  }

  const onClickItem = (id, name, shortcut) => {
    onSelectItem({ id, name, shortcut })
    setShowHideItems(false)
  }

  return (
    <div className="DropDown">
      {label && (
        <div className="tw-pb-2">
          <Label label={label} weight />
        </div>
      )}
      <div className={'tw-relative'}>
        <div onClick={!disabled ? onShowHideItems : undefined}>
          <InputContent
            className={`tw-flex tw-justify-between tw-items-center tw-gap-x-4 ${
              noBackGround ? ' tw-border-0' : 'tw-border tw-border-gray-100 '
            } tw-pr-1 tw-pl-2 tw-pt-px ${
              disabled
                ? 'tw-bg-white tw-select-none'
                : noBackGround
                ? 'tw-bg-white tw-cursor-pointer'
                : 'tw-bg-gray-100 tw-cursor-pointer'
            }`}
          >
            <Paragraphs className="tw-overflow-hidden tw-whitespace-nowrap tw-w-full">
              {textInput}
            </Paragraphs>
            {!disabled && <IcArrowDown />}
          </InputContent>
        </div>
        {showHideItems && (
          <ContainerItems
            className="tw-border tw-border-gray-100 tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-z-50 tw-bg-white"
            ref={ref}
          >
            {onSearchInput && (
              <div className="tw-pt-4 tw-px-4">
                <TextInput
                  id="DropDown_onSearchInput"
                  name="list"
                  placeholder="Buscar ..."
                  type="searchLeft"
                  value={text}
                  onChange={(e) => onChange(e)}
                  colorIcSearch="#2C2C2C"
                  searchIconsize={25}
                />
              </div>
            )}
            {data && data.data.length !== 0 ? (
              <ContainerScroll
                className="tw-py-2 tw-px-4 tw-mr-2 tw-overflow-auto"
                onScroll={onScrollList}
              >
                <div className="tw-overflow-auto">
                  {data.data.map(({ id, name, shortcut }, i) => (
                    <DropdownItem
                      key={id}
                      className={`tw-flex tw-justify-between tw-py-2 ${
                        i !== 0 && 'tw-border-t tw-border-gray-100'
                      } tw-cursor-pointer`}
                      onClick={() => onClickItem(id, name, shortcut)}
                    >
                      <Paragraphs size="xs" className="DropDown-text">
                        {name}
                      </Paragraphs>
                      <Paragraphs
                        size="xxs"
                        weight="bold"
                        className="tw-text-secondary"
                      >
                        {shortcut}
                      </Paragraphs>
                    </DropdownItem>
                  ))}
                </div>
              </ContainerScroll>
            ) : (
              <ContainerScroll className=" tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-2 tw-px-4">
                <IcSearchOff />
                <Paragraphs size="xxs" weight="bold" className="tw-text-center">
                  No se han encontrado resultados
                </Paragraphs>
              </ContainerScroll>
            )}

            {addNew && (
              <div onClick={addNew}>
                <Paragraphs
                  size="xs"
                  weight="bold"
                  className="tw-text-secondary hover:tw-underline tw-pb-4 tw-px-4 tw-cursor-pointer"
                >
                  Añadir nuevo
                </Paragraphs>
              </div>
            )}
          </ContainerItems>
        )}
      </div>
    </div>
  )
}

DropDown.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
        shortcut: PropTypes.string
      })
    ),
    paginatorInfo: PropTypes.shape({
      currentPage: PropTypes.number,
      hasMorePages: PropTypes.bool
    })
  }).isRequired,
  addNew: PropTypes.func,
  onSearchInput: PropTypes.func,
  loadMore: PropTypes.func,
  textInput: PropTypes.string,
  label: PropTypes.string,
  onSelectItem: PropTypes.func,
  disabled: PropTypes.bool,
  noBackGround: PropTypes.bool
}

DropDown.defaultProps = {
  onSelectItem: () => null,
  noBackGround: false
}

export default DropDown
