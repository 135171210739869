import React, { useState, useEffect } from 'react'
import DropDown from '../../components/DropDown/DropDown'
import TextInput from '../../components/TextInput'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

/**
 * @author aesteban
 * @param {*} params Recibe el path para hacer la llamada tipo options y obtener así los datos de las columnas a filtrar (ahora /mocks/option/comandas)
 * @returns
 */
const GridFilterContainer = ({ items, onChangeFilter }) => {
  const [busqueda, setBusqueda] = useState(null) // Estructura con los valores del filtro
  const [filterValues, setFilterValues] = useState({}) // Estructura con los valores del filtro

  const { t } = useTranslation()
  /**
   *
   * @param {*} element
   * @param {*} name
   */
  const onSelectItem = (element, name) => {
    setFilterValues(e => ({ ...e, [name]: element.id }))
  }

  /**
   *
   * @param {*} elements
   * @returns
   */
  const getElements = (elements) => {
    const values = []
    elements.forEach((element) => {
      values.push({ id: element.key, name: element.value })
    })
    return { data: values }
  }

  /**
   *
   * @param {*} field
   * @param {*} value
   */
  const updateValue = (field, value) => {
    setFilterValues(e => ({ ...e, [field]: value }))
  }

  /**
   * @author aesteban
   * @returns
   */
  const renderInputs = (item) => {
    const renderOpts = item.columnStyle.outputOptions.renderType
    if (renderOpts !== null) {
      const attrType = renderOpts.widget
      const elems = renderOpts.data

      switch (attrType) {
        case 'MAP_SELECT':
          return (
            <div className=" tw-w-full md:tw-w-2/12 lg:tw-w-2/12 lg:tw-pl-2 tw-pt-2 lg:tw-pt-0">
              <DropDown
                label={t(item.columnLabel)}
                data={getElements(elems, item.columnName)}
                onSelectItem={(el) => onSelectItem(el, item.columnName)}
                textInput={elems.find(e => e.key === filterValues[item.columnName]?.toString())?.value}
              />
            </div>
          )
      }
    }
    let iType = 'text'
    switch (item.columnType) {
      case 2:
      case 3:
      case 4:
      case 5:
      case 7:
      case 8:
        iType = 'number'
        break
      case 91:
      case 92:
      case 93:
        iType = 'date'
        break
    }
    return (
      <div className="tw-w-full md:tw-w-2/12 lg:tw-w-2/12 lg:tw-pl-2 tw-pt-2 lg:tw-pt-0">
        <TextInput
          label={t(item.columnLabel)}
          type={iType}
          value={filterValues[item?.columnName]}
          onChange={
            (e) =>
              updateValue(
                item.columnName,
                e.target.value
              )
          }
        />
      </div>
    )
  }

  const setInitialFilterValues = () => {
    const values = {}

    items.GET.parameters.forEach(field => {
      const defaultValue = field.columnAttributes.default
      values[field.columnName] = defaultValue
    })

    setFilterValues(values)
  }

  useEffect(() => {
    setInitialFilterValues()
  }, [items])

  useEffect(() => {
    if (onChangeFilter) {
      onChangeFilter(filterValues, () => { console.log('--------') })
    }
  }, [filterValues])

  return (
    <div className=" tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-gap-8">
      <div className="tw-w-full md:tw-w-3/6 lg:tw-w-3/12 tw-pt-2 lg:tw-pt-0">
        <TextInput
          label={t('Búsqueda general')}
          type="text"
          value={busqueda}
          onChange={(e) => setBusqueda(e.target.value)}
        />
      </div>
      {items?.GET?.parameters &&
        items?.GET?.parameters.map((i) => renderInputs(i))}
    </div>
  )
}

GridFilterContainer.propTypes = {
  items: PropTypes.object,
  onChangeFilter: PropTypes.func
}

export default GridFilterContainer
