import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'
/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcAdd = ({ size, color }) => {
  return (
    <Icon
      className="IcAdd material-icons tw-block tw-flex tw-items-center tw-justify-center"
      size={size}
      color={color}
    >
      add
    </Icon>
  )
}

IcAdd.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string
}

export default IcAdd
