import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'

/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcSensorWindow = ({ size, color }) => {
  return (
    <Icon
      className="ic-sensor-window material-icons tw-block tw-flex tw-items-center tw-justify-center"
      size={size}
      color={color}
    >
      sensor_window
    </Icon>
  )
}

IcSensorWindow.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
}

export default IcSensorWindow
