import styled, { keyframes } from 'styled-components'

const fade = keyframes`
  from {
    opacity:0;
    left: -20px;
  }

  to {
    opacity: 1;
    left: 0;
  }
`
export const ContainerItemBox = styled.div`
animation: ${fade} .8s linear;
opacity: 0;
position: relative;
animation-delay: ${(props) => `${props.delay}s`};
animation-fill-mode: forwards;

`
