import React from 'react'
import Button from '../../../../components/Button'
import Carousell from '../../../../components/Carousell'
import CarousellItem from '../../../../components/Carousell/components/CarousellItem'
import Paragraphs from '../../../../components/Paragraphs'
import { slides } from '../../constants/constants'

const SliderHome = () => {
  return (
    <div className="SliderHome">
      <Carousell id="sliderHome" color='white' items={slides}>
        {slides.map((i, idx) => {
          return (
            <CarousellItem color='white' active={idx === 0} key={i.id}>
              <img src={i.uri} alt={i.alt} />
              <div className="carousell-item__caption tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0 tw-flex tw-flex-col tw-items-center tw-justify-center">
                  <div className='tw-max-w-[50%] tw-text-center'>
              <Paragraphs className=' tw-text-white' weight='bold' size='6xl'>{i.title}</Paragraphs>
              <Paragraphs className=' tw-text-white' size='xl'>{i.mainText}</Paragraphs>
              </div>
                <div className="tw-flex tw-row tw-justify-center tw-pt-6">
                  <Button
                    size="medium"
                    backgroundColor=""
                    label={i.buttonText}
                    onClick={() => {}}
                    textColor="white"
                    mode="transparent-white-border"
                    uppercase
                  />
                </div>
              </div>
            </CarousellItem>
          )
        })}
      </Carousell>
    </div>
  )
}

SliderHome.propTypes = {}

export default SliderHome
