import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'

/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcEur = ({ size }) => {
  return (
    <div className={`tw-h-${4} tw-w-${4} hover:tw-bg-success tw-rounded-full tw-items-center tw-justify-center tw-border tw-border-success tw-transition-colors tw-flex tw-group`}>
    <Icon
      className="IcEur material-icons-outlined tw-items-center tw-text-success tw-rounded-full tw-self-center group-hover:tw-text-white tw-transition-colors tw-mr-0.5"
      size={size}
    >
      euro_symbol
    </Icon>
    </div>
  )
}

IcEur.propTypes = {
  size: PropTypes.number.isRequired
}

export default IcEur
