export const listing = [
  {
    id: 1,
    name: 'Preparación',
    date: '2020-01-01 10:31:00',
    'data-testid': 'order-traking-listing-1',
    img: require('../../assets/ic_box.svg').default
  },
  {
    id: 2,
    name: 'En reparto',
    date: '2020-01-01 10:31:00',
    'data-testid': 'order-traking-listing-2',
    img: require('../../assets/ic_truck.svg').default
  },
  {
    id: 3,
    name: 'Entregado',
    date: '2020-01-01 10:31:00',
    'data-testid': 'order-traking-listing-3',
    img: require('../../assets/ic_check.svg').default
  }
]
