export const descriptions = [{
  key: 'Indicaciones',
  id: 'indications',
  active: true
},
{
  key: 'Posologia',
  id: 'posology'
},
{
  key: 'Precauciones',
  id: 'precautions'
},
{
  key: 'Composición',
  id: 'composition'
}]
