import React from 'react'
import PropTypes from 'prop-types'

/**
 * @author ehernandez
 * @param children content to render
 * @param btnLabel label btn
 * @param onClick click to btn
 * @returns {React.Component}
 */
const BannerPage = ({ children, className }) => {
  return (
    <div className={`banner-page tw-h-full tw-px-[5%] tw-py-[3%] ${className}`}>
      {children}
    </div>
  )
}

BannerPage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default BannerPage
