import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../../../../components/Button'
import SelectorAddItems from '../../../../components/SelectorAddItems'
import useCart from '../../../../infrastructure/hooks/useCart'

/**
 *
 * @author
 * @returns
 */
const ProductCartButton = ({ productId }) => {
  const [quantity, setQuantity] = useState(1)
  const { addProductToCart, loading } = useCart()

  const onChangeQuantity = (e) => {
    let value = quantity
    if (e === '+') {
      value += 1
    }
    if (e === '-') {
      value -= 1
    }
    if (value > 0) {
      setQuantity(value)
    }
  }

  const onAddToCart = async () => {
    await addProductToCart(quantity, productId)
  }
  return (
    <div className="product-cart-button tw-flex ">
      <SelectorAddItems
        quantity={quantity}
        onChange={onChangeQuantity}
      />
      <Button
        size="full"
        disabled={loading}
        onClick={() => onAddToCart(quantity)}
        iconLeft={<span style={{ fontSize: 20 }} className="icon-ic_cesta_aadir tw-pr-3" />}
        label="añadir al carrito"
        uppercase
      />
    </div>
  )
}

ProductCartButton.propTypes = {
  productId: PropTypes.string
}

export default ProductCartButton
