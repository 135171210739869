import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Paragraphs from '../../../../components/Paragraphs'
import TextInput from '../../../../components/TextInput'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'
import BannerPage from '../../components/BannerPage'
import FormLogin from '../../components/FormLogin/FormLogin'
import Hero from '../../../../components/Hero'
import LeftLogin from '../../components/LeftLogin'
import { INPUTS } from '../../constants/inputs'
import { useLogin } from '../../hooks/useLogin/useLogin'
/**
 * page component for the login screen
 * @author ehernandez
 * @returns {React.Component}
 */
const LoginScreen = () => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm()

  const { onLogin } = useLogin()
  const { showMessage } = useFlashMessage()

  const navigate = useNavigate()

  /**
   * form submit for login form
   * @author   ehernandez
   * @param    {Object} user element
   * @param    {String}   user.email user
   * @param    {String}   user.password password
   */
  const onSubmit = async (user) => {
    const { success } = await onLogin(user)
    if (success) {
      navigate('/')
    } else {
      showMessage('Credenciales no válidas', 'Error')
    }
  }

  return (
    <div className="login-screen tw-flex tw-flex-col tw-h-screen tw-w-screen">
      <Hero gotToHome={() => navigate('/')} />
      <div className="tw-flex-1 tw-flex ">
        <LeftLogin
          className="tw-w-6/12 tw-flex tw-justify-between tw-flex-col"
          navigateTo={() => navigate('/register')}
        />
        <BannerPage className="tw-w-6/12 tw-flex tw-justify-between tw-flex-col tw-items-center">
          <FormLogin
            btnLabel={t('Iniciar la sesión')}
            title={t('Accede con tu usuario')}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          >
            {INPUTS.login.map((i) => (
              <TextInput
                type={i.type}
                key={i.id}
                id={i.id}
                required
                label={i.label}
                register={register(i.name, {
                  required: {
                    value: true,
                    message: 'Campo requerido'
                  },
                  minLength: {
                    value: i.minLength?.value,
                    message: i?.minLength?.message
                  }
                })}
                description={i.description}
                error={errors[i.name]?.message}
              />
            ))}
            <Paragraphs className="tw-py-5 tw-text-primary" weight='bold'>{t('¿Has olvidado la contraseña?')}</Paragraphs>
          </FormLogin>
        </BannerPage>
      </div>
    </div>
  )
}

export default LoginScreen
