import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {Number} size
 * @param {String} color
 * @param {String} className
 * @param {String} hoverColor
 * @returns
 */

const IcNovelties = ({ size, color, className }) => (
  <svg id="ic_banner_destacado_novedades" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18">
    <g id="Grupo_7299" data-name="Grupo 7299" transform="translate(22886 23479)">
      <g id="ic_info_table_more" transform="translate(-22886 -23479)">
        <rect id="Rectángulo_2924" data-name="Rectángulo 2924" width={size} height={size} fill="none"/>
      </g>
      <g id="Polígono_1" data-name="Polígono 1" transform="translate(-22884 -23477)" fill="none">
        <path d="M7,0,9.45,4.193,14,5.348,10.964,9.093,11.326,14,7,12.122,2.674,14l.362-4.907L0,5.348,4.55,4.193Z" stroke="none"/>
        <path d="M 7 2.97343635559082 L 5.528070449829102 5.49275016784668 L 2.641838073730469 6.22488260269165 L 4.575510025024414 8.610790252685547 L 4.352275848388672 11.63597106933594 L 7 10.48643016815186 L 9.647724151611328 11.63597106933594 L 9.424489974975586 8.610790252685547 L 11.35816192626953 6.22488260269165 L 8.471929550170898 5.49275016784668 L 7 2.97343635559082 M 7 0 L 9.449999809265137 4.193340301513672 L 14 5.347519874572754 L 10.96417999267578 9.093339920043945 L 11.32624053955078 14 L 7 12.12170028686523 L 2.673760414123535 14 L 3.035820007324219 9.093339920043945 L 0 5.347519874572754 L 4.550000190734863 4.193340301513672 L 7 0 Z" stroke="none" fill={color}/>
      </g>
    </g>
  </svg>
)
IcNovelties.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  hoverColor: PropTypes.string
}

IcNovelties.defaultProps = {
  size: 30,
  color: '#000',
  className: '',
  hoverColor: '#8F3087'
}
export default IcNovelties
