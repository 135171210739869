import React from 'react'
import Paragraphs from '../Paragraphs'
import Button from '../Button'

import PropTypes from 'prop-types'
// import ItemInBasket from './components/ItemInBasket/ItemInBasket'
import BasketTotal from './components/BasketTotal/BasketTotal'
// import colors from '../../infrastructure/styles/colors'
// import Icons from '../Icons'
import { useNavigation } from '../../infrastructure/context/StackContext/StackContext'
import { useTranslation } from 'react-i18next'
import SlotBasket from './SlotBasket'
import useCart from '../../infrastructure/hooks/useCart'
import useListenerClose from '../../infrastructure/hooks/useListenerClose'
/**
 * @author mromero
 * @returns {React.Component}
 */

const OrderBasket = ({ bigBasket, closeBasket, items, onChangeQtyCart }) => {
  const navigation = useNavigation()
  const { t } = useTranslation()
  const [ref] = useListenerClose(closeBasket)

  const { addProductToCart } = useCart()

  const onChangeQty = async (qty, productId) => {
    const res = await addProductToCart(qty, productId)
    console.log(res)
  }

  return (
    <div className="order-basket tw-p-4 tw-bg-white" ref={ref}>
      <div className="order-basket__wrapper">
        <div className="order-basket__wrapper__header">
          <div className="order-basket__wrapper__header__title__close tw-flex tw-justify-between tw-mb-4">
            <div className="order-basket__wrapper__header__title">
              <Paragraphs
                uppercase
                weight="bold"
                size="xxs"
                className="tw-text-primary"
              >
                {t('Cesta')}
              </Paragraphs>
              <Paragraphs uppercase weight="bold" size="xs">
                {t('Utimos Artículos añadidos')}
              </Paragraphs>
            </div>
            <div
              className="order-basket__wrapper__header__close tw-pointer"
              onClick={closeBasket}
            >
              <span className="icon-ic_common_close" />
            </div>
          </div>
        </div>
        <div className="order-basket__wrapper__content">
          {items?.map((item) => {
            return (
              <div className="tw-mb-4" key={item.product_id}>
                <SlotBasket
                  onDelete={() => onChangeQty(0, item.product_id)}
                  onChageQty={(val) => onChangeQty(val, item.product_id)}
                  {...item}
                  mode="small"
                />
              </div>
            )
          })}
        </div>
        <hr />
        <div className="order-basket__wrapper__footer">
          <div className="order-basket__wrapper__footer__total tw-my-4">
            {!!items.length && <BasketTotal mode="small" />}
          </div>
          <div className="order-basket__wrapper__footer__button tw-gap-4 tw-flex">
            <Button
              label={t('Ver cesta')}
              uppercase
              disabled={!items.length}
              mode="transparent"
              onClick={() =>
                navigation.navigate({ path: 'basket', name: 'Cesta' })
              }
            />
            <Button
              label={t('Tramitar el pedido')}
              uppercase
              mode="primary"
              disabled={!items.length}
              onClick={() =>
                navigation.navigate({ path: 'home', name: 'home' })
              }
            />
          </div>
        </div>
      </div>
    </div>
  )

  // if (items?.length <= 0) {
  //   return (
  //     <div
  //       className="order-basket tw-flex tw-flex-col tw-w-full tw-bg-white"
  //       ref={wrapperRef}
  //     >
  //       <Paragraphs
  //         weight="bold"
  //         size={bigBasket ? 'lg' : 'base'}
  //         className=" tw-pb-4"
  //       >
  //         La cesta está vacía
  //       </Paragraphs>
  //       <Button
  //         label="seguir comprando"
  //         uppercase
  //         mode="transparent"
  //         onClick={() => navigation.navigate({ path: 'home', name: 'home' })}
  //       />
  //     </div>
  //   )
  // }

  // return (
  //   <div
  //     className={`order-basket tw-flex tw-flex-col tw-relative ${
  //       bigBasket
  //         : ' tw-bg-white tw-w-[411px] tw-shadow-2xl tw-p-5'
  //     }`}
  //     ref={wrapperRef}
  //   >
  //     {!bigBasket && (
  //       <div className="tw-flex tw-flex-row tw-justify-between">
  //         <Paragraphs
  //           uppercase
  //           color={colors.primary}
  //           weight="bold"
  //           size="xxs"
  //           className=" tw-pb-2"
  //         >
  //           cesta
  //         </Paragraphs>
  //         <div
  //           className=" tw-cursor-pointer tw-select-none"
  //           onClick={closeBasket}
  //         >
  //           <Icons.IcClose size={16} />
  //         </div>
  //       </div>
  //     )}
  //     <Paragraphs
  //       weight="bold"
  //       size={bigBasket ? 'lg' : 'base'}
  //       className=" tw-pb-4"
  //     >
  //       Se {items.length === 1 ? 'ha' : 'han'} añadido
  //       <Paragraphs
  //         className={`tw-inline-block ${bigBasket && ' tw-text-primary'}  `}
  //         weight="bold"
  //         size={bigBasket ? 'lg' : 'base'}
  //       >
  //         &nbsp;{items.length}{' '}
  //         {items.length === 1 ? 'artículo' : 'artículos'}
  //       </Paragraphs>
  //       {/* &nbsp;{bigBasket && 'en tu pedido'} */}
  //     </Paragraphs>
  //     {bigBasket && (
  //       <div className="tw-flex tw-flex-row">
  //         <div className=" tw-w-[11%]"></div>
  //         <Paragraphs
  //           className=" tw-w-[40%]"
  //           weight="bold"
  //           color="#9D9FA2"
  //           size="xxs"
  //         >
  //           Descripción
  //         </Paragraphs>
  //         <Paragraphs
  //           className=" tw-w-[11%] tw-text-right"
  //           weight="bold"
  //           color="#9D9FA2"
  //           size="xxs"
  //         >
  //           Precio unitario
  //         </Paragraphs>
  //         <Paragraphs
  //           className=" tw-w-[11%] tw-text-center"
  //           weight="bold"
  //           color="#9D9FA2"
  //           size="xxs"
  //         >
  //           Unidades
  //         </Paragraphs>
  //         <Paragraphs
  //           className=" tw-w-[7%] tw-text-right"
  //           weight="bold"
  //           color="#9D9FA2"
  //           size="xxs"
  //         >
  //           Dto.
  //         </Paragraphs>
  //         <Paragraphs
  //           className=" tw-w-[15%] tw-text-right"
  //           weight="bold"
  //           color="#9D9FA2"
  //           size="xxs"
  //         >
  //           Importe
  //         </Paragraphs>
  //         <div className=" tw-w-[5%]"></div>
  //       </div>
  //     )}
  //     <div className={`${!bigBasket && ' tw-w-full'}`}>
  //       {items.map((item, index) => (
  //         <ItemInBasket
  //           key={item.id}
  //           item={item}
  //           index={index}
  //           changeItemQuantity={handleChangeItemQuantity}
  //           removeItemFromBasket={handleRemoveItemFromBasket}
  //           bigBasket={bigBasket}
  //         />
  //       ))}
  //     </div>

  //     <div
  //       className={`sm:tw-self-end ${
  //         bigBasket ? ' tw-w-[340px]' : ' tw-w-full'
  //       }`}
  //     >
  //       <BasketTotal
  //         basket={items}
  //         iva={4}
  //         shippment={null}
  //         bigBasket={bigBasket}
  //       />
  //     </div>

  //     {bigBasket && (
  //       <div className="tw-self-center tw-pt-4 sm:tw-self-start sm:tw-absolute sm:tw-bottom-0">
  //         <Button
  //           label="seguir comprando"
  //           uppercase
  //           mode="transparent"
  //           onClick={() => navigation.navigate({ path: 'home', name: 'home' })}
  //         />
  //       </div>
  //     )}
  //   </div>
  // )
}

OrderBasket.propTypes = {
  bigBasket: PropTypes.bool,
  closeBasket: PropTypes.func,
  items: PropTypes.array,
  onChangeQtyCart: PropTypes.func
}

OrderBasket.defaultProps = {
  bigBasket: true,
  items: []
}
export default OrderBasket
