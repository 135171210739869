import { useDispatch, useSelector } from 'react-redux'
import { actionSetUserSuccess } from '../../redux/actions/user'
import { useMutation } from '../useMutation/useMutation'

/**
 * get info user
 * @author   ehernandez
 * @param    {Boolean}   updateStorage   update redux storage
 * @return  {Object}
*/
export const useUser = () => {
  const { token, user } = useSelector(state => state.userReducer)
  const [fetchInfoUser] = useMutation('user/info')
  const [fetchUpdateUser] = useMutation('sign-up-pharmacy')

  const dispatch = useDispatch()

  const getInfoUser = async () => {
    const { success, data } = await fetchInfoUser({
      method: 'get'

    })

    if (success) {
      dispatch(actionSetUserSuccess(data?.data?.item))
    }
  }

  const updateUser = async (user) => {
    const { data, success, error } = await fetchUpdateUser({
      data: user
    })

    return {
      success,
      data,
      error
    }
  }
  return {
    token,
    user,
    getInfoUser,
    updateUser
  }
}
