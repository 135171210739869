import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import FlashMessage from './components/FlashMessage'
import StackNavigator from './infrastructure/components/StackNavigator/StackNavigator'
// import AuthContext from './infrastructure/context/AuthContext/AuthContext'
import { StackProvider } from './infrastructure/context/StackContext/StackContext'
import { persistor, store } from './infrastructure/redux/store'
import CreateUserScreen from './modules/Auth/pages/CreateUserScreen'
import LoginScreen from './modules/Auth/pages/LoginScreen/LoginScreen'

/**
 *
 * @returns {React.Component}
 */
function App () {
  useEffect(() => {
    document.title = 'Vadefarma - Área privada'
  }, [])
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <FlashMessage />
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/register" element={<CreateUserScreen />} />
            <Route path='/' element={<StackProvider />}>
              <Route
              index
              element={
                  // <AuthContext>
                    <StackNavigator />
                  // </AuthContext>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default App
