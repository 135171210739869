import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button'
import BasketTotal from '../../../components/OrderBasket/components/BasketTotal/BasketTotal'
import SlotBasket from '../../../components/OrderBasket/SlotBasket'
import Page from '../../../components/Page'
import Paragraphs from '../../../components/Paragraphs'
import useCart from '../../../infrastructure/hooks/useCart'
const BasketScreen = (props) => {
  const { cart, addProductToCart } = useCart()
  const { t } = useTranslation()

  const onChageQty = (qty, productId) => {
    addProductToCart(qty, productId)
  }
  return (
      <div className='basket-screen tw-p-4 tw-bg-white'>
          <Page.Container className='tw-relative tw-flex tw-flex-col'>
                <Page.Header>
                  <Paragraphs size='xl' weight='bold' className='tw-flex'>
                    {t('Se han añadido')}<Paragraphs weight='bold' size='xl' className='tw-text-primary tw-ml-1'>{cart?.items?.length}{' '}{t('artículos')}</Paragraphs>
                  </Paragraphs>
                </Page.Header>
                <Page.Body className='tw-flex-1'>

                  {
                    cart?.items?.map((item, index) => (
                      <div className="tw-px-6 tw-py-4 " key={item.product_id}>
                          <SlotBasket onDelete={() => onChageQty(0, item.product_id)} {...item} onChageQty={(val) => onChageQty(val, item.product_id)} />
                      </div>
                    ))
                  }

                </Page.Body>
                <div className='basket-screen__footer'>
                  <div className=' tw-flex tw-justify-end'>
                    <div className='basket-screen__footer__total tw-w-[30%] tw-py-10 '>
                      <BasketTotal mode='large' />
                    </div>
                    </div>
                    <div className='basket-screen__footer__buttons tw-gap-4 tw-flex tw-justify-between'>
                      <Button label={t('Seguir comprando')} uppercase mode='transparent'/>
                      <Button label={t('Tramitar el pedido')} uppercase />
                    </div>
                </div>
            </Page.Container>
      </div>

  )
}

BasketScreen.propTypes = {}

export default BasketScreen
