import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {Number} size
 * @param {String} color
 * @param {String} className
 * @param {String} hoverColor
 * @returns
 */

const IcSuggestion = ({ size, color, className }) => (
  <svg id="ic_menu_vadefarma_sugerencias" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
  <path id="Sustracción_12" data-name="Sustracción 12" d="M0,15V1A1,1,0,0,1,1,0H14a1,1,0,0,1,1,1V3H13V2H2v8.17l.58-.58L3,9.17V12L0,15Z" transform="translate(2 2)" fill={color}/>
  <path id="Trazado_2654" data-name="Trazado 2654" d="M0,0H24V24H0Z" fill="none"/>
  <path id="Trazado_2656" data-name="Trazado 2656" d="M4,4v8h9.83l.59.59.58.58V4H4M3,2H16a1,1,0,0,1,1,1V18l-4-4H3a1,1,0,0,1-1-1V3A1,1,0,0,1,3,2Z" transform="translate(5 5)" fill={color}/>
  <path id="Trazado_2657" data-name="Trazado 2657" d="M-19903.016-23058.3h3.043" transform="translate(-19888.473 -23043.797) rotate(180)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <path id="Trazado_2658" data-name="Trazado 2658" d="M-19903.016-23058.3h4.375" transform="translate(-19887.141 -23047.094) rotate(180)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
</svg>

)
IcSuggestion.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  hoverColor: PropTypes.string
}

IcSuggestion.defaultProps = {
  size: 30,
  color: '#000',
  className: '',
  hoverColor: '#8F3087'
}
export default IcSuggestion
