import React from 'react'
import PropTypes from 'prop-types'
import { ModalSection } from './styles'

/**
 * @auhtor aesteban
 * @returns {React.Component}
 */
const ModalWindow = ({ show, handleClose, content }) => {
  if (!show) {
    return null
  }
  return (
    <div className={'modal-window tw-fixed tw-w-full tw-h-full tw-top-0 tw-left-0 tw-bg-black/[.6] tw-z-50 tw-flex tw-items-center tw-justify-center'}>
      <ModalSection>
        <div className='modal-header tw-text-right tw-h-[20px]'><button type="button" onClick={handleClose}>Close</button></div>
        { /* <embed src="https://areaprivada.fedefarma.com/proxy2/iofnet/v1/api/get_obj_pdf/gvenfach_print_iof?obj_cond=gvenfach.docser%3D%27SI21-13366%27&amp;db_code=fedefarm_his"/> */ }
        <embed className={'tw-w-full tw-h-full'} src={content}/>
      </ModalSection>
    </div>
  )
}

ModalWindow.propTypes = {
  show: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  content: PropTypes.string.isRequired

}

export default ModalWindow
