import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../../../components/Paragraphs'
import ProductPrice from '../ProductPrice'
import { useTranslation } from 'react-i18next'

const ProductNamePrice = ({ name, price, iva, pvp, cn }) => {
  const { t } = useTranslation()
  return <div className='tw-flex tw-flex-wrap tw-flex-row'>
      <div className='tw-flex-1'>
      <Paragraphs
          className=" tw-text-primary"
          size="xs"
          uppercase
          weight="bold"
        >
          {t('cn:', { cn: cn })}
        </Paragraphs>
  <Paragraphs weight='bold' size='xl' uppercase>{name}</Paragraphs>
  </div>
  <div className='tw-w-1/3 tw-text-right tw-flex tw-flex-col'>
      <ProductPrice
        price={price}
        iva={iva}
        pvp={pvp}
      />
  </div>
</div>
}

ProductNamePrice.propTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  iva: PropTypes.number,
  pvp: PropTypes.number,
  cn: PropTypes.string
}

export default ProductNamePrice
