import React from 'react'
import Logo from './assets/Logo.png'
// import PropTypes from 'prop-types'

/**
    * @author   mromero
    * @return {React.Component}
    */
const RatingsLogo = () => {
  return (
        <div className='ratings-logo'>
          <img src={Logo} width='60px' height='60px'/>
        </div>
  )
}

RatingsLogo.propTypes = {

}

RatingsLogo.defaultProps = {

}

export default RatingsLogo
