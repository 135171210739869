import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import { useBtn } from './hooks/useBtn'
import { Container } from './styles'

/**
 *  @author ehernandez
 * @param {propeties} propiedades to component
 * @returns {React.Component}
 */
const Button = ({
  size,
  label,
  shortcut,
  rounded,
  loading,
  type,
  uppercase,
  iconLeft,
  iconRight,
  onClick,
  disabled,
  backgroundColor,
  mode,
  styles,
  textColor,
  form,
  labelLeft,
  id,
  border
}) => {
  const { classFromMode } = useBtn(mode, rounded, uppercase, border)

  return (
    <Container
    id={id}
      form={form}
      backgroundColor={backgroundColor}
      textColor={textColor}
      size={size}
      style={{ ...styles }}
      className={`Button ${classFromMode(disabled)}`}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
    >

      <div
        className={`tw-flex tw-w-full tw-items-center ${shortcut || iconRight || iconLeft ? 'tw-justify-between' : 'tw-justify-center'
          }`}
      >
        {iconLeft && <div className="tw-flex">{iconLeft}</div>}
        <div>
          <Paragraphs className={`label ${labelLeft && 'tw-leading-3 tw-text-left'}`} weight="bold" size="xs">
            {label}
          </Paragraphs>
        </div>
        {
          shortcut && <div className="tw-ml-2 tw-opacity-70">
            <Paragraphs size="xs">
              {shortcut}
            </Paragraphs>
          </div>
        }

        {iconRight && <div className="tw-flex">{iconRight}</div>}
      </div>

    </Container>
  )
}
Button.propTypes = {
  form: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button']),
  size: PropTypes.oneOf(['mini', 'small', 'medium', 'full']),
  mode: PropTypes.oneOf(['primary', 'secondary', 'transparent', 'expansible', 'transparent-white-border']),
  label: PropTypes.string.isRequired,
  shortcut: PropTypes.string,
  icon: PropTypes.node,
  rounded: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  uppercase: PropTypes.bool,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  styles: PropTypes.object,
  labelLeft: PropTypes.bool,
  id: PropTypes.string,
  border: PropTypes.oneOf(['primary', 'secondary', 'none'])
}

Button.defaultProps = {
  mode: 'primary',
  rounded: false,
  disabled: false,
  size: 'medium',
  onClick: null,
  icon: null,
  uppercase: false,
  backgroundColor: null,
  textColor: null,
  loading: false,
  type: 'button',
  form: null,
  labelLeft: false,
  boder: 'none'
}

export default Button
