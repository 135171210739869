import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'
/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcAnotaciones = ({ color, size }) => {
  return (
        <Icon
            className="ic-IcAnotaciones material-icons-outlined tw-items-center tw-justify-center"
            size={size}
            color={color}
        >
            chat
        </Icon>
  )
}

IcAnotaciones.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
}

export default IcAnotaciones
