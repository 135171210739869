import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {Number} size
 * @param {String} color
 * @param {String} className
 * @param {String} hoverColor
 * @returns
 */

const IcHome = ({ size, color, className }) => (
  <svg id="ic_menu_vadefarma_inicio" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
  <path id="Trazado_2256" data-name="Trazado 2256" d="M0,0H24V24H0Z" fill="none"/>
    <g id="Grupo_6317" data-name="Grupo 6317" transform="translate(-142.5 -540.5)">
      <path id="Trazado_2309" data-name="Trazado 2309" d="M-20017-23016l8-5,8,5" transform="translate(20162 23564)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Trazado_2310" data-name="Trazado 2310" d="M-20015-23017v10h7" transform="translate(20162 23565)" fill="none" stroke={color} strokeLinejoin="round" strokeWidth="2"/>
      <circle id="Elipse_399" data-name="Elipse 399" cx="4.519" cy="4.519" r="4.519" transform="translate(153 550.963)" fill="none" stroke={color} strokeLinejoin="round" strokeWidth="2"/>
      <path id="Trazado_2311" data-name="Trazado 2311" d="M-20077.234-23009.234l2.873,2.873" transform="translate(20238.361 23568.324)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Trazado_2312" data-name="Trazado 2312" d="M-20004-23014v-4" transform="translate(20163 23565)" fill="none" stroke={color} strokeLinejoin="round" strokeWidth="2"/>
    </g>
  </svg>

)
IcHome.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  hoverColor: PropTypes.string
}

IcHome.defaultProps = {
  size: 30,
  color: '#000',
  className: '',
  hoverColor: '#8F3087'
}
export default IcHome
