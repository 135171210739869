import { useState } from 'react'
import { useMutation } from '../useMutation/useMutation'

/**
 *
 * @author ehernandez
 */
export const useProduct = () => {
  const [data, setData] = useState(null)
  const [getInfo] = useMutation('products')
  const [fetchImages] = useMutation('product-images/repofarma/')
  const [extra, setExtra] = useState(null)
  const [images, setImages] = useState([])

  /**
   *
   * @author ehernandez
   * @param {string} id id product
   * @param {boolean} force force to get extra data from api fede
   */
  const getProduct = async (id, force, widthImages) => {
    const res = await getInfo({
      method: 'GET',
      addToURL: `/${id}`
    })
    if (res?.success && res.data?.data) {
      setData({ ...res.data.data?.item })
      if (force) { getProductFede(res.data.data?.item.id_product) }
      if (widthImages) { getImages(res.data.data?.item.cn) }
      return {
        success: true,
        data: { ...res.data.data.item }
      }
    }

    return {
      success: false
    }
  }

  /**
   *
   * @author ehernandez
   * @param {string} cn  get products images from fede
   */
  const getImages = async (cn = '349787') => {
    const { success, data } = await fetchImages({
      method: 'GET',
      addToURL: `${cn}`
    })
    if (success && data?.data?.items) {
      const { items } = data.data
      const images = []
      items.forEach((item, idx) => {
        images.push({
          id: idx,
          src: item
        })
      })
      setImages(images)
      return {
        success: true,
        data: images
      }
    }

    return {
      success: false
    }
  }

  /**
   *
   * @author ehernandez
   * @param {string} cn  National code product
   */
  const getProductFede = async (id, widthImages, cn) => {
    const res = await getInfo({
      method: 'GET',
      addToURL: `/${id}/info`
    })
    if (res?.success) {
      if (widthImages) { getImages(cn) }
      setExtra(res?.data?.data)
      return {
        success: true,
        data: res?.data?.data
      }
    }

    return {
      success: false
    }
  }

  const getProductList = async () => {
    const { success, data } = await getInfo({
      method: 'GET',
      addToURL: '?per_page=5'
    })

    if (success && data) {
      return {
        success: true,
        data: data.data
      }
    }

    return {
      success: false
    }
  }

  return { getProduct, data, extra, getProductFede, getImages, images, getProductList }
}
