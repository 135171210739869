import React from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import PropTypes from 'prop-types'
import 'ag-grid-enterprise'
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css'
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css'
import { columnsTypes } from './infraestructure/columnsTypes'
import frameworkComponents from './infraestructure/frameworkComponents'

const Aggrid = ({ columns, onGridReady, onCellClicked, detailCellRenderer, suppressPaginationPanel, paginationPageSize }) => {
  console.log(detailCellRenderer)
  const onGridReadyCallback = params => {
    if (onGridReady) {
      const data = onGridReady(params)
      params.api.setRowData(data)
    }
  }

  return (
    <div className="ag-theme-alpine tw-h-full" style={{ height: 400, width: '100%' }}>
      <AgGridReact
        rowHeight={38}
        detailRowHeight={400}
        headerHeight={28}

        pagination={true}
        paginationPageSize={paginationPageSize}
        masterDetail={!!detailCellRenderer}
        detailCellRenderer={detailCellRenderer}
        frameworkComponents={frameworkComponents}
        onCellClicked={onCellClicked}
        columnTypes={columnsTypes}
        onGridReady={onGridReadyCallback}
        reactUi={true}
        modules={AllModules}
        suppressPaginationPanel={suppressPaginationPanel}
      // detailRowAutoHeight={true}
      // detailRowHeight={1000}
      // getRowHeight={getRowHeight}
      >

        {
          columns?.map((column, index) => (<AgGridColumn key={column.field} cellRenderer={index === 0 && 'agGroupCellRenderer'} {...column}></AgGridColumn>))
        }
      </AgGridReact>
    </div>
  )
}

Aggrid.propTypes = {
  columns: PropTypes.array,
  onGridReady: PropTypes.func,
  onCellClicked: PropTypes.func,
  suppressPaginationPanel: PropTypes.bool,
  paginationPageSize: PropTypes.number,
  detailCellRenderer: PropTypes.oneOf(['detailsOrderDetails'])
}

Aggrid.defaultProps = {
  columns: [{
    id: 1,
    field: 'id',
    headerName: 'ID',
    suppressPaginationPanel: false
  }]
}

export default Aggrid
