export const comandasOpt = {
  GET: {
    output:
      [
        {
          columnName: 'albaran',
          columnType: 1,
          columnIndex: 1,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Albarà',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'auxchr3',
          columnType: 1,
          columnIndex: 2,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Data i hora Comanda',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'cabid',
          columnType: 1,
          columnIndex: 3,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Comanda',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: true,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'docori',
          columnType: 1,
          columnIndex: 4,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Origen',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'docpdf',
          columnType: 1,
          columnIndex: 5,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'docpdf',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: true,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'docped',
          columnType: 1,
          columnIndex: 6,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Comanda',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'docser',
          columnType: 1,
          columnIndex: 7,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Expedició',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: true,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'encargo',
          columnType: 1,
          columnIndex: 8,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Enc.',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: {
              renderType: {
                trueValue: 'S',
                falseValue: 'N',
                widget: 'BOOLEAN_CHECKBOX'
              },
              prefix: null,
              suffix: null,
              nullValue: null,
              tooltip: null,
              note: 0,
              align: 'AUTO',
              joins: null,
              grid: null,
              trend: null,
              icons: null
            },
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'envter',
          columnType: 1,
          columnIndex: 9,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Enviament',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'estado',
          columnType: 1,
          columnIndex: 10,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Estat',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: true,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: {
              renderType: {
                data: [
                  {
                    key: 'B',
                    value: 'Esborrany',
                    icon: null
                  },
                  {
                    key: 'P',
                    value: 'Pendent daprovació',
                    icon: null
                  },
                  {
                    key: 'A',
                    value: 'Aprovada per facturar',
                    icon: null
                  },
                  {
                    key: 'F',
                    value: 'Facturada',
                    icon: null
                  }
                ],
                multiple: false,
                stepper: false,
                widget: 'MAP_SELECT'
              },
              prefix: null,
              suffix: null,
              nullValue: null,
              tooltip: null,
              note: 0,
              align: 'AUTO',
              joins: null,
              grid: null,
              trend: null,
              icons: null
            },
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'estcab',
          columnType: 1,
          columnIndex: 11,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Estat',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: {
              renderType: {
                data: [
                  {
                    key: 'E',
                    value: 'Bloquejat',
                    icon: null
                  },
                  {
                    key: 'V',
                    value: 'Validat',
                    icon: null
                  },
                  {
                    key: 'X',
                    value: 'Extret',
                    icon: null
                  },
                  {
                    key: 'P',
                    value: 'Parcials',
                    icon: null
                  },
                  {
                    key: 'S',
                    value: 'Servit',
                    icon: null
                  },
                  {
                    key: 'H',
                    value: 'Històric',
                    icon: null
                  }
                ],
                multiple: false,
                stepper: false,
                widget: 'MAP_SELECT'
              },
              prefix: null,
              suffix: null,
              nullValue: null,
              tooltip: null,
              note: 0,
              align: 'AUTO',
              joins: null,
              grid: null,
              trend: null,
              icons: null
            },
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'fecfin',
          columnType: 1,
          columnIndex: 12,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Data Final',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'fecha',
          columnType: 1,
          columnIndex: 13,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Data',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'fechorped',
          columnType: 1,
          columnIndex: 14,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Data i Hora Comanda',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'fecini',
          columnType: 1,
          columnIndex: 15,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Data Inici',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'fedcampanya',
          columnType: 1,
          columnIndex: 16,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Fedefarma Campanya',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'linreg',
          columnType: 1,
          columnIndex: 17,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Línies',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'multizona',
          columnType: 1,
          columnIndex: 18,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Multimagatzem',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: {
              renderType: {
                trueValue: 'S',
                falseValue: 'N',
                widget: 'BOOLEAN_CHECKBOX'
              },
              prefix: null,
              suffix: null,
              nullValue: null,
              tooltip: null,
              note: 0,
              align: 'AUTO',
              joins: null,
              grid: null,
              trend: null,
              icons: null
            },
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'nombre',
          columnType: 1,
          columnIndex: 19,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Nom',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'nomerr',
          columnType: 1,
          columnIndex: 20,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Descripció',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: true,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'numreg',
          columnType: 1,
          columnIndex: 21,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Línies',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'of_estados',
          columnType: 1,
          columnIndex: 22,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Estat',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: {
              renderType: {
                data: [
                  {
                    key: 'E',
                    value: 'Bloquejat',
                    icon: null
                  },
                  {
                    key: 'V',
                    value: 'Validat',
                    icon: null
                  },
                  {
                    key: 'X',
                    value: 'Extret',
                    icon: null
                  },
                  {
                    key: 'P',
                    value: 'Parcials',
                    icon: null
                  },
                  {
                    key: 'S',
                    value: 'Servit',
                    icon: null
                  },
                  {
                    key: 'H',
                    value: 'Històric',
                    icon: null
                  }
                ],
                multiple: false,
                stepper: false,
                widget: 'MAP_SELECT'
              },
              prefix: null,
              suffix: null,
              nullValue: null,
              tooltip: null,
              note: 0,
              align: 'AUTO',
              joins: null,
              grid: null,
              trend: null,
              icons: null
            },
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'pdf_link',
          columnType: 1,
          columnIndex: 23,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'PDF',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'print_obj',
          columnType: 1,
          columnIndex: 24,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'print_obj',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: true,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'refter',
          columnType: 1,
          columnIndex: 25,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Referència',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'type',
          columnType: 1,
          columnIndex: 26,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'type',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: true,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        },
        {
          columnName: 'zero',
          columnType: 1,
          columnIndex: 27,
          precision: 0,
          scale: 0,
          tableName: 'virtportal',
          schemaName: null,
          catalogName: null,
          columnClassName: 'java.lang.String',
          columnLabel: 'Comanda zero',
          columnTypeName: 'char',
          columnDisplaySize: 0,
          isAutoIncrement: false,
          isCaseSensitive: false,
          isCurrency: false,
          isReadOnly: false,
          isSearchable: false,
          isSigned: false,
          isWritable: false,
          isDefinitelyWritable: false,
          isNullable: 1,
          isHidden: false,
          isGeometric: false,
          isEditable: false,
          constraints: null,
          columnActions: null,
          columnStyle: {
            header: null,
            align: 'AUTO',
            grid: null,
            joins: null,
            tooltip: null,
            note: 0,
            prefix: null,
            suffix: null,
            format: null,
            trend: null,
            inputOptions: null,
            outputOptions: null,
            links: null,
            fonts: null,
            colors: null,
            colspans: null,
            icons: null
          },
          columnAttributes: null
        }
      ],
    row_expression: null,
    parameters: [
      {
        columnName: 'fecini',
        columnType: 91,
        columnIndex: 1,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.sql.Date',
        columnLabel: 'Data Inici',
        columnTypeName: 'date',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: null,
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: '2021-11-30',
          isArray: false,
          source: 'query'
        }
      },
      {
        columnName: 'fecfin',
        columnType: 91,
        columnIndex: 2,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.sql.Date',
        columnLabel: 'Data Final',
        columnTypeName: 'date',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: null,
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: '2021-12-30',
          isArray: false,
          source: 'query'
        }
      },
      {
        columnName: 'tipcom',
        columnType: 4,
        columnIndex: 3,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.Integer',
        columnLabel: 'Tipus comanda',
        columnTypeName: 'integer',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '0',
                  value: 'Reposició',
                  icon: null
                },
                {
                  key: '1',
                  value: 'Transfers',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: 0,
          isArray: false,
          source: 'query'
        }
      },
      {
        columnName: 'cn',
        columnType: 1,
        columnIndex: 4,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Codi Nacional',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: null,
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'query'
        }
      }
    ],
    actions: [],
    ontentType: '*/*',
    info: 'Pedido Transfer (ff_of_gvenpedh_transfer)'
  }
}
