import React, { useEffect, useState } from 'react'
import Aggrid from '../../infrastructure/components/Aggrid'
import PropTypes from 'prop-types'
import { useAggridProvider } from './hooks/useAggridProvider/useAggridProvider'
import ModalWindow from '../ModalWindow'
import Paragraphs from '../../components/Paragraphs'
import colors from '../../infrastructure/styles/colors'
import { useTranslation } from 'react-i18next'
import Icons from '../../components/Icons'
import CustomPagination from './CustomPagination'
// import { use } from 'i18next'

const AggridProvider = ({ onSetGridApi, data, detailCellRenderer, showDownload, customPagination, paginationPageSize }) => {
  const [columns, setColumns] = useState(null)
  const { _formatGridCols, _getCols } = useAggridProvider()
  const [modalContent, setModalContent] = useState(null)
  const [params, setParams] = useState(null)
  const [totalRows, setTotalRows] = useState(null)
  const { t } = useTranslation()

  const handleClose = () => setModalContent(null)

  /**
   * @author aesteban
   * @param {Object} params
   * @returns
   */
  const onGridReady = (params) => {
    // Array con los datos de las columnas que devolveremos
    const rows = _formatGridCols(data, columns)

    setParams(params)
    if (onSetGridApi) {
      onSetGridApi(params)
    }

    // Retornamos datos tratados
    setTotalRows(rows.length)
    return rows
  }

  const onCellClicked = (evt, params) => {
    const { cellRendererParams } = evt.column.colDef

    if (cellRendererParams.links) {
      const linkMode = cellRendererParams.links[0].mode
      const linkContent = cellRendererParams.URL_BASE + cellRendererParams.links[0].href

      if (linkMode === 'NEW_WINDOW') {
        window.open(linkContent) // _blank (valor por defecto)
      } else if (linkMode === 'CURRENT_WINDOW') {
        window.open(linkContent, '_self')
      } else if (linkMode === 'MODAL') {
        setModalContent(linkContent)
        // handleShow()
      } else if (linkMode === 'PROCESS') {
        // Hay hacer una petición put a linkContent

      } else if (linkMode === 'PROCESS_AND_REFRESH') {
        // Hay hacer una petición put a linkContent

      }
    }
  }

  const DownLoadButton = () => {
    if (!showDownload) {
      return (<div className=' tw-flex-1'></div>)
    }

    return (
      <div className=' tw-text-primary tw-cursor-pointer tw-mt-4 tw-flex tw-flex-row tw-items-center hover:tw-underline tw-justify-center md:tw-justify-start tw-flex-1'>
        <div className=' tw-border-2 tw-inline-flex tw-mt-2 tw-px-3 tw-py-2 tw-border-primary items-end'>
          <Icons.IcDownload size={22} color={colors.primary} />
          <Paragraphs uppercase weight='bold' size='xs' className='tw-pl-2'>{t('pedidosScreen.Descargar Excel')}</Paragraphs>
        </div>
      </div>
    )
  }

  const GridFooter = () => {
    if (!customPagination) {
      return (
        <div className='tw-flex'>
          <DownLoadButton />
        </div>
      )
    }

    const goToFirstPage = () => {
      params.api.paginationGoToFirstPage()
    }

    const goToLastPage = () => {
      params.api.paginationGoToLastPage()
    }

    const goToNextPage = () => {
      params.api.paginationGoToNextPage()
    }

    const goToPreviousPage = () => {
      params.api.paginationGoToPreviousPage()
    }

    return (
      <div className='tw-flex'>
        <DownLoadButton />
        <CustomPagination pageSize={paginationPageSize} totalRows={totalRows} goToFirstPage={goToFirstPage} goToLastPage={goToLastPage} goToNextPage={goToNextPage} goToPreviousPage={goToPreviousPage} />
      </div>)
  }

  useEffect(() => {
    setColumns(_getCols(data))
  }, [])

  return (
    <div className='aggrid-provider tw-h-full'>

      <Aggrid

        suppressPaginationPanel={customPagination}
        onCellClicked={onCellClicked}
        onGridReady={onGridReady}
        columns={columns}
        detailCellRenderer={detailCellRenderer}
        paginationPageSize={paginationPageSize}
      />
      <GridFooter />
      <ModalWindow
        show={modalContent !== null}
        handleClose={handleClose}
        content={modalContent}
      />
    </div>
  )
}

AggridProvider.propTypes = {
  data: PropTypes.array,
  onSetGridApi: PropTypes.func,
  detailCellRenderer: PropTypes.string,
  showDownload: PropTypes.bool,
  customPagination: PropTypes.bool,
  paginationPageSize: PropTypes.number
}

export default AggridProvider
