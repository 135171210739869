import React from 'react'
import BannerPage from '../BannerPage'
import Paragraphs from '../../../../components/Paragraphs'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Button from '../../../../components/Button'
import { infoLogin } from '../../constants/register'
import { ContainerItemBox } from './styles'

/**
 * @author ehernandez
 * @param navigateTo function to navigate
 * @returns {React.Component}
 */
const LeftLogin = ({ navigateTo, className }) => {
  const { t } = useTranslation()
  return (
    <BannerPage className={`tw-bg-primary left-register ${className}`}>
      <div>
        <Paragraphs size="8xl" weight="bold" className="tw-text-white">
          {t('¿Por qué vadefarma?')}
        </Paragraphs>
        <div className="tw-mt-4">
          {infoLogin.map((i, idx) => (
            <ContainerItemBox
              delay={idx * 0.5}
              key={i.id}
              className="login-box tw-bg-white tw-flex tw-rounded-xs tw-mt-4"
            >
              <div className="tw-p-6">
                <span className="icon-ic_seguimiento_reparto tw-text-9xl tw-text-tertiary" />
              </div>
              <div className="tw-pr-6 tw-pt-6 tw-pb-6">
                <Paragraphs weight="bold">{t(i.title)}</Paragraphs>
                <Paragraphs>{t(i.description)}</Paragraphs>
              </div>
            </ContainerItemBox>
          ))}
        </div>
      </div>
      <Button
        onClick={navigateTo}
        mode="transparent-white-border"
        textColor="white"
        label={t('Darme de alta')}
        uppercase
      />
    </BannerPage>
  )
}

LeftLogin.propTypes = {
  navigateTo: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default LeftLogin
