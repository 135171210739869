import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {Author} mromero
 * @param {Number} size
 * @param {String} color
 * @param {String} borderColor
 * @param {Function} onClick
 * @param {Boolean} selected
 * @returns
 */

const IcModalFullscreen = ({ size, color, onClick, selected }) => {
  const handleClick = () => {
    onClick()
  }
  return (
    <svg className={`ic-modal-fullscreen tw-cursor-pointer tw-select-none ${!selected && ' tw-opacity-20 hover:tw-opacity-100'} `} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18" onClick={handleClick}>
    <g id="ic-modal-fullscreen">
      <rect id="Rectángulo_2452" data-name="Rectángulo 2452" width={size} height={size} fill="none"/>
      <path id="Unión_100" data-name="Unión 100" d="M-3563,13023.5v-6.187l2.445,2.445,6.311-6.315-2.441-2.441h6.184v6.185l-2.442-2.442-6.314,6.315,2.445,2.441Z" transform="translate(3566 -13008.001)" fill={color}/>
    </g>
  </svg>

  )
}

IcModalFullscreen.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool
}

IcModalFullscreen.defaultProps = {
  size: 30,
  color: '#8f3087',
  onClick: () => { console.log('Test') },
  selected: false
}
export default IcModalFullscreen
