import React from 'react'
import PropTypes from 'prop-types'
import { CheckboxLabel } from './styles'
// import Paragraphs from '../../../../../../../../components/Paragraphs'

/**
    * @author   mromero
    * @return {React.Component}
    */
const Checkbox = ({ onChange, checked, globalCheck }) => {
  return (
    <div className='check-box'>
      <CheckboxLabel className="control control-checkbox" globalCheck = {globalCheck}>
        <input type="checkbox" checked={checked} onChange={onChange} />
        <div className="control_indicator"></div>
      </CheckboxLabel>
    </div>
  )
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  globalCheck: PropTypes.bool
}

Checkbox.defaultProps = {
  register: {}
}
export default Checkbox
