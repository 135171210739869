import styled from 'styled-components'

export const RatingContainer = styled.div`
    height: ${props => props.height + 'px'};
    width: ${props => props.width + 'px'};
`
export const Star = styled.img`
    width: 14px;
    height: 14px;
    margin-right: 8px;
`
