import React, { useState } from 'react'

// import order from './mock/index'
import PropTypes from 'prop-types'

import Paragraphs from '../../../../../../components/Paragraphs'
import Button from '../../../../../../components/Button/Button'
import CheckBox from './Components/CheckBox'
import { useTranslation } from 'react-i18next'

const OrderDetails = ({ order }) => {
  const { t } = useTranslation()
  //   const [allSelected, setAllSelected] = useState(true)

  const [currentOrder, setCurrentOrder] = useState(order)
  const [allItemsSelected, setAllItemsSelected] = useState(false)

  const handleSelectItem = (index) => {
    const newItems = currentOrder.orderItems.map((item, i) => {
      if (i === index) {
        item.checked ? item.checked = !item.checked : item.checked = true
      }
      return item
    })
    const totalSelected = newItems.reduce((ac, item) => item.checked ? ac + 1 : ac, 0)
    totalSelected === newItems.length ? setAllItemsSelected(true) : setAllItemsSelected(false)
    const newOrder = { ...currentOrder, items: newItems }
    setCurrentOrder(newOrder)
  }

  const handleSelectAllItems = () => {
    const newItems = currentOrder.orderItems.map(item => {
      item.checked = !allItemsSelected
      return item
    })
    const newOrder = { ...currentOrder, items: newItems }
    setCurrentOrder(newOrder)
    setAllItemsSelected(!allItemsSelected)
  }

  const checkItemsSelected = () => {
    const totalSelected = currentOrder.orderItems.reduce((ac, item) => item.checked ? ac + 1 : ac, 0)
    if (totalSelected) {
      const totalSelected = currentOrder.orderItems.reduce((ac, item) => item.checked ? ac + 1 : ac, 0)
      if (totalSelected === currentOrder.orderItems.length) return 'devolver pedido'
      return `devolver ${totalSelected} ${totalSelected === 1 ? 'artículo' : 'artículos'}`
    }
    return false
  }

  return (
        <div className='order-details tw-flex tw-flex-col tw-w-full tw-p-6 tw-bg-white'>
            <Paragraphs weight='bold' size='lg' className='tw-flex tw-flex-row tw-h-[50px] tw-flex tw-items-center'>
                {t('Detalles del pedido')}
            </Paragraphs>
            <div className=' tw-flex tw-flex-row tw-pt-2 tw-h-[30px]'>
                <div className=' tw-w-[10%]  tw-flex tw-flex-row'>
                {currentOrder.orderClosed && <CheckBox checked={allItemsSelected} onChange={handleSelectAllItems} globalCheck={true}/>} <Paragraphs size='xxs' weight='bold'>Artículo</Paragraphs>
                </div>
                <div className=' tw-w-[30%] tw-text-slate-400'>
                    <Paragraphs size='xxs' weight='bold'>Descripción</Paragraphs>
                </div>
                <div className=' tw-w-[10%] tw-text-slate-400 tw-text-center'>
                    <Paragraphs size='xxs' weight='bold'>Nemotécnico</Paragraphs>
                </div>
                <div className=' tw-w-[10%] tw-text-slate-400 tw-text-center'>
                    <Paragraphs size='xxs' weight='bold'>VL</Paragraphs>
                </div>
                <div className=' tw-w-[10%] tw-text-slate-400 tw-text-right'>
                    <Paragraphs size='xxs' weight='bold'>Cantidad pedida</Paragraphs>
                </div>
                <div className=' tw-w-[10%] tw-text-slate-400 tw-text-right'>
                    <Paragraphs size='xxs' weight='bold'>Unidad ventas</Paragraphs>
                </div>
                <div className=' tw-w-[10%] tw-text-slate-400 tw-text-right'>
                    <Paragraphs size='xxs' weight='bold'>Precio</Paragraphs>
                </div>
                <div className=' tw-w-[10%] tw-text-slate-400 tw-text-right'>
                    <Paragraphs size='xxs' weight='bold'>Importe neto</Paragraphs>
                </div>
            </div>
            <div className='tw-flex tw-flex-col tw-pt-2'>
                {
                    currentOrder.orderItems?.map((item, index) => {
                      return (
                        <div key={item.cn} className={`tw-flex tw-flex-row tw-h-[100px] tw-pt-3 ${index < currentOrder.orderItems.length - 1 && ' tw-border-b tw-border-slate-200'}`}>
                            <div className=' tw-w-[10%] tw-flex tw-flex-row tw-align-middle tw-pr-4 tw-self-start'>
                                {currentOrder.orderClosed && <div className=' tw-align-top'><CheckBox onChange={() => handleSelectItem(index)} checked={item.checked ? item.checked : false} /></div>}
                                <img src={item.imgSrc} className=' tw-object-contain tw-px-3' />
                            </div>
                            <div className=' tw-w-[30%] tw-self-start'>
                                <div className=' tw-flex tw-flex-col'>
                                    <Paragraphs size='xs' uppercase>{item.descripcion}</Paragraphs>
                                    <Paragraphs weight='bold' size='xxs' className='tw-flex tw-flex-row'>
                                        CN:&nbsp;
                                        <Paragraphs size='xxs'>{item.cn}</Paragraphs>
                                    </Paragraphs>
                                </div>
                            </div>
                            <div className=' tw-w-[10%]'>
                                <div className=' tw-flex tw-flex-col tw-text-center'>
                                    <Paragraphs size='xs' uppercase>{item.nemotecnico}</Paragraphs>
                                </div>
                            </div>
                            <div className=' tw-w-[10%] tw-text-center'>
                                <Paragraphs size='xs'>{item.VL}</Paragraphs>

                            </div>
                            <div className=' tw-w-[10%] tw-text-right'>
                                <Paragraphs size='xs'>{item.cantidadPedida}</Paragraphs>
                            </div>
                            <div className=' tw-w-[10%] tw-text-right'>
                                <Paragraphs size='xs'>{item.unidadVentas.toFixed(2)} €</Paragraphs>
                            </div>
                            <div className=' tw-w-[10%] tw-text-right'>
                                <Paragraphs size='xs'>{item.precio.toFixed(2)} €</Paragraphs>
                            </div>
                            <div className=' tw-w-[10%] tw-text-right'>
                                <Paragraphs size='xs's>{item.importeNeto.toFixed(2)} €</Paragraphs>
                            </div>
                        </div>
                      )
                    })
                }
            </div>
            <div className='tw-flex tw-flex-row tw-justify-between tw-pt-6'>
                {currentOrder.orderClosed
                  ? <>
                        <Button label='Ver factura' uppercase mode='transparent'/>
                        <Button
                            label={checkItemsSelected() ? checkItemsSelected() : 'devolver pedido'}
                            disabled={!checkItemsSelected()}
                            uppercase
                        />
                    </>

                  : <>
                        <Button label='ver albarán' uppercase mode='transparent'/>
                        <Button label='Serguimiento envio' uppercase/>
                    </>

                    }

            </div>
        </div>
  )
}

OrderDetails.propTypes = {
  order: PropTypes.object
}

OrderDetails.defaultProps = {
  order: {
    orderNumber: '2120-0000028',
    orderClosed: true,
    orderItems: [
      {
        descripcion: 'Mascarilla FFP2 Kadi con Certificado CE Negra',
        cn: '036260',
        imgSrc: 'https://www.druni.es/media/catalog/product/1/4/1457097_7.jpg',
        nemotecnico: 'a',
        VL: 0,
        cantidadPedida: 500,
        unidadVentas: 0.52,
        precio: 260.00,
        importeNeto: 260.00,
        checked: false
      },
      {
        descripcion: 'Priorin 60 capsulas Cabello Sano',
        cn: '234553',
        imgSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCF4Y-CHcfeeP4k72KckZQ36hxvpQhMzDdFg&usqp=CAU',
        nemotecnico: 'b',
        VL: 0,
        cantidadPedida: 200,
        unidadVentas: 260.00,
        precio: 260.00,
        importeNeto: 260.00,
        checked: false
      },
      {
        descripcion: 'Paracetamol Normon EFG 650mg 40 Comprimidos',
        cn: '045995',
        imgSrc: 'https://www.dosfarma.com/12070-large_default/paracetamol-normon-efg-650-mg-40-comprimidos.jpg',
        nemotecnico: 'c',
        VL: 0,
        cantidadPedida: 10,
        unidadVentas: 21.00,
        precio: 21.00,
        importeNeto: 2100.00,
        checked: false
      }
    ]
  }
}

export default OrderDetails
