import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextInputSearch from '../../../TextInputSearch'
import { usePredictiveText } from './hooks/usePredictiveText/usePredictiveText'
import highlightWords from 'highlight-words'
import Paragraphs from '../../../Paragraphs'
import loadingImg from '../../../../infrastructure/assets/images/loading.gif'

const QueryInput = ({ submitSearch, closeSelector, searchTerm, submitPredictive, placeholder, onlyManufacturers }) => {
  const [currentSearchTerm, setCurrentSearchTerm] = useState(searchTerm)
  const { predictives, setPredictiveQueryText, loading } = usePredictiveText({ onlyManufacturers })

  const handleChangeCurrentSearchTerm = (e) => {
    setCurrentSearchTerm(e.target.value)
    setPredictiveQueryText(e.target.value)
  }

  const hightlightSearchOnPredictive = (predictive) => {
    const predictiveParts = highlightWords({ text: predictive && predictive, query: currentSearchTerm || ' ' })
    return (
      predictiveParts?.map(p => <pre key={p.key}><Paragraphs uppercase={true} weight={p.match ? 'bold' : 'regular'}>{p.text}</Paragraphs></pre>)
    )
  }

  return (
  <div className='query-input tw-absolute tw-z-50 tw-bg-white tw-right-0 tw-top-0 tw-flex tw-flex-col tw-w-full tw-max-h-[391px] tw-shadow-lg tw-px-[18px] tw-pt-[18px]'>
      <div className=' tw-flex  tw-pb-4'>
        <div className=' tw-flex tw-flex-1'>
          <span className='icon-ic_common_search tw-text-primary' style={{ fontSize: 30 }}/>
          <form onSubmit={submitSearch} className=' tw-w-full'>
            <TextInputSearch value={currentSearchTerm || ''} onChange={ handleChangeCurrentSearchTerm} placeholder={placeholder} onBlur={currentSearchTerm ? () => submitSearch() : () => closeSelector(false)}/>
          </form>
        </div>
          <span onClick={() => closeSelector(false)} className="icon-ic_common_close tw-cursor-pointer tw-text-3xl"/>
      </div>
      <div className='tw-overflow-y-scroll tw-scrollbar tw-scrollbar-thumb-primary tw-scrollbar-track-white'>
      {
        currentSearchTerm?.length <= 2
          ? <></>
          : loading
            ? <div className=' tw-p-2'><img src={loadingImg} height={'30'} width={'30'}/></div>
            : predictives?.length > 0
              ? predictives?.map((predictive) => {
                return <div key={predictive?.id} className={`tw-flex tw-h-10 tw-border-b tw-cursor-pointer tw-items-center hover:tw-underline ${false && ' tw-bg-slate-100'}`} onClick={() => submitPredictive(predictive)}>
            {hightlightSearchOnPredictive(predictive?.name)}
          </div>
              }
              )
              : !predictives && <div className=' tw-flex tw-flex-col tw-p-2 tw-gap-2'>
                  <Paragraphs size='lg' weight={'bold'}>{`Sin resultados para "${currentSearchTerm}"`}</Paragraphs>
                  <Paragraphs size='base'>Revisa la ortografía o utiliza términos más generales</Paragraphs>
              </div>
      }
      </div>

  </div>
  )
}

QueryInput.propTypes = {
  closeSelector: PropTypes.func,
  submitSearch: PropTypes.func,
  searchTerm: PropTypes.string,
  submitPredictive: PropTypes.func,
  onlyManufacturers: PropTypes.bool,
  placeholder: PropTypes.string
}

export default QueryInput
