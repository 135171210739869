import React from 'react'
import Paragraphs from '../Paragraphs'
import PropTypes from 'prop-types'
import IcLogout from '../Icons/IcLogout'
import DropDown from '../DropDown/DropDown'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { actionUserLogout } from '../../infrastructure/redux/actions/user'
const UserPopover = ({ notificacions, closeUserPopover, onClickProfile }) => {
//   const [isOpen, setIsOpen] = useState(true)
  const { i18n, t } = useTranslation()
  const dispatch = useDispatch()

  const dropdownData = {
    data: [
      {
        id: 1,
        name: 'Castellano'
      },
      {
        id: 2,
        name: 'Català'
      }
    ]
  }

  /**
   *
   * @param {id} language id
   */
  const onSelectItem = ({ id }) => {
    switch (id) {
      case 1:
        i18n.changeLanguage('es')
        break
      case 2:
        i18n.changeLanguage('ca')
        break
      default:
        i18n.changeLanguage('es')
    }
    closeUserPopover()
  }

  /**
   * @author ehernandez
   */
  const onLogoutUser = () => {
    dispatch(actionUserLogout())
  }

  return (
    <div className="user-popover tw-absolute tw-left-0 tw-top-0">
       <div
            onClick={closeUserPopover}
            className="tw-bg-black tw-fixed tw-bg-opacity-50 tw-h-full tw-w-full tw-cursor-pointer"
        />
        <div className='tw-fixed tw-right-0 tw-flex tw-flex-col tw-pr-8 tw-top-12'>
            <div className='tw-flex tw-p-4 tw-flex-col tw-justify-between tw-w-60 tw-h-[262px] tw-bg-white'>
                <div className='tw-flex tw-flex-col tw-gap-2'>
                  <DropDown label='Idioma' data={dropdownData} onSelectItem={onSelectItem} textInput={t('language_name')} />
                </div>
                <div className='hover:tw-underline tw-cursor-pointer'>
                    <Paragraphs size='xs'>Favoritos</Paragraphs>
                </div>
                {notificacions > 0
                  ? <ul className='tw-group tw-list-disc tw-pl-3 tw-cursor-pointer tw-align-text-center'>
                        <li className='tw-text-green-500'>
                        <div className='tw-flex tw-flex-row tw-justify-between'>
                            <Paragraphs size='xs' color='black' className='group-hover:tw-underline'>Notificaciones y alertas</Paragraphs>
                            <Paragraphs className='tw-bg-green-500 tw-px-1 tw-decoration-transparent' size='xxs' color='white'>{notificacions < 99 ? notificacions : '+99'}</Paragraphs>
                        </div>
                        </li>
                    </ul>
                  : <Paragraphs size='xs'>Notificaciones y alertas</Paragraphs>
                }
                <div className='hover:tw-underline tw-cursor-pointer' onClick={onClickProfile}>
                    <Paragraphs size='xs'>Perfil de usuario</Paragraphs>
                </div>
                <div onClick={onLogoutUser} className='tw-group link-logout-user tw-flex tw-flex-row tw-justify-between tw-cursor-pointer tw-text-lilaVF tw-items-center'>
                    <Paragraphs size='xs' weight='bold' className='group-hover:tw-underline'>Cerrar sesión</Paragraphs>
                    <IcLogout size={14}></IcLogout>
                </div>
            </div>
        </div>
    </div>

  )
}

UserPopover.propTypes = {
  notificacions: PropTypes.number,
  closeUserPopover: PropTypes.func,
  onClickProfile: PropTypes.func
}

UserPopover.defaultProps = {
  notificacions: 3,
  closeUserPopover: () => console.log('closing')
}

export default UserPopover
