export const enviosOpt = { // socio 64329
  DELETE: {
    output: [
      {
        columnName: 'bulcid',
        columnType: 4,
        columnIndex: 1,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.Integer',
        columnLabel: 'Identificador',
        columnTypeName: 'integer',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: true,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: '#,##0.###',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'docref',
        columnType: 12,
        columnIndex: 2,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Referència',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'docser',
        columnType: 12,
        columnIndex: 3,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Document',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'estado',
        columnType: 1,
        columnIndex: 4,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Estat',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: 'P',
                  value: 'Pendent',
                  icon: null
                },
                {
                  key: 'C',
                  value: 'Confirmat',
                  icon: null
                },
                {
                  key: 'U',
                  value: 'En curs',
                  icon: null
                },
                {
                  key: 'E',
                  value: 'Entregat',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'fecrec',
        columnType: 91,
        columnIndex: 5,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.sql.Date',
        columnLabel: 'Data recepció',
        columnTypeName: 'date',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: 'dd-MM-yyyy',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'fecsol',
        columnType: 91,
        columnIndex: 6,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.sql.Date',
        columnLabel: 'Data Sol·licitud',
        columnTypeName: 'date',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: 'dd-MM-yyyy',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'franja',
        columnType: 1,
        columnIndex: 7,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Franja',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'numbul',
        columnType: 5,
        columnIndex: 8,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.Short',
        columnLabel: 'Nº Embalums',
        columnTypeName: 'smallint',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: '#,##0.###',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '1',
                  value: '1',
                  icon: null
                },
                {
                  key: '2',
                  value: '2',
                  icon: null
                },
                {
                  key: '3',
                  value: '3',
                  icon: null
                },
                {
                  key: '4',
                  value: '4',
                  icon: null
                },
                {
                  key: '5',
                  value: '5',
                  icon: null
                },
                {
                  key: '6',
                  value: '6',
                  icon: null
                },
                {
                  key: '7',
                  value: '7',
                  icon: null
                },
                {
                  key: '8',
                  value: '8',
                  icon: null
                },
                {
                  key: '9',
                  value: '9',
                  icon: null
                },
                {
                  key: '10',
                  value: '10',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'terdes',
        columnType: 12,
        columnIndex: 9,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Tercer Destí',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'tipbul',
        columnType: 12,
        columnIndex: 10,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Tipus Embalum',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: 'CAJ',
                  value: 'Caixa',
                  icon: null
                },
                {
                  key: 'SOB',
                  value: 'Sobre',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'action',
        columnType: 1,
        columnIndex: 11,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Acció',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'link_pdf',
        columnType: 1,
        columnIndex: 12,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'PDF',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'nevera',
        columnType: 1,
        columnIndex: 13,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Nevera',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '0',
                  value: 'No',
                  icon: null
                },
                {
                  key: '1',
                  value: 'Sí',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'nombre',
        columnType: 1,
        columnIndex: 14,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Nom',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      }
    ],
    row_expression: null,
    parameters: [
      {
        columnName: 'bulcid',
        columnType: 4,
        columnIndex: 1,
        precision: 10,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.Integer',
        columnLabel: 'Identificador',
        columnTypeName: 'integer',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: null,
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'query'
        }
      }
    ],
    actions: [],
    contentType: 'application/json',
    info: 'Unitat Enviament Cega (ff_bultociegoh)'
  },
  POST: {
    output: [
      {
        columnName: 'docref',
        columnType: 12,
        columnIndex: 1,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Referència',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'bulcid',
        columnType: 4,
        columnIndex: 2,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.Integer',
        columnLabel: 'Identificador',
        columnTypeName: 'integer',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: true,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: '#,##0.###',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'docser',
        columnType: 12,
        columnIndex: 3,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Document',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'estado',
        columnType: 1,
        columnIndex: 4,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Estat',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: 'P',
                  value: 'Pendent',
                  icon: null
                },
                {
                  key: 'C',
                  value: 'Confirmat',
                  icon: null
                },
                {
                  key: 'U',
                  value: 'En curs',
                  icon: null
                },
                {
                  key: 'E',
                  value: 'Entregat',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'fecrec',
        columnType: 91,
        columnIndex: 5,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.sql.Date',
        columnLabel: 'Data recepció',
        columnTypeName: 'date',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: 'dd-MM-yyyy',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'fecsol',
        columnType: 91,
        columnIndex: 6,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.sql.Date',
        columnLabel: 'Data Sol·licitud',
        columnTypeName: 'date',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: 'dd-MM-yyyy',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'franja',
        columnType: 1,
        columnIndex: 7,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Franja',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'numbul',
        columnType: 5,
        columnIndex: 8,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.Short',
        columnLabel: 'Nº Embalums',
        columnTypeName: 'smallint',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: '#,##0.###',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '1',
                  value: '1',
                  icon: null
                },
                {
                  key: '2',
                  value: '2',
                  icon: null
                },
                {
                  key: '3',
                  value: '3',
                  icon: null
                },
                {
                  key: '4',
                  value: '4',
                  icon: null
                },
                {
                  key: '5',
                  value: '5',
                  icon: null
                },
                {
                  key: '6',
                  value: '6',
                  icon: null
                },
                {
                  key: '7',
                  value: '7',
                  icon: null
                },
                {
                  key: '8',
                  value: '8',
                  icon: null
                },
                {
                  key: '9',
                  value: '9',
                  icon: null
                },
                {
                  key: '10',
                  value: '10',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'terdes',
        columnType: 12,
        columnIndex: 9,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Tercer Destí',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'tipbul',
        columnType: 12,
        columnIndex: 10,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Tipus Embalum',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: 'CAJ',
                  value: 'Caixa',
                  icon: null
                },
                {
                  key: 'SOB',
                  value: 'Sobre',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'action',
        columnType: 1,
        columnIndex: 11,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Acció',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'link_pdf',
        columnType: 1,
        columnIndex: 12,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'PDF',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'nevera',
        columnType: 1,
        columnIndex: 13,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Nevera',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '0',
                  value: 'No',
                  icon: null
                },
                {
                  key: '1',
                  value: 'Sí',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'nombre',
        columnType: 1,
        columnIndex: 14,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Nom',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      }
    ],
    row_expression: null,
    parameters: [
      {
        columnName: 'tipdoc',
        columnType: 1,
        columnIndex: 1,
        precision: 6,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Tipus Document',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 6,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: 'EN',
                  value: 'Enviament Normal',
                  icon: null
                },
                {
                  key: 'CF',
                  value: 'Enviament CEDIFA',
                  icon: null
                },
                {
                  key: 'DH',
                  value: 'Dispensació hospitalària',
                  icon: null
                },
                {
                  key: 'DT',
                  value: 'Distribuïdors',
                  icon: null
                },
                {
                  key: 'FM',
                  value: 'Fòrmules magistrals',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 6,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: 'EN',
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'terdes',
        columnType: 1,
        columnIndex: 2,
        precision: 16,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Tercer Destí',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 6,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: null,
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 6,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'tipbul',
        columnType: 1,
        columnIndex: 3,
        precision: 15,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Tipus Embalum',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 6,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: 'CAJ',
                  value: 'Caixa',
                  icon: null
                },
                {
                  key: 'SOB',
                  value: 'Sobre',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 6,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'numbul',
        columnType: 1,
        columnIndex: 4,
        precision: 5,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Nº Embalums',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 6,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '1',
                  value: '1',
                  icon: null
                },
                {
                  key: '2',
                  value: '2',
                  icon: null
                },
                {
                  key: '3',
                  value: '3',
                  icon: null
                },
                {
                  key: '4',
                  value: '4',
                  icon: null
                },
                {
                  key: '5',
                  value: '5',
                  icon: null
                },
                {
                  key: '6',
                  value: '6',
                  icon: null
                },
                {
                  key: '7',
                  value: '7',
                  icon: null
                },
                {
                  key: '8',
                  value: '8',
                  icon: null
                },
                {
                  key: '9',
                  value: '9',
                  icon: null
                },
                {
                  key: '10',
                  value: '10',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 6,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'nevera',
        columnType: 4,
        columnIndex: 5,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.Integer',
        columnLabel: 'Nevera',
        columnTypeName: 'integer',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 6,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '0',
                  value: 'No',
                  icon: null
                },
                {
                  key: '1',
                  value: 'Sí',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 6,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: 0,
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'franja',
        columnType: 1,
        columnIndex: 6,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Franja',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 6,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '0',
                  value: 'Mati',
                  icon: null
                },
                {
                  key: '1',
                  value: 'Tarda',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 6,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: '0',
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'fecrec',
        columnType: 91,
        columnIndex: 7,
        precision: 10,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.sql.Date',
        columnLabel: 'Data recepció',
        columnTypeName: 'date',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: null,
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: '2022-02-02',
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'coment',
        columnType: 1,
        columnIndex: 8,
        precision: 2048,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Comentari',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: null,
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'form'
        }
      }
    ],
    actions: [],
    contentType: '*/*',
    info: 'Unitat Enviament Cega (ff_bultociegoh)'
  },
  GET: {
    output: [
      {
        columnName: 'bulcid',
        columnType: 4,
        columnIndex: 1,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.Integer',
        columnLabel: 'Identificador',
        columnTypeName: 'integer',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: true,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: '#,##0.###',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'docref',
        columnType: 12,
        columnIndex: 2,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Referència',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'docser',
        columnType: 12,
        columnIndex: 3,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Document',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'estado',
        columnType: 1,
        columnIndex: 4,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Estat',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: 'P',
                  value: 'Pendent',
                  icon: null
                },
                {
                  key: 'C',
                  value: 'Confirmat',
                  icon: null
                },
                {
                  key: 'U',
                  value: 'En curs',
                  icon: null
                },
                {
                  key: 'E',
                  value: 'Entregat',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'fecrec',
        columnType: 91,
        columnIndex: 5,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.sql.Date',
        columnLabel: 'Data recepció',
        columnTypeName: 'date',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: 'dd-MM-yyyy',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'fecsol',
        columnType: 91,
        columnIndex: 6,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.sql.Date',
        columnLabel: 'Data Sol·licitud',
        columnTypeName: 'date',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: 'dd-MM-yyyy',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'franja',
        columnType: 1,
        columnIndex: 7,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Franja',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'numbul',
        columnType: 5,
        columnIndex: 8,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.Short',
        columnLabel: 'Nº Embalums',
        columnTypeName: 'smallint',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: '#,##0.###',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '1',
                  value: '1',
                  icon: null
                },
                {
                  key: '2',
                  value: '2',
                  icon: null
                },
                {
                  key: '3',
                  value: '3',
                  icon: null
                },
                {
                  key: '4',
                  value: '4',
                  icon: null
                },
                {
                  key: '5',
                  value: '5',
                  icon: null
                },
                {
                  key: '6',
                  value: '6',
                  icon: null
                },
                {
                  key: '7',
                  value: '7',
                  icon: null
                },
                {
                  key: '8',
                  value: '8',
                  icon: null
                },
                {
                  key: '9',
                  value: '9',
                  icon: null
                },
                {
                  key: '10',
                  value: '10',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'terdes',
        columnType: 12,
        columnIndex: 9,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Tercer Destí',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'tipbul',
        columnType: 12,
        columnIndex: 10,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Tipus Embalum',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: 'CAJ',
                  value: 'Caixa',
                  icon: null
                },
                {
                  key: 'SOB',
                  value: 'Sobre',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'action',
        columnType: 1,
        columnIndex: 11,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Acció',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'link_pdf',
        columnType: 1,
        columnIndex: 12,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'PDF',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'nevera',
        columnType: 1,
        columnIndex: 13,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Nevera',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '0',
                  value: 'No',
                  icon: null
                },
                {
                  key: '1',
                  value: 'Sí',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'nombre',
        columnType: 1,
        columnIndex: 14,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Nom',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      }
    ],
    row_expression: null,
    parameters: [],
    actions: [],
    contentType: '*/*',
    info: 'Unitat Enviament Cega (ff_bultociegoh)'
  },
  PUT: {
    output: [
      {
        columnName: 'bulcid',
        columnType: 4,
        columnIndex: 1,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.Integer',
        columnLabel: 'Identificador',
        columnTypeName: 'integer',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: true,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: '#,##0.###',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'docref',
        columnType: 12,
        columnIndex: 2,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Referència',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'docser',
        columnType: 12,
        columnIndex: 3,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Document',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'estado',
        columnType: 1,
        columnIndex: 4,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Estat',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: 'P',
                  value: 'Pendent',
                  icon: null
                },
                {
                  key: 'C',
                  value: 'Confirmat',
                  icon: null
                },
                {
                  key: 'U',
                  value: 'En curs',
                  icon: null
                },
                {
                  key: 'E',
                  value: 'Entregat',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'fecrec',
        columnType: 91,
        columnIndex: 5,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.sql.Date',
        columnLabel: 'Data recepció',
        columnTypeName: 'date',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: 'dd-MM-yyyy',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'fecsol',
        columnType: 91,
        columnIndex: 6,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.sql.Date',
        columnLabel: 'Data Sol·licitud',
        columnTypeName: 'date',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: 'dd-MM-yyyy',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'franja',
        columnType: 1,
        columnIndex: 7,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Franja',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'numbul',
        columnType: 5,
        columnIndex: 8,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.Short',
        columnLabel: 'Nº Embalums',
        columnTypeName: 'smallint',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: {
            pattern: '#,##0.###',
            zone: null,
            locale: 'ca-ES'
          },
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '1',
                  value: '1',
                  icon: null
                },
                {
                  key: '2',
                  value: '2',
                  icon: null
                },
                {
                  key: '3',
                  value: '3',
                  icon: null
                },
                {
                  key: '4',
                  value: '4',
                  icon: null
                },
                {
                  key: '5',
                  value: '5',
                  icon: null
                },
                {
                  key: '6',
                  value: '6',
                  icon: null
                },
                {
                  key: '7',
                  value: '7',
                  icon: null
                },
                {
                  key: '8',
                  value: '8',
                  icon: null
                },
                {
                  key: '9',
                  value: '9',
                  icon: null
                },
                {
                  key: '10',
                  value: '10',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'terdes',
        columnType: 12,
        columnIndex: 9,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Tercer Destí',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'tipbul',
        columnType: 12,
        columnIndex: 10,
        precision: 0,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Tipus Embalum',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: 'CAJ',
                  value: 'Caixa',
                  icon: null
                },
                {
                  key: 'SOB',
                  value: 'Sobre',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'action',
        columnType: 1,
        columnIndex: 11,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Acció',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'link_pdf',
        columnType: 1,
        columnIndex: 12,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'PDF',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'nevera',
        columnType: 1,
        columnIndex: 13,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Nevera',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '0',
                  value: 'No',
                  icon: null
                },
                {
                  key: '1',
                  value: 'Sí',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: null,
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      },
      {
        columnName: 'nombre',
        columnType: 1,
        columnIndex: 14,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Nom',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: null,
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: null,
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: null
      }
    ],
    row_expression: null,
    parameters: [
      {
        columnName: 'bulcid',
        columnType: 4,
        columnIndex: 1,
        precision: 10,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.Integer',
        columnLabel: 'Identificador',
        columnTypeName: 'integer',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: true,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 0,
        isHidden: true,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: null,
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'terdes',
        columnType: 1,
        columnIndex: 2,
        precision: 16,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Tercer Destí',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: true,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 6,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: null,
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 6,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'tipbul',
        columnType: 1,
        columnIndex: 3,
        precision: 15,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Tipus Embalum',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 6,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: 'CAJ',
                  value: 'Caixa',
                  icon: null
                },
                {
                  key: 'SOB',
                  value: 'Sobre',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 6,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'numbul',
        columnType: 4,
        columnIndex: 4,
        precision: 5,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.Integer',
        columnLabel: 'Nº Embalums',
        columnTypeName: 'integer',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 6,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '1',
                  value: '1',
                  icon: null
                },
                {
                  key: '2',
                  value: '2',
                  icon: null
                },
                {
                  key: '3',
                  value: '3',
                  icon: null
                },
                {
                  key: '4',
                  value: '4',
                  icon: null
                },
                {
                  key: '5',
                  value: '5',
                  icon: null
                },
                {
                  key: '6',
                  value: '6',
                  icon: null
                },
                {
                  key: '7',
                  value: '7',
                  icon: null
                },
                {
                  key: '8',
                  value: '8',
                  icon: null
                },
                {
                  key: '9',
                  value: '9',
                  icon: null
                },
                {
                  key: '10',
                  value: '10',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 6,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'nevera',
        columnType: 4,
        columnIndex: 5,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.Integer',
        columnLabel: 'Nevera',
        columnTypeName: 'integer',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 6,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '0',
                  value: 'No',
                  icon: null
                },
                {
                  key: '1',
                  value: 'Sí',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 6,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'franja',
        columnType: 1,
        columnIndex: 6,
        precision: 0,
        scale: 0,
        tableName: 'virtportal',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Franja',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 6,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: '0',
                  value: 'Mati',
                  icon: null
                },
                {
                  key: '1',
                  value: 'Tarda',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 6,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'fecsol',
        columnType: 91,
        columnIndex: 7,
        precision: 10,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.sql.Date',
        columnLabel: 'Data Sol·licitud',
        columnTypeName: 'date',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 6,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: null,
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 6,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'coment',
        columnType: 1,
        columnIndex: 8,
        precision: 2048,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Comentari',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: false,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: false,
        isGeometric: false,
        isEditable: true,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 12,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: null,
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 12,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'form'
        }
      },
      {
        columnName: 'estado',
        columnType: 1,
        columnIndex: 9,
        precision: 1,
        scale: 0,
        tableName: 'ff_bultociegoh',
        schemaName: null,
        catalogName: null,
        columnClassName: 'java.lang.String',
        columnLabel: 'Estat',
        columnTypeName: 'char',
        columnDisplaySize: 0,
        isAutoIncrement: false,
        isCaseSensitive: false,
        isCurrency: false,
        isReadOnly: true,
        isSearchable: false,
        isSigned: false,
        isWritable: false,
        isDefinitelyWritable: false,
        isNullable: 1,
        isHidden: true,
        isGeometric: false,
        isEditable: false,
        constraints: null,
        columnActions: null,
        columnStyle: {
          header: null,
          align: 'AUTO',
          grid: {
            xs: 0,
            sm: 0,
            md: 6,
            lg: 0,
            xl: 0
          },
          joins: null,
          tooltip: null,
          note: 0,
          prefix: null,
          suffix: null,
          format: null,
          trend: null,
          inputOptions: null,
          outputOptions: {
            renderType: {
              data: [
                {
                  key: 'P',
                  value: 'Pendent',
                  icon: null
                },
                {
                  key: 'C',
                  value: 'Confirmat',
                  icon: null
                },
                {
                  key: 'U',
                  value: 'En curs',
                  icon: null
                },
                {
                  key: 'E',
                  value: 'Entregat',
                  icon: null
                }
              ],
              multiple: false,
              stepper: false,
              widget: 'MAP_SELECT'
            },
            prefix: null,
            suffix: null,
            nullValue: null,
            tooltip: null,
            note: 0,
            align: 'AUTO',
            joins: null,
            grid: {
              xs: 0,
              sm: 0,
              md: 6,
              lg: 0,
              xl: 0
            },
            trend: null,
            icons: null
          },
          links: null,
          fonts: null,
          colors: null,
          colspans: null,
          icons: null
        },
        columnAttributes: {
          default: null,
          isArray: false,
          source: 'form'
        }
      }
    ],
    actions: [],
    contentType: '*/*',
    info: 'Unitat Enviament Cega (ff_bultociegoh)'
  }
}
