const notificationsFromServer = [
  {
    id: 1,
    isNew: true,
    type: 'Comercial',
    date: new Date('Mon Jan 31 2022 11:50:05 GMT+0100 (Central European Standard Time)'),
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.'
  },
  {
    id: 2,
    isNew: false,
    type: 'Sanitaria',
    date: new Date('Mon Jan 30 2022 17:01:05 GMT+0100 (Central European Standard Time)'),
    title: 'Lorem ipsum dolor sit amet',
    body: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.'
  },
  {
    id: 3,
    isNew: true,
    type: 'Sanitaria',
    date: new Date('Mon Dec 12 2021 19:00:05 GMT+0100 (Central European Standard Time)'),
    title: 'Lorem ipsum dolor sit amet, consectetur',
    body: 'Nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.'
  },
  {
    id: 4,
    isNew: false,
    type: 'Informativa',
    date: new Date('Mon Apr 10 2021 16:33:05 GMT+0100 (Central European Standard Time)'),
    title: 'Lorem ipsum dolor sit amet, consectetur',
    body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.'
  },
  {
    id: 5,
    isNew: false,
    type: 'Informativa',
    date: new Date('Mon May 01 2021 07:20:05 GMT+0100 (Central European Standard Time)'),
    title: 'Adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.'
  },
  {
    id: 6,
    isNew: false,
    type: 'Comercial',
    date: new Date('Mon Jun 22 2021 15:50:05 GMT+0100 (Central European Standard Time)'),
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.'
  }

]

export default notificationsFromServer
