import styled from 'styled-components'

export const ContainerBallUser = styled.div`
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 2px solid #fff;
`

export const ActiveNotification = styled.div`
    height: 10px;
    width: 10px;
    background-color: #fff;
    border-radius: 50%;
    right: -2px;
    top: -1px;
    background-color: #71D875;
    border: 2px solid #fff;
`
