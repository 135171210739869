import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { RatingContainer, Star } from './styles'
import StarImg from './assets/starImg.png'
import Paragraphs from '../Paragraphs'
import { format, parseISO } from 'date-fns'
/**
    *
    *
    * @author   mromero
    * @param {starsNum} number of stars
    * @param {ratingExcerpt} the excerpt
    * @param {ratingAuthor} the author name
    * @param {ratingDate} the date of the rating
    * @return {React.Component}
    */
const Rating = ({ starsNum, ratingExcerpt, ratingAuthor, ratingDate }) => {
  const maxStars = 5
  const maxExcerptLenht = 70
  const [stars, setStars] = useState([])

  useEffect(() => {
    const newStars = []
    if (starsNum < 1) starsNum = 1
    for (let i = 0; i < starsNum && i < maxStars; i++) {
      newStars.push(<Star key={i} src={StarImg} alt='rating-star' />)
    }
    setStars(newStars)
  }, [])

  return (
    <RatingContainer
      height={81}
      width={160}
      className="rating tw-w-full tw-bg-negroVf tw-flex tw-flex-col tw-gap-3 tw-flex-auto"
    >
      <div className='tw-flex tw-flex-row'>
        {stars.map(s => s)}
      </div>
      <div className='tw-flex tw-flex-col'>
        <Paragraphs className='tw-text-white tw-break-word tw-text-opacity-80' size="xxs" italic >
          &quot;{ratingExcerpt.length < maxExcerptLenht ? ratingExcerpt : ratingExcerpt.substr(0, maxExcerptLenht - 1) + '...'}&quot;
        </Paragraphs>
        <Paragraphs className='tw-text-white' size="xxs" weight="bold">
          {ratingAuthor} { format(parseISO(ratingDate), '\'el \'dd/MM/yyyy') }
        </Paragraphs>
      </div>

    </RatingContainer>
  )
}

Rating.propTypes = {
  rating: PropTypes.number,
  starsNum: PropTypes.number,
  ratingExcerpt: PropTypes.string,
  ratingAuthor: PropTypes.string,
  ratingDate: PropTypes.string
}

Rating.defaultProps = {
  rating: 0,
  starsNum: 0,
  ratingExcerpt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
  ratingAuthor: 'Santiago B.',
  ratingDate: '1/6/2021'
}

export default Rating
