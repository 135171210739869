import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'

/**
 * @returns {React.Component}
 */
const IcDeleteOutline = ({ size, color }) => {
  return (
    <Icon
      className="IcDeleteOutline material-icons tw-items-center tw-justify-center"
      size={size}
      color={color}
    >
      delete_outline
    </Icon>
  )
}

IcDeleteOutline.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
}

export default IcDeleteOutline
