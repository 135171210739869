import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import HeaderBarPage from '../../../../components/HeaderBarPage'
import Page from '../../../../components/Page'
import Paragraphs from '../../../../components/Paragraphs'
import TextInput from '../../../../components/TextInput'
// import PropTypes from 'prop-types'
import Button from '../../../../components/Button'
import { useUser } from '../../../../infrastructure/hooks/useUser/useUser'
import { useForm } from 'react-hook-form'
const INPUTS = {
  account: [
    {
      id: 1,
      type: 'text',
      name: 'name',
      label: 'Nombre'
    },
    {
      id: 2,
      type: 'email',
      name: 'email',
      label: 'Correo electrónico'
    },
    {
      id: 3,
      type: 'password',
      name: 'password',
      label: 'Contraseña'
    }
  ],
  info: [
    {
      id: 1,
      type: 'text',
      name: 'nif',
      label: 'Nif del titular'
    },
    {
      id: 2,
      type: 'text',
      name: 'college_number',
      label: 'Numero de colegiado'
    },
    {
      id: 3,
      type: 'text',
      name: 'mobile',
      label: 'Tel. movil'
    },
    {
      id: 4,
      type: 'text',
      name: 'phone',
      label: 'Tel. fijo'
    },
    {
      id: 5,
      type: 'fax',
      name: 'fax',
      label: 'Fax'
    }
  ],
  farmacia: [
    {
      id: 1,
      type: 'text',
      name: 'pharmacy.name',
      label: 'S.L. Asociada / Farmacia (denominación)'
    },
    {
      id: 2,
      type: 'text',
      name: 'pharmacy.cif',
      label: 'Cif'
    },
    {
      id: 3,
      type: 'text',
      name: 'pharmacy.type_address',
      label: 'Tipo de vía'
    },
    {
      id: 4,
      type: 'text',
      name: 'pharmacy.address',
      label: 'Dirección'
    },
    {
      id: 5,
      type: 'text',
      name: 'pharmacy.city',
      label: 'Población'
    },
    {
      id: 6,
      type: 'text',
      name: 'pharmacy.province',
      label: 'Provincia'
    },
    {
      id: 7,
      type: 'text',
      name: 'pharmacy.postal_code',
      label: 'Codigo postal'
    }
  ],
  extra: [
    {
      id: 1,
      type: 'text',
      name: 'pharmacy.type_schedule',
      label: 'Horarios'
    },
    // {
    //   id: 2,
    //   type: 'text',
    //   name: 'pharmacy.equivalence_surcharge',
    //   label: 'Rcargo de equivalencia'
    // },
    {
      id: 3,
      type: 'text',
      name: 'pharmacy.software',
      label: 'Software de gestión de la farmacia'
    }
  ]
}
const ProfileScreen = (props) => {
  const { t } = useTranslation()

  const { user, getInfoUser, updateUser } = useUser()

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit
  } = useForm()
  useEffect(() => {
    getInfoUser()
  }, [])

  useEffect(() => {
    reset(user)
  }, [user])

  const onSubmit = async (data) => {
    const values = {
      user: {
        data
      }
      // pharmacy: {
      //   ...data.pharmacy
      // }
    }
    const res = await updateUser(values)
    console.lgo(res, '....')
  }

  return (
    <Page.Container name="profile-screem tw-bg-white">
      <Page.Header className="tw-flex tw-items-center tw-px-4">
        <HeaderBarPage title={t('Completa tu perfil')} />
      </Page.Header>
      <Page.Body className="tw-px-4">
        <Paragraphs size="md">
          {t(
            'Para poder comprar en vadefarma, necesitamos que completes algunos datos adicionales sobre ti y tu farmacia.'
          )}
        </Paragraphs>
        <div className="tw-my-4 tw-bg-secondary tw-bg-opacity-10 tw-p-8">
          <Paragraphs
            className="tw-text-primary tw-mb-4"
            size="xl"
            weight="bold"
          >
            {t('Datos del farmacéutico/a titular')}
          </Paragraphs>
          <div className="tw-flex tw-flex-wrap tw-gap-x-4">
            <div
              className="tw-bg-primary"
              style={{ width: 135, height: 135 }}
            ></div>
            <div>
              <div className="tw-flex tw-gap-x-4">
                {INPUTS.account.map((input) => (
                  <div style={{ width: 200 }} key={input.id}>
                    <TextInput
                      register={register(input.name)}
                      backgroundColorInput="white"
                      name={input.input}
                      label={input.label}
                      key={input.id}
                      error={errors[input.name]?.message}

                    />
                  </div>
                ))}
              </div>
              <div className="tw-flex tw-gap-x-4 tw-w-full">
                {INPUTS.info.map((input) => (
                  <div style={{ width: 200 }} key={input.id}>
                    <TextInput
                      register={register(input.name)}
                      backgroundColorInput="white"
                      name={input.input}
                      label={input.label}
                      key={input.id}
                      error={errors[input.name]?.message}

                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="tw-my-4 tw-bg-white tw-p-8">
          <Paragraphs
            className="tw-text-primary tw-mb-4"
            size="xl"
            weight="bold"
          >
            {t('Datos del farmacéutico/a titular')}
          </Paragraphs>
          <div className="tw-flex tw-flex-wrap tw-gap-x-4">
            <div className="tw-flex tw-gap-x-4 tw-flex-wrap tw-w-full">
              {INPUTS.farmacia.map((input) => (
                <div className="tw-w-2/12" key={input.id}>
                  <TextInput
                    register={register(input.name)}
                    name={input.input}
                    label={input.label}
                    key={input.id}
                    error={errors[input.name]?.message}

                  />
                </div>
              ))}
            </div>
            <hr className="tw-separtor tw-my-6 tw-bg-secondary tw-w-full tw-h-[2px]" />
            <div className="tw-flex tw-gap-x-4 tw-w-full">
              {INPUTS.extra.map((input) => (
                <div style={{ width: 200 }} key={input.id}>
                  <TextInput
                    register={register(input.name)}
                    name={input.input}
                    label={input.label}
                    key={input.id}
                    error={errors[input.name]?.message}

                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="tw-my-4 tw-bg-secondary tw-bg-opacity-10 tw-p-8">
          <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
            <Paragraphs className="tw-text-primary" size="2xl" weight="bold">
              {t('Datos de pago mediante SEPA')}
            </Paragraphs>
            <div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top tw-bg-primary bg-no-repeat bg-contain bg-white focus:outline-none cursor-pointer shadow-sm"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked76"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="tw-h-[80px] tw-flex tw-items-center tw-justify-end ">
          <Button onClick={handleSubmit(onSubmit)} uppercase label="Validar datos" />
        </div>
      </Page.Body>
    </Page.Container>
  )
}

ProfileScreen.propTypes = {}

export default ProfileScreen
