export const es = {
  'cn:': 'cn: {{cn}}',
  'pvp:': 'p.v.p:',
  'iva:': 'i.v.a:',
  success: 'Éxito',
  error: 'Error',
  alert: 'Alerta',
  Almacén: 'Almacén {{warehouse}}',
  'Crea una cuenta con los datos del titular de la farmacia': '<bold>Crea una cuenta</bold> con los datos del titular de la farmacia'
}
