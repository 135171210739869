import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../../../components/Paragraphs'
import { useTranslation } from 'react-i18next'

const ProductPrice = ({ price, iva, pvp }) => {
  const { t } = useTranslation()
  return (
    <div className="product-price tw-flex tw-flex-col tw-items-end">
      <Paragraphs size="9xl" weight="bold" className=" tw-text-tertiary">
        {price || '-'}{' '}
        <Paragraphs className=" tw-inline-block" size="xl" weight="bold">
          €
        </Paragraphs>
      </Paragraphs>
      <div className="tw-flex tw-flex-row">
      <div className="tw-flex">
          <Paragraphs size='xs' uppercase>
            {t('pvp:')}
          </Paragraphs>
          <Paragraphs size='xs' weight="bold" className="tw-pl-1">{pvp}€</Paragraphs>
        </div>
        <div className="tw-flex tw-pl-1">
          <Paragraphs size='xs' uppercase>
            {t('iva:')}
          </Paragraphs>
          <Paragraphs size='xs' weight="bold" className="tw-pl-1">{iva}%</Paragraphs>
        </div>
      </div>
    </div>
  )
}

ProductPrice.propTypes = {
  price: PropTypes.number,
  iva: PropTypes.number,
  pvp: PropTypes.number
}

export default ProductPrice
