import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../../Paragraphs'
import { useTranslation } from 'react-i18next'

export const SlotTotal = ({ total, title, size }) => {
  const { t } = useTranslation()
  return (
    <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
      <Paragraphs weight="bold" size={size} uppercase>
        {t(title)}
      </Paragraphs>
      <Paragraphs weight="bold" size="lg" className='tw-text-primary'>
        {total ? total?.toFixed(2) : 0}
        <Paragraphs
          weight="bold"
          size="xs"
          className=" tw-inline-block tw-text-primary tw-pl-2"
        >
          €
        </Paragraphs>
      </Paragraphs>
    </div>
  )
}

//
SlotTotal.propTypes = {
  total: PropTypes.number,
  title: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg'])
}
/**
 * @author mromero
 * @param {*} param0
 * @returns {React.Component}
 */
const BasketTotal = ({ mode, iva, bigBasket, shippment, total }) => {
  const { t } = useTranslation()
  const size = mode === 'large' ? 'xs' : 'xxs'
  const padding = mode === 'large' ? 'tw-py-4' : 'tw-py-0'

  const classesFromLarge = (bg) => {
    let classes = ' '
    if (mode === 'large') {
      classes += ` tw-p-4 ${padding} ${bg} `
    }
    return classes
  }
  return (
    <div className={'basket-total'}>
        <div className={` ${classesFromLarge()}`}>
      <SlotTotal size={size} total={total} title="Subtotal" />
      </div>
      <div className={` ${classesFromLarge('tw-bg-gray-100')}`}>
      <SlotTotal size={size} total={iva} title="iva" />
      </div>
      <div className={`tw-flex tw-flex-row tw-justify-between ${classesFromLarge()}`}>
        <Paragraphs weight="bold" size={size} className=" tw-self-center">
          {t('Envio')}
        </Paragraphs>
        <Paragraphs
          weight="bold"
          size='xs'
          className=" tw-self-center tw-text-success"
        >
          {t('Envio gratis en 24 h.')}
        </Paragraphs>
      </div>
      <div className={`tw-flex tw-flex-row tw-justify-between  ${classesFromLarge('tw-bg-gray-100')}`}>
        <Paragraphs weight="bold" size={size} className=" tw-self-center">
          {t('Total pedido')}
        </Paragraphs>
        <Paragraphs
          weight="bold"
          size='xl'
          className=" tw-text-tertiary tw-flex tw-items-end"
        >
          {total || 0}
          <Paragraphs weight='bold' className="tw-text-tertiary tw-ml-2">€</Paragraphs>
        </Paragraphs>
      </div>
    </div>
  )
}
//
BasketTotal.propTypes = {
  basket: PropTypes.array,
  iva: PropTypes.number,
  shippment: PropTypes.object,
  bigBasket: PropTypes.bool,
  mode: PropTypes.oneOf(['small', 'large']),
  total: PropTypes.number
}

BasketTotal.defaultProps = {
  basket: [],
  iva: 4,
  shippment: null,
  bigBasket: true
}

export default BasketTotal
