import React, { useState, useEffect } from 'react'
import Paragraphs from '../../../../components/Paragraphs'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { descriptions } from './constants/descriptions'
import ProductNamePrice from '../ProductNamePrice'
import ProductLabCategories from '../ProductLabCategories'
import ProductPromotions from '../ProductPromotions'
import ProductCartButton from '../ProductCartButton'
import ProductCover from '../ProductCover'

/**
 * @author mromero
 * @returns {React.Component}
 */
const ProductDetail = ({ data, extra, images }) => {
  const { t } = useTranslation()
  const [productDescriptions, setProductDescriptions] = useState(descriptions)
  useEffect(() => {
    if (data) {
      const filters = productDescriptions.filter((i, idx) => {
        i.text = data[i.id]
        return i
      })
      setProductDescriptions(filters)
    }
  }, [data])

  const handleChangeDescriptionActive = (index) => {
    const filters = productDescriptions.filter((i, idx) => {
      if (idx === index) {
        i.active = true
      } else {
        i.active = false
      }
      return i
    })
    setProductDescriptions(filters)
  }
  return (
    <div className="product-detail tw-flex tw-flex-wrap tw-flex-row tw-h-full">
      <div className=" tw-flex tw-justify-center tw-items-center tw-w-[45%]">
        <ProductCover images={images} />
      </div>
      <div className="tw-flex tw-flex-col tw-w-[55%]">
        <div className='tw-pb-4'>
        <ProductNamePrice
          name={data?.name}
          price={extra?.price}
          discount={extra?.price_discount}
          iva={data?.iva}
          pvp={extra?.pvp}
          cn={data?.cn}
        />
        </div>
        <div className="tw-pb-4">
        <ProductLabCategories
          laboratory={data?.laboratory}
          categories={data?.categories}
        />
        </div>

        <div className=" tw-flex tw-flex-row tw-w-full tw-items-center tw-min-h-[94px] tw-bg-secondary-20 tw-py-[22px] tw-px-4 tw-gap-2 tw-relative tw-mb-4">
          <div className='tw-flex-1'>
          <ProductPromotions available={extra?.has_stock} nomalm={extra?.nomalm} promotions={extra?.promotions} />
          </div>
          <ProductCartButton
            productId={data?.id}
          />
        </div>

        <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-8 tw-pt-6">
          {productDescriptions.map((description, index) => (
            <div
              key={description.key}
              onClick={() => handleChangeDescriptionActive(index)}
            >
              <Paragraphs
                className="tw-cursor-pointer hover:tw-underline"
                uppercase
                weight={description.active ? 'bold' : 'regular'}
              >
                {t(description.key)}
              </Paragraphs>
            </div>
          ))}
        </div>
        <div className=" tw-overflow-auto tw-flex-1">
          <Paragraphs className=" tw-h-2">
            {' '}
            {productDescriptions?.find((desc) => desc.active)?.text}
          </Paragraphs>
        </div>
      </div>
    </div>
  )
}

ProductDetail.propTypes = {
  data: PropTypes.shape({
    cn: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    pvp: PropTypes.number,
    iva: PropTypes.number,
    categories: PropTypes.array,
    laboratory: PropTypes.object,
    id_product: PropTypes.string,
    price_discount: PropTypes.number,
    promotions: PropTypes.array,
    nomalm: PropTypes.string,
    stock_disp: PropTypes.number,
    id: PropTypes.number
  }),
  images: PropTypes.array,
  extra: PropTypes.object
}

export default ProductDetail
