import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import { useNavigation } from '../../infrastructure/context/StackContext/StackContext'

/**
    * @author   mromero
    * @param   {Object} column
    * @return {React.Component}
    */
function FooterLinks ({ column }) {
  const navigation = useNavigation()
  /**
    * @author   mromero
    * @param   {Object} item
    */
  const onClik = (item) => {
    if (item.type === 'link') {
      if (item.path) {
        // console.log(item.path)
        navigation.navigate({ path: item.path, id: item.id })
      } else {
        window.open(item.link, '_self')
      }
    }
    if (item.type === 'image') {
      console.log(item)
    }
  }

  return (
    <div className='footer-links tw-pr-8'>
      <Paragraphs className='tw-text-white tw-mb-3' size='sm' weight='bold'>{column.text}</Paragraphs>
      {
        column?.items?.map(item => <div key={item.id}>
          <div onClick={() => onClik(item)}>
            {item.type === 'image' && <img key={item.id} className='tw-h-12' src={item.src} alt={item.alt}/>}
            <Paragraphs className={(item.items ? 'tw-text-white' : 'tw-text-white/75') + ' tw-cursor-pointer hover:tw-underline tw-mb-2'} weight={item.items && 'bold'} size='xs'>
              {item.text}
            </Paragraphs>
          </div>
          {item?.items?.map(i => {
            if (i.type === 'image') {
              return <img key={i.id} className='tw-h-12' src={i.src} alt={i.alt}/>
            }
            return <div key={i.id} onClick={() => onClik(i)}>
              <Paragraphs className='tw-text-white/75 tw-cursor-pointer hover:tw-underline tw-mb-2' size='xs'>
                {i.text}
              </Paragraphs>
            </div>
          })}
        </div>)
      }
      {/* {decodeLine(column)}
          { column?.items?.map((item, index) => decodeLine(item, index))} */}
    </div>
  )
}

FooterLinks.propTypes = {
  column: PropTypes.object
}

export default FooterLinks
