import { useEffect, useState } from 'react'
import { useMutation } from '../../../../../../infrastructure/hooks/useMutation/useMutation'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

export const usePredictiveText = ({ onlyManufacturers }) => {
  const [fetchPredictiveText] = useMutation('products/auto-complete')
  const [predictives, setPredictives] = useState([])
  const { searchTerm } = useSelector(state => state.searchReducer)
  const [predictiveQueryText, setPredictiveQueryText] = useState(!onlyManufacturers ? searchTerm : '')
  // const [predictiveIndex, setPredictiveIndex] = useState(-1)
  const [currentPredictive, setCurrentPredictive] = useState()
  const [currentData, setCurrentData] = useState()
  const [loading, setLoading] = useState(false)
  // const [selectedItem, setSelectedItem] = useState(-1)

  // console.log(selectedItem)
  // useEffect(() => {
  //   setSelectedItem(predictives?.length ? predictives.length : -1)
  // }, [predictives])

  // useEffect(() => {
  //   document.addEventListener('keyup', () => setSelectedItem(selectedItem + 1))
  //   return document.addEventListener('keyup', () => setSelectedItem(selectedItem + 1))
  // }, [])

  useEffect(async () => {
    console.log({ predictiveQueryText })
    if (predictiveQueryText?.length <= 2 || !predictiveQueryText) {
      setPredictives([])
      return
    }
    const query = {
      method: 'POST',
      data: {
        filter: {
          query: predictiveQueryText
        }
      }
    }
    console.log('sdkfjsdofj')
    setLoading(true)
    const newCurrentPredictive = uuidv4()
    setCurrentPredictive(newCurrentPredictive)
    fetchPredictiveText(query).then(data => {
      if (onlyManufacturers) {
        const newData = data?.data?.data?.filter(d => d.type === 'manufacturer')
        data = { ...data, data: { data: newData } }
        console.log({ data })
      }
      data = { ...data, newCurrentPredictive }
      setCurrentData(data)
    })
  }, [predictiveQueryText, searchTerm])

  console.log(predictiveQueryText)
  useEffect(() => {
    console.log(currentPredictive)
    if (currentPredictive === currentData?.newCurrentPredictive && predictiveQueryText?.length > 0 && currentPredictive) {
      setPredictives(currentData?.data?.data)
      setLoading(false)
    }
  }, [currentData])

  return ({ predictives, setPredictiveQueryText, loading })
}
