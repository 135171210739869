import styled from 'styled-components'

export const ContainerItems = styled.div`
  border-radius: 1px;
  box-shadow: 0px 2px 3px 2px #E8F4F5;
`
export const ContainerScroll = styled.div`
 max-height: 180px;
`
export const DropdownItem = styled.div`
    &:hover .DropDown-text{
        text-decoration: underline;
    }
`
export const InputContent = styled.div`
    height: 35px;    
`
