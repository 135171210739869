import React from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param {Number} size
 * @param {String} color
 * @param {String} className
 * @param {String} hoverColor
 * @returns
 */

const IcTransport = ({ size, color, className }) => (
  <svg id="ic_banner_destacado_transporte" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18">
  <g id="ic_info_table_more">
    <rect id="Rectángulo_2924" data-name="Rectángulo 2924" width={size} height={size} fill="none"/>
    <g id="Grupo_6997" data-name="Grupo 6997" transform="translate(23497.301 12004)">
      <g id="Grupo_6998" data-name="Grupo 6998">
        <g id="Grupo_6997-2" data-name="Grupo 6997">
          <rect id="Rectángulo_2922" data-name="Rectángulo 2922" width="5" height="3" transform="translate(-23486 -11995)" fill={color}/>
          <path id="Trazado_2587" data-name="Trazado 2587" d="M-23486.045-12027.592h2.648l2.668,3.394v2.886h-6" transform="translate(0 29.572)" fill="none" stroke={color} strokeWidth="1.5"/>
          <g id="Rectángulo_2923" data-name="Rectángulo 2923" transform="translate(-23497 -12001)" fill="none" stroke={color} strokeWidth="1.5">
            <path d="M2,0h9a0,0,0,0,1,0,0V10a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2A2,2,0,0,1,2,0Z" stroke="none"/>
            <path d="M2,.75H9.5a.75.75,0,0,1,.75.75v7a.75.75,0,0,1-.75.75h-8A.75.75,0,0,1,.75,8.5V2A1.25,1.25,0,0,1,2,.75Z" fill="none"/>
          </g>
          <circle id="Elipse_421" data-name="Elipse 421" cx="2" cy="2" r="2" transform="translate(-23495 -11993)" fill="none" stroke={color} strokeWidth="1.5"/>
          <path id="Trazado_2591" data-name="Trazado 2591" d="M0,0H6V4H0Z" transform="translate(-23486.301 -11995)" fill="none"/>
          <circle id="Elipse_422" data-name="Elipse 422" cx="2" cy="2" r="2" transform="translate(-23486.5 -11993)" fill="none" stroke={color} strokeWidth="1.5"/>
        </g>
      </g>
    </g>
  </g>
</svg>

)
IcTransport.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  hoverColor: PropTypes.string
}

IcTransport.defaultProps = {
  size: 30,
  color: '#000',
  className: '',
  hoverColor: '#8F3087'
}
export default IcTransport
