import { combineReducers } from 'redux'
import userReducer from './user'
import navigationReducer from './navigation'
import searchReducer from './search'
import cartReducer from './cart'

export default combineReducers({
  userReducer,
  navigationReducer,
  searchReducer,
  cartReducer
})
