import React, { useState, useEffect } from 'react'
import Paragraphs from '../../../../components/Paragraphs'
import PropTypes from 'prop-types'
import Icons from '../../../../components/Icons'

const InfoBox = ({ boxText, boxType, haveAlerts }) => {
  const [icon, setIcon] = useState()
  useEffect(() => {
    switch (boxType) {
      case 'alert':
        setIcon(<Icons.IcAlertVF size={38} color='tertiary' />)
        break
      case 'invoice':
        setIcon(<Icons.IcInvoiceVF size={38} color='tertiary'/>)
        break
      case 'order':
        setIcon(<Icons.IcOrderVF size={38} color='tertiary'/>)
        return
      default:
        setIcon(<></>)
    }
  }, [])

  return (
    <div className='info-box tw-flex tw-flex-row tw-w-full tw-h-[84px] tw-bg-[#FBFAFC] tw-transition-colors tw-delay-150 tw-border-b-4 tw-border-transparent hover:tw-border-tertiary tw-items-center tw-gap-4 tw-cursor-pointer tw-px-4'>
        <div>
            {icon}
        </div>
        <div className=' tw-flex tw-flex-col'>
            <Paragraphs weight='bold' size='base'>{boxText}</Paragraphs>
            {haveAlerts && <Paragraphs size='xs' weight='bold' color='green'>¡Hay nuevas alertas!</Paragraphs>}
        </div>
    </div>
  )
}

InfoBox.propTypes = {
  boxText: PropTypes.string,
  boxType: PropTypes.string,
  haveAlerts: PropTypes.bool
}

InfoBox.defaultProps = {
  boxText: 'Alertas sanitarias',
  boxType: 'alert',
  haveAlerts: false
}

export default InfoBox
