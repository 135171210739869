import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../../infrastructure/styles/colors'
/**
 *
 * @param {Number} size
 * @param {Color} color
 * @returns
 */

const IcAlertVF = ({ size, color }) => (
    <svg id="ic_menu_vadefarma_alertas" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 38 38">
        <g id="Grupo_7310" data-name="Grupo 7310" transform="translate(2.375 3.958)">
         <path id="Unión_99" data-name="Unión 99" d="M1.583,30.083A1.584,1.584,0,0,1,.2,27.726L15.243.811a1.584,1.584,0,0,1,2.765,0L33.048,27.726a1.584,1.584,0,0,1-1.381,2.357Zm2.7-3.168H28.969L16.626,4.828Zm10.764-3.96a1.583,1.583,0,1,1,1.58,1.585A1.581,1.581,0,0,1,15.046,22.955Zm0-4.748V13.46a1.583,1.583,0,1,1,3.166,0v4.746a1.583,1.583,0,0,1-3.166,0Z" transform="translate(0 0)" fill={colors[color]}/>
        </g>
        <rect id="Rectángulo_3111" data-name="Rectángulo 3111" width="38" height="38" fill="none"/>
        </svg>
)
IcAlertVF.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
}

IcAlertVF.deaultProps = {
  size: '24px',
  color: 'primary'
}

export default IcAlertVF
