import React, { useEffect, useState } from 'react'
import Page from '../../../../components/Page'
import TopSearch from '../../../../components/TopSearch'
import HeaderBarPage from '../../../../components/HeaderBarPage'
import InfoBoxesContainer from '../../components/InfoBoxesContainer'
import OurBrands from '../../../../components/OurBrands'
import { menuLinks } from '../../constants/constants'
import SliderHome from '../../components/SliderHome'
import FeatureBar from '../../components/FeatureBar'
import ProductListBlock from '../../../../components/ProductsListBlock'
// import { useMutation } from '../../../../infrastructure/hooks/useMutation/useMutation'
import useProducts from '../../../../infrastructure/hooks/useProduct'
import useUser from '../../../../infrastructure/hooks/useUser'
/**
 *
 * @author ehernandez
 * @returns {React.Component}
 */
const HomeScreen = () => {
  const { getProductList } = useProducts()
  const [list, setList] = useState([])
  const { user } = useUser()

  useEffect(() => {
    getProductList().then(({ success, data }) => {
      if (success) { setList(data.items) }
    })
  }, [])

  return (
        <Page.Container name='home-screen' className=' tw-bg-white tw-flex tw-flex-col'>
          <Page.Header className='header tw-flex tw-flex-col tw-px-4 tw-justify-center'>
            <HeaderBarPage links={menuLinks} />
          </Page.Header>
          <Page.Body className='header tw-flex tw-flex-col tw-px-4'>
          <TopSearch/>
          <SliderHome />
          <FeatureBar />

          <InfoBoxesContainer />
          <div className='tw-py-8'>
          <ProductListBlock title='Top ventas' products={list} />

          </div>
          {
            user?.id

              ? <>
            <div className='tw-py-8 tw-bg-secondary tw-bg-opacity-20 tw-px-4 tw-flex tw-gap-x-6'>
            <div className='tw-w-2/12 tw-bg-red-100 tw-h-[200px]' />
            <div className='tw-w-2/12 tw-bg-blue-100 tw-h-[200px]' />
            <div className='tw-w-8/12 tw-bg-blue-100 tw-h-[200px]' />
          </div>
          <div className='tw-py-8'>
          <ProductListBlock title='Novedades' products={list} />
          </div>

            </>

              : null
          }
 <OurBrands />
          </Page.Body>
        </Page.Container>
  )
}

export default HomeScreen
