import React, { useState, useEffect } from 'react'
import Paragraphs from '../Paragraphs'
import notificationsFromServer from './mock'
import Notification from '../Notification/Notification'
import Icons from '../Icons'
// import PropTypes from 'prop-types';
/**
  *
  * @param {*} props
  * @returns {React.Component}
  */
const NotificationsContainer = (props) => {
  const [notificationTypes, setNotificationTypes] = useState([])
  const [activeType, setActiveType] = useState('Todas')
  const [notifications, setNotifications] = useState(notificationsFromServer)
  const [visibleNotifications, setVisibleNotifications] = useState(notificationsFromServer)
  const [order, setOrder] = useState('newest')
  const [showFilterModal, setShowFilterModal] = useState(false)
  /**
   * Extra las posibles categorias de las notificaciones para mostrarlas como filtros
   */
  useEffect(() => {
    const newNotificationTypes = [...new Set(notifications.map(item => item.type))]
    const allNotifications = 'Todas'
    setNotificationTypes([allNotifications].concat(newNotificationTypes))
  }, [notifications])

  /**
   *
   * @param {number} id Indice de la notificación que queremos marcar como leída o como no leída
   */
  const handleChangeNotification = (id) => {
    const newNotifications = notifications.map(notification => {
      if (notification.id === id) notification.isNew = !notification.isNew
      return notification
    })
    setNotifications(newNotifications)
  }

  /**
   *
   * @param {*} id la id de la notificación que se quiere borrar
   */
  const handleDeleteNotification = (id) => {
    console.log(id)
    const newNotifications = notifications.filter(notification => notification.id !== id)
    setNotifications(newNotifications)
  }

  /**
   *
   * filtra las notificaciones por el tipo
   */
  const handleFilterNotifications = () => {
    if (activeType === 'Todas') {
      setVisibleNotifications(notifications)
      return
    }
    const filteredNotificactions = notifications.filter(notification => notification.type === activeType)
    setVisibleNotifications(filteredNotificactions)
  }

  useEffect(() => handleFilterNotifications(), [activeType, notifications])

  useEffect(() => {
    setShowFilterModal(false)
  }, [order])

  return (
    <div className='notifications-container tw-flex tw-flex-col'>
        <div className=' tw-pb-7'>
            <Paragraphs size='xl' weight='bold'>Notificaciones y alertas</Paragraphs>
        </div>

        <div className='tw-flex tw-flex-row tw-gap-10'>
            {
                notificationTypes.map(type =>
                    <div key={type} className=' tw-self-center ' onClick={() => setActiveType(type)}>
                        <Paragraphs
                        className={`tw-self-center ${activeType === type ? ' tw-text-primary' : ' tw-text-[#22222160] hover:tw-text-black tw-cursor-pointer'}`}
                        size={activeType === type ? 'lg' : 'sm'}
                        weight='bold'
                        >
                            {type}
                        </Paragraphs>
                    </div>
                )
            }
        </div>
        <div className='tw-flex tw-flex-row tw-justify-end tw-gap-2 tw-pr-4 tw-pb-[29px] tw-relative'>
            <Paragraphs weight='bold' size='xs' className='tw-self-center'>Ordenar por: </Paragraphs>
            <div className='tw-flex tw-self-center tw-cursor-pointer' onClick={() => setShowFilterModal(true)}><Paragraphs>{order}</Paragraphs><Icons.IcArrowExpand direction={{}} expand='more' size={20} /></div>
            {
                showFilterModal &&
                  <div className=' tw-absolute tw-right-0 tw--top-[10px] tw-flex tw-flex-col tw-w-40 tw-shadow-xl tw-bg-white tw-z-10'>
                    <div className='tw-cursor-pointer' onClick={() => setOrder('unreaded')}>
                        <Paragraphs className='tw-px-5 tw-py-5 tw-border-b hover:tw-underline' size='xs' >
                            No leídos primero
                        </Paragraphs>
                    </div>
                    <div className='tw-cursor-pointer' onClick={() => setOrder('newest')}>
                        <Paragraphs className='tw-px-5 tw-py-5  tw-border-b hover:tw-underline' size='xs'>Más recientes</Paragraphs>
                    </div>
                    <div className='tw-cursor-pointer' onClick={() => setOrder('oldest')}>
                        <Paragraphs className='tw-px-5 tw-py-5 hover:tw-underline' size='xs'>Más antiguos</Paragraphs>
                    </div>
              </div>

            }
        </div>
        {visibleNotifications.length === 0
          ? <div><Paragraphs size='sm' weight='bold'>No hay notificaciones</Paragraphs></div>
          : <div>
            {
                visibleNotifications.map((notification, index) => <Notification key={notification.id} notification={notification} index={index} changeNotification={handleChangeNotification} deleteNotification={handleDeleteNotification} />)
            }
        </div>
        }

    </div>
  )
}

NotificationsContainer.propTypes = {}

export default NotificationsContainer
