import React from 'react'
import PropTypes from 'prop-types'
import Icons from '../Icons'

/**
  * @author mromero
  * @param {Number} itemsNum the number of items inside the shopping cart
  * @param {Boolean} plus if 'true' shows a plus sign instead of itemsnumber
  * @param {Function} onClick
  * @returns {React.Component}
  */
const CartButton = ({ itemsInCartNum, plus, onClick, iconSize, buttonSize }) => {
  return (
    <div className='cart-button tw-bg-lilaVF tw-text-white tw-flex tw-justify-center tw-place-items-center tw-cursor-pointer'
      onClick={onClick}
      style={{ height: buttonSize, width: buttonSize }}
    >
      <Icons.IcCart size={iconSize} color='#fff' />
      <div className='tw-absolute tw-text-xxs tw-pb-3 tw-pl-0.5'>
        {plus ? <Icons.IcAdd size={14} /> : itemsInCartNum > 0 ? (itemsInCartNum < 10 ? itemsInCartNum : '+9') : '-'}
      </div>
    </div>
  )
}

CartButton.propTypes = {
  itemsInCartNum: PropTypes.number,
  plus: PropTypes.bool,
  onClick: PropTypes.func,
  iconSize: PropTypes.number,
  buttonSize: PropTypes.number
}

CartButton.defaultProps = {
  itemsInCartNum: 0,
  plus: true,
  buttonSize: 40,
  iconSize: 24,
  onClick: () => { }
}

export default CartButton
