import styled from 'styled-components'

export const LayoutContainer = styled.div`
    margin-left: ${props => props.isLogged ? '56px' : '0'};
`
export const HeaderNav = styled.div`
    height: 98px;
    position: sticky;
    z-index: 45
`
