import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'

const SelectorAddItems = ({ quantity, onChange }) => {
  const onChangeQuantity = (e) => {
    let value = quantity
    if (e === '+') {
      value += 1
    }
    if (e === '-') {
      value -= 1
    }
    if (value > 0) {
      onChange(value)
    }
  }
  return <div className='selector-add-items tw-flex tw-flex-row tw-items-center'>
        <div
            onClick={() => onChangeQuantity('-')}
          className="tw-cursor-pointer tw-text-primary tw-px-3 tw-select-none tw-flex tw-items-center tw-justify-center "
        >
          <span className="icon-ic_rest_units" style={{ fontSize: 20 }}></span>
        </div>
        <Paragraphs
          weight="bold"
          className="tw-select-none  tw-w-[40px] tw-h-[40px] tw-flex tw-items-center tw-justify-center "
        >
          {quantity}
        </Paragraphs>
        <div
            onClick={() => onChangeQuantity('+')}
          className="tw-cursor-pointer tw-text-primary tw-px-3 tw-flex tw-items-center tw-justify-center tw-h-full tw-select-none "
        >
          <span className="icon-ic_add_units" style={{ fontSize: 20 }}></span>
        </div>
  </div>
}

SelectorAddItems.propTypes = {
  quantity: PropTypes.number,
  onChange: PropTypes.func
}

export default SelectorAddItems
