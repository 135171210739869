import { createStore, applyMiddleware, compose } from 'redux'
// redux persist
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// redux-logger
import { createLogger } from 'redux-logger'

// reducers
import Reducers from './reducers'

const logger = createLogger({
  collapsed: true
})

const middlewares = []

const persistConfig = {
  key: 'root-planograma-1',
  storage,
  whitelist: ['userReducer', 'cartReducer']
}

const persistedReducer = persistReducer(persistConfig, Reducers)

middlewares.push(logger)

const store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middlewares))
)

const persistor = persistStore(store)

export { store, persistor }
