import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'

/**
 * @returns {React.Component}
 */
const IcPlus = ({ size, color }) => {
  return (
    <Icon size={size} color={color} className="material-icons IcPlus">
      add
    </Icon>
  )
}

IcPlus.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
}

export default IcPlus
