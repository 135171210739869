export const menuLinks = [
  {
    id: 1,
    text: 'Natural',
    path: '/natural'
  },
  {
    id: 2,
    text: 'Ortopedia',
    path: '/ortopedia'
  },
  {
    id: 3,
    text: 'Veterinaria',
    path: '/veterinaria'
  },
  {
    id: 4,
    text: 'Dermocosmetica',
    path: '/dermocosmetica'
  },
  {
    id: 5,
    text: 'Promociones',
    path: '/promociones'
  },
  {
    id: 6,
    text: 'Marcas',
    path: '/marcas'
  },
  {
    id: 7,
    text: 'Marcas',
    path: '/novedades'
  },
  {
    id: 8,
    text: 'Outlet',
    path: '/outlet'
  }
]

export const slides = [
  {
    id: 1,
    title: '¡Consigue hasta un 60% de descuento!',
    mainText: 'Regalando ORAL-B estas navidades, ahorra y participa en el sorteo de un súper pack* valorado en 200€.',
    buttonText: 'comprar ahora',
    path: '/promo/oral-b',
    uri: 'https://i.ibb.co/Xj2Pd3v/img-ejemplo-hero.png'
  },
  {
    id: 2,
    title: '¡Consigue hasta un 60% de descuento!',
    mainText: 'Regalando ORAL-B estas navidades, ahorra y participa en el sorteo de un súper pack* valorado en 200€.',
    buttonText: 'comprar ahora',
    path: '/promo/oral-b',
    uri: 'https://via.placeholder.com/1812x407'
  }
]
