import styled from 'styled-components'

export const Dot = styled.button`


    height: 10px !important;
        width: 10px !important;
        border-radius: 50%;
        background-color: transparent !important;

    
    
`
