import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'

/**
 *
 * @param {number} size size icon
 * @param {string} color color hexadecimal
 * @returns {React.Component}
 */
const IcEye = ({ size }) => {
  return (
    <Icon
      size={size}
      className="material-icons IcEye tw-text-primary"
    >
      visibility
    </Icon>
  )
}

IcEye.propTypes = {
  size: PropTypes.number.isRequired
}

export default IcEye
