import React from 'react'
import Paragraphs from '../Paragraphs'

const brands = [
  {
    id: 1,
    src: 'https://upload.wikimedia.org/wikipedia/en/thumb/2/2b/Essity_logo.svg/153px-Essity_logo.svg.png',
    url: ''
  },
  {
    id: 2,
    src: 'https://www.farmaciaguitart.com/img/m/x3.jpg.pagespeed.ic.ArO0CYEx0Y.png',
    url: ''
  },
  {
    id: 3,
    src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e2/Logo_Merck_KGaA_2015.svg/1066px-Logo_Merck_KGaA_2015.svg.png',
    url: ''
  },
  {
    id: 4,
    src: 'https://upload.wikimedia.org/wikipedia/en/thumb/2/2b/Essity_logo.svg/153px-Essity_logo.svg.png',
    url: ''
  },
  {
    id: 5,
    src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Almirall_Logo_2020.svg/2560px-Almirall_Logo_2020.svg.png',
    url: ''
  }
]

// import PropTypes from 'prop-types'
/**
 *  @author mromero
 * @returns {React.Component}
 */
const OurBrands = () => {
  // SwiperCore.use([Autoplay, Mousewheel, Keyboard])

  return (
        <div className='our-brands tw-flex tw-flex-col tw-gap-4'>
            <Paragraphs weight='bold' size='xl'>Nuestras marcas</Paragraphs>

            <div className='tw-flex tw-items-center tw-justify-between tw-py-8'>
              {
                brands.map(brand => (
                  <div key={brand.id} className='tw-flex tw-flex tw-items-center tw-justify-center tw-h-[100px] tw-w-[226px] tw-cursor-pointer tw-px-10 tw-opacity-30 tw-grayscale tw-transition-all tw-delay-300 tw-duration-75 hover:tw-delay-[0ms] hover:tw-duration-200 hover:tw-grayscale-0 hover:tw-opacity-100'>
                    <img className='tw-mx-auto' src={brand.src} onClick={() => console.log(brand.url)}/>
                  </div>
                ))
              }
            </div>
        </div>
  )
}

OurBrands.propTypes = {

}

export default OurBrands
